import L from 'leaflet';
import { ApplicationActions } from '../../../store/applicationState';
import editableSwitchIcon from '../../../statics/images/diplomaticRelationship.svg';
import { toRef, watch } from 'vue';

export default class DiplomaticRelationshipToggleControl {
    constructor(store) {
        const diplomaticRelationshipEnabled = toRef(store.state.applicationState, 'diplomaticRelationshipEnabled');
        watch(diplomaticRelationshipEnabled, () => {
            if (diplomaticRelationshipEnabled.value) {
                document.querySelector('.map-control.diplomatic-relationship-toggle-map-button').parentElement.style.backgroundColor = 'red';
            } else {
                document.querySelector('.map-control.diplomatic-relationship-toggle-map-button').parentElement.style.backgroundColor = 'white';
            }
        });
        const LeafletControlConstructor = L.Control.extend({
            options : {
                position : 'topleft',
            },

            onAdd : () => {
                let container = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom diplomatic-relationship-toggle-map-button-in-toolbar');
                container.type = 'div';
                container.innerHTML = `<a>
                <img class="map-control diplomatic-relationship-toggle-map-button" src="${editableSwitchIcon}" alt=""/>
                <span class="tooltip-container">
                <span class="tooltip">Diplomacie</span>
                </span>
                </a>`;
                container.style.color = 'black';
                container.style.backgroundColor = 'white';
                container.style.width = '30px';
                container.style.height = '30px';
                container.style.display = 'inherit';

                container.addEventListener('pointerup', (event) => {
                    event.stopPropagation();
                    store.dispatch(ApplicationActions.toggleDiplomaticRelationship);
                });

                return container;
            },
        });
        this._control = new LeafletControlConstructor();
    }

    getControl() {
        return this._control;
    }
}
