<template>
    <div v-if="shouldDisplayExplorationMenu" @click.stop>
        <div id="exploration-menu-nav" class="exploration-menu-nav exploration-menu-nav-animation">
            <div class="input-field search-component">
                <img class="prefix" :src="require('../statics/images/search.png')" alt=""/>
                <label for="search-input"></label>
                <EditableSelect
                  class="search-field"
                  :currentItem="{ name: markerFilterQuery, id: undefined }"
                  :items="fuzzyResults"
                  @inputOnGoing="inputOnGoing"
                  @selectedItem="selectedSearchResult" />
            </div>
            <ul class="collapsible expandable marker-categories">
                <li v-for="markersInCategory in markersInCategories" :key="markersInCategory.id" class="marker-type-section" v-show="!markerTypeCategoriesToExpand[markersInCategory.id].hide">
                    <div class="marker-list-head">
                        <div class="left teal lighten-5 marker-type-layer marker-type-layer" @click="toggleMarkerTypeFilter(markersInCategory.id)">
                            <img v-show="!markersInCategory.filterOutOnMap" class=" marker-type-layer-show" :src="require('../statics/images/eye.png')" alt=""/>
                            <img v-show="markersInCategory.filterOutOnMap" class=" marker-type-layer-hide" :src="require('../statics/images/eye-off.png')" alt=""/>
                        </div>
                        <div class="ml-expandable-head marker-type-name teal lighten-5" @click="toggleCategoryDisplay(markersInCategory.id)">
                            {{ markersInCategory.name }}
                        </div>
                    </div>
                    <ul v-show="markerTypeCategoriesToExpand[markersInCategory.id].expand" class="collection ml-expandable" id="${this.type}-marker-list">
                        <li v-show="markerFilterQuery.length === 0 || marker.label.toLocaleLowerCase().indexOf(markerFilterQuery.toLocaleLowerCase()) !== -1 || marker.text.toLocaleLowerCase().indexOf(markerFilterQuery.toLocaleLowerCase()) !== -1" v-for="marker in markersInCategory.markers" :key="marker.id" class="collection-item marker-item extended-link" id="marker-${this.id}" @click="zoomOnMarker(marker.id)">
                            <a href="#!" class="collection-item label-find">{{ marker.label }}</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, ComputedRef, nextTick, onMounted, ref, Ref, toRef, watch, watchEffect } from 'vue';
import { useStore, Store } from 'vuex';
import * as showdown from 'showdown';
import { RootState } from '../store/store';
import { ApplicationActions } from '../store/applicationState';
import { MapActions } from "../store/mapStore/MapActions";
import { MapGetters } from "../store/mapStore/MapGetters";
import { Map } from "../store/domain/Map";
import { Marker } from "../store/domain/Marker";
import { MapState } from '../store/mapStore/mapState';
import { MarkerType } from '../store/domain/MarkerType';
import { MarkerCategory } from '../store/domain/MarkerCategory';
import { Roles } from '../iam/javascripts/Roles';
import { MarkerData, MarkerGetters } from '../store/domain/markers/MarkerEvents';
import Fuse from 'fuse.js';
import EditableSelect from './graphics/EditableSelect.vue';

export default {
  components: { EditableSelect },
    name: 'ExplorationMenu',
    setup() {
        const store: Store<RootState> = useStore();
        const markerFilterQuery = ref('');
        const markerTypeCategoriesToExpand: Ref<any> = ref({});

        type MarkerTypeContainer = { markerType: MarkerType, filterOutOnMap: boolean, markers: Marker[] };

        const markersInCategories = computed(() => {
          const markers = store.getters[MarkerGetters.GET_ALL_MARKERS];
          return store.getters[MapGetters.getMarkerCategories]
            .map((markerCategory: MarkerCategory) => {
              return {
                id: markerCategory.id,
                name: markerCategory.name,
                filterOutOnMap: store.state.applicationState.markerTypesToDim.indexOf(markerCategory.id) > -1,
                markers: markers
                  .filter((marker: Marker) => marker.type === markerCategory.id)
                  .sort((markerA: Marker, markerB: Marker) => markerA.label.localeCompare(markerB.label)),
              }
            })
            .filter((markerCategory: Record<string, any>) => markerCategory.markers.length > 0)
            .sort((markerCategoryA: MarkerCategory, markerCategoryB: MarkerCategory) => {
              markerCategoryA.name.localeCompare(markerCategoryB.name);
            });
        });
        const shouldDisplayExplorationMenu: Ref<boolean> = toRef(store.state.applicationState, 'shouldDisplayExplorationMenu');
        
        watch(markersInCategories, () => {
          markersInCategories.value.forEach((markersInCategory: any) => {
            markerTypeCategoriesToExpand.value[markersInCategory.id] = {
              expand: false,
              hide: false,
            }
          })
        });

        function inputOnGoing(name: string) {
          markerFilterQuery.value = name;
        }
        function selectedSearchResult({ name } : { name: string }) {
          markerFilterQuery.value = name;
        }
        const fuzzyResults = computed(() => {
          if (markerFilterQuery.value.length) {
            const markers = store.getters[MarkerGetters.GET_ALL_MARKERS];
            const options = {
                includeScore: true,
                isCaseSensitive: false,
                shouldSort: true,
                threshold: 0.6,
                useExtendedSearch: true,
                keys: ['label'],
            }
            const fuse = new Fuse(markers, options);
            const fuzzyLabelMatches: any[] = fuse.search(markerFilterQuery.value)
              .filter((result: any): any => {
                return result.score <= 0.5;
              })
              .map((result: any): any => {
                return {
                  id: result.item.id,
                  name: result.item.label,
                }
              });
            return fuzzyLabelMatches;
          } else {
            return [];
          }
        });

        function toggleMarkerTypeFilter(markerType: MarkerType) {
            store.dispatch(ApplicationActions.toggleMarkerTypeToDimOnMap, markerType);
        }
        function toggleCategoryDisplay(markerType: MarkerType) {
            markerTypeCategoriesToExpand.value[markerType].expand = !markerTypeCategoriesToExpand.value[markerType].expand;
        }
        watch(markerFilterQuery, () => {
          Object.keys(<any>markerTypeCategoriesToExpand.value).forEach((key: any) => {
            if (markerFilterQuery.value.length === 0) {
              markerTypeCategoriesToExpand.value[key].expand = false;
              markerTypeCategoriesToExpand.value[key].hide = false;
            } else {
              markersInCategories.value.forEach((markersInCategory: any) => {
                const shouldShow = markersInCategory.markers
                  .filter((marker: MarkerData) => {
                    return marker.label.toLocaleLowerCase().indexOf(markerFilterQuery.value.toLocaleLowerCase()) !== -1 
                    || marker.text.toLocaleLowerCase().indexOf(markerFilterQuery.value.toLocaleLowerCase()) !== -1;
                  }).length > 0;
                markerTypeCategoriesToExpand.value[markersInCategory.id].expand = shouldShow;
                markerTypeCategoriesToExpand.value[markersInCategory.id].hide = !shouldShow;
              });
            }
          });
        });
        watch(markerFilterQuery, () => {
          store.dispatch(ApplicationActions.updateMarkerToFilterOutFromSearch, markerFilterQuery.value);
        });
        function zoomOnMarker(id: number) {
          store.dispatch(MapActions.zoomOnMarker, id);
        }

        return {
          fuzzyResults,
          inputOnGoing,
          shouldDisplayExplorationMenu,
          markerFilterQuery,
          markersInCategories,
          markerTypeCategoriesToExpand,
          selectedSearchResult,
          toggleCategoryDisplay,
          toggleMarkerTypeFilter,
          zoomOnMarker,
        }
    },
}
</script>

<style lang="postcss" scoped>

$side-nav-length: 250px;

.exploration-menu-nav {
  position: absolute;
  top:0;
  left: 0;
  right: auto;
  z-index: 10000;
  height: 100vh;
  width: $side-nav-length;
  background-color: white;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  resize: horizontal;
  transition: all 0.2s ease;
}

.marker-categories {
  * {
    list-style-type: none;
  }
  padding-left: 0;
}

.marker-type-name {
  text-transform: capitalize;
  font-size: 14px;
}

.marker-item {
  padding: 5px;
  .label-find {
    text-decoration-line: none;
    color: brown;
  }
  &:hover {
    background-color: rgba(255, 59, 59, 0.33);
  }
  transition: background-color 0.3s ease;
}

.marker-type-name {
  height: 40px;
  margin-left: 25px;
}

.marker-type-section {
  &:first-child {
    border-top: 1px solid #ddd;
  }
  border-bottom: 1px solid #ddd;
  background-color: white;
}

.marker-type-layer {
  align-items:center;
  display: flex;
  height: 40px;
  float: left;
}

.marker-type-layer-show, .marker-type-layer-hide {
  width: 14px;
  height: 14px;
  padding-left: 5px;
  font-size: 14px;
}

.ml-expandable-head {
  text-transform: capitalize;
  font-size: 14px;
  align-items:center;
  display: flex;
}

.ml-expandable {
  border-top: 1px solid #ddd;
  padding: 0;
  transition: height 0.8s ease;
}

.marker-list-head {
  &:hover {
    background-color: rgba(255, 59, 59, 0.33);
    transition: background-color 0.2s ease;
  }
}

.input-field.search-component {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 15px;

  #search {
    height: 10vh;
    width: 100%;
  }

  img {
    width: 10px;
    height: 10px;

    margin-right: 7px;
  }

  label {
    width: auto !important;
  }

  .search-field {
    z-index: 1;
    width: 80%;
  }
}

</style>