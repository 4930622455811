import { MarkerCategory } from './MarkerCategory';
import { GenericEvent } from './genericEvents/genericMapStoreFunctions';


export class Map {
    id: string;
    name: string;
    url: string;
    width: number;
    height: number;
    isVisible: boolean;
    markerCategories: MarkerCategory[];
    markerHistory: GenericEvent[];
    rumorsHistory: GenericEvent[];
    diplomaticRelationshipHistory: GenericEvent[];

    constructor(
        id: string,
        name: string,
        url: string,
        width: number,
        height: number,
        isVisible: boolean,
        markerCategories: MarkerCategory[],
        markerHistory: GenericEvent[],
        rumorsHistory: GenericEvent[],
        diplomaticRelationshipHistory: GenericEvent[],
        ) {
        this.id = id;
        this.name = name;
        this.url = url;
        this.width = width;
        this.height = height;
        this.isVisible = isVisible;
        this.markerCategories = markerCategories;
        this.markerHistory = markerHistory;
        this.rumorsHistory = rumorsHistory;
        this.diplomaticRelationshipHistory = diplomaticRelationshipHistory;
    }
}
