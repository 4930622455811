import lieuxMarkerIcon from '../../statics/images/lieux.png';
import capitaleMarkerIcon from '../../statics/images/capitale.png';
import citeMarkerIcon from '../../statics/images/cite.png';
import fleuveMarkerIcon from '../../statics/images/riviere.png';
import foretMarkerIcon from '../../statics/images/foret.png';
import montagneMarkerIcon from '../../statics/images/montagne.png';
import regionMarkerIcon from '../../statics/images/region.png';
import ruineMarkerIcon from '../../statics/images/ruine.png';
import villageMarkerIcon from '../../statics/images/village.png';
import playersMarkerIcon from '../../statics/images/players.png';
import merMarkerIcon from '../../statics/images/mer.png';

export default class MarkerTypes {
    constructor(props) {
        const defaultIconSize = 20;
        const defaultVisitedSize = 23;
        const defaultTargetVisitedSize = 18;
        const defaultDiamondVisitedSize = 18;
        this._markerTypes = [];
        this._markerIcons = [];
        this._markerGreyIcons = [];
        this._markerInvisibleIcons = [];
        this._markerMapZIndex = [];
        [
            { 'type': 'capitales', 'icon_url': capitaleMarkerIcon, 'mapDisplayIndex': 7, 'iconSize': 30, visitedSize: 25 },
            { 'type': 'cités', 'icon_url': citeMarkerIcon, 'mapDisplayIndex': 6, 'iconSize': defaultIconSize, visitedSize: defaultVisitedSize },
            { 'type': 'fleuves', 'icon_url': fleuveMarkerIcon, 'mapDisplayIndex': 0, 'iconSize': 16, visitedSize: 18 },
            { 'type': 'forêts', 'icon_url': foretMarkerIcon, 'mapDisplayIndex': 2, 'iconSize': defaultIconSize, visitedSize: defaultTargetVisitedSize },
            { 'type': 'lieux', 'icon_url': lieuxMarkerIcon, 'mapDisplayIndex': 4, 'iconSize': defaultIconSize, visitedSize: defaultTargetVisitedSize },
            { 'type': 'mer', 'icon_url': merMarkerIcon, 'mapDisplayIndex': 1, 'iconSize': defaultIconSize, visitedSize: defaultDiamondVisitedSize },
            { 'type': 'montagnes', 'icon_url': montagneMarkerIcon, 'mapDisplayIndex': 8, 'iconSize': defaultIconSize, visitedSize: defaultVisitedSize },
            { 'type': 'regions', 'icon_url': regionMarkerIcon, 'mapDisplayIndex': 9, 'iconSize': defaultIconSize, visitedSize: defaultDiamondVisitedSize },
            { 'type': 'ruines', 'icon_url': ruineMarkerIcon, 'mapDisplayIndex': 3, 'iconSize': defaultIconSize, visitedSize: defaultTargetVisitedSize },
            { 'type': 'villages', 'icon_url': villageMarkerIcon, 'mapDisplayIndex': 5, 'iconSize': defaultIconSize, visitedSize: defaultVisitedSize },
        ].forEach(markerDescription => {
            this._markerTypes.push(markerDescription.type);
            this._markerMapZIndex[ markerDescription.type ] = markerDescription.mapDisplayIndex;
            this._markerIcons[ markerDescription.type ] = {
                iconUrl     : markerDescription.icon_url,
                iconSize    : [ markerDescription.iconSize, markerDescription.iconSize ],
                iconAnchor  : [ markerDescription.iconSize / -2, markerDescription.iconSize / -2 ],
                visitedSize : [ 7, 7 ],
                popupAnchor : [ 0, 0 ],
            };
        });
        [
            { 'type': 'players', 'icon_url': playersMarkerIcon, 'mapDisplayIndex': 10, 'iconSize': [ 40, 55.6 ], visitedSize: [] },
        ].forEach(markerDescription => {
            this._markerMapZIndex[ markerDescription.type ] = markerDescription.mapDisplayIndex;
            const factor = 0.8;
            this._markerIcons[ markerDescription.type ] = {
                iconUrl     : markerDescription.icon_url,
                iconSize    : [ markerDescription.iconSize[ 0 ] * factor, markerDescription.iconSize[ 1 ] * factor ],
                iconAnchor  : [ markerDescription.iconSize[ 0 ] / 2 * -factor, markerDescription.iconSize[ 1 ] * -factor ],
                visitedSize : [ 7, 7 ],
                popupAnchor : [ 0, 0 ],
            };
        });
    }
    get markerTypes() {
        return this._markerTypes;
    }

    get markerIcons() {
        return this._markerIcons;
    }

    get markerMapZIndex() {
        return this._markerMapZIndex;
    }
}
