import L from 'leaflet';
import LeafletMarkerContainer from './LeafletMarkerContainer';
import mapBackground from '../../statics/images/map.jpg';
import { MapGetters } from '../../store/mapStore/MapGetters';
import { MapActions } from '../../store/mapStore/MapActions';
import { ApplicationActions } from '../../store/applicationState';
import { nextTick, shallowRef, toRef, watch, watchEffect } from 'vue';
import { RumorActions, RumorGetters, RumorsData } from '../../store/domain/rumors/RumorsEvents';
import Toast from '../toasts/Toast';
import LeafletRumorContainer from './LeafletRumorContainer';
import LeafletDiplomaticRelationshipContainer from './LeafletDiplomaticRelationshipContainer';
import { Roles } from '../../iam/javascripts/Roles';
import { MarkerActions, MarkerData, MarkerGetters } from '../../store/domain/markers/MarkerEvents';
import { MarkerType } from '../../store/domain/MarkerType';
import { DiplomaticRelationshipActions, DiplomaticRelationshipGetters } from '../../store/domain/diplomaticRelationship/diplomaticRelationshipEvents';
import { setupCursors } from './cursorsWebsocket';
import { displayCursors } from './mapCursors';
import youAreHereIcon from '../../statics/images/you-are-here.png';

export default class Map {
    constructor(store, controls, loggedInControls, isLoggedIn) {
        this._controls = controls;
        this._loggedInControls = loggedInControls;
        this._isLoggedIn = isLoggedIn;
        this._store = store;
        this._leafletMap = shallowRef(L.map('map'));
        this._leafletMapCursors = [];
        const cursors = setupCursors(this);
        this._leafletMapSetup(mapBackground, 2000, 1414);
        this._undisplayCursors = undefined;
        displayCursors(cursors, this);

        document.getElementById('map').style.cursor = 'crosshair';

        this._leafletDistanceMarkers = [];
        this._leafletMarkersContainers = [];
        this._leafletRumorsContainers = [];
        this._leafletDiplomaticRelationshipLines = [];
        this._startDiplomaticRelationshipLine = [];

        const distanceRef = toRef(store.state.applicationState, 'shouldDisplayDistance');
        this.distanceActivated = false;

        watch(distanceRef, () => {
            if (!distanceRef.value) {
                this.distanceActivated = false;
                store.dispatch(ApplicationActions.cleanDistance);
            }
        });

        store.subscribeAction({
            after : (action, state) => {
                if (action.type === ApplicationActions.appendLatLng) {
                    this._leafletDistanceMarkers.forEach(distanceMarker => distanceMarker.remove());
                    store.state.applicationState.distanceMarkers.forEach(({ lat, lng }) => {
                        const divIcon = new L.DivIcon({
                            html       : `<div style="width: 1px; height: 1px; display: flex; align-items: center; justify-content: center;"><img width=30 height=30 src="${youAreHereIcon}"></div>`,
                            iconAnchor : [ 1, 1 ],
                        });
                        const marker = new L.marker([ lat, lng ], { // eslint-disable-line new-cap
                            icon         : divIcon,
                            zIndexOffset : 1000000,
                            riseOnHover  : true,
                            riseOffset   : 1000000,
                        }).addTo(this._leafletMap.value);
                        this._leafletDistanceMarkers.push(marker);
                    });
                    if (store.state.applicationState.distanceMarkers.length === 2) {
                        const markers = store.state.applicationState.distanceMarkers;
                        const pline = new L.polyline([ // eslint-disable-line new-cap
                            [ markers[ 0 ].lat, markers[ 0 ].lng ],
                            [ markers[ 1 ].lat, markers[ 1 ].lng ],
                        ], {
                            color       : '#000',
                            riseOnHover : false,
                            dashArray   : '4',
                            weight      : 2,
                        }).addTo(this._leafletMap.value);
                        this._leafletDistanceMarkers.push(pline);
                    }
                } else if (action.type === ApplicationActions.cleanDistance) {
                    this._leafletDistanceMarkers.forEach(distanceMarker => distanceMarker.remove());
                } else if (action.type === MarkerActions.ADD_MARKER
                    || (action.type === MarkerActions.EVENT_DELETE_MARKER && action.payload.eventType === MarkerActions.DELETE_MARKER)) {
                    store.getters[ MarkerGetters.GET_ALL_MARKERS ]
                        .filter(marker => {
                            return this._leafletMarkersContainers.findIndex((leafletMarker) => leafletMarker.id === marker.id) === -1;
                        })
                        .forEach(marker => {
                            this.addMarker({ marker });
                        });
                } else if (action.type === RumorActions.ADD_RUMOR) {
                    store.getters[ RumorGetters.GET_ALL_RUMORS ]
                        .filter(rumor => {
                            return this._leafletRumorsContainers.findIndex((leafletRumor) => leafletRumor.id === rumor.id) === -1;
                        })
                        .forEach(rumor => {
                            if (rumor.isVisible || store.state.applicationState.role === Roles.GAME_MASTER) {
                                this.addRumor(rumor);
                            }
                        });
                } else if (action.type === DiplomaticRelationshipActions.ADD_DIPLOMATIC_RELATIONSHIP) {
                    store.getters[ DiplomaticRelationshipGetters.GET_ALL_DIPLOMATIC_RELATIONSHIP ]
                        .filter(diplomaticRelationship => {
                            return this._leafletDiplomaticRelationshipLines.findIndex((leafletDiplomaticRelationship) => leafletDiplomaticRelationship.id === diplomaticRelationship.id) === -1;
                        })
                        .forEach(diplomaticRelationship => {
                            if (diplomaticRelationship.isVisible || store.state.applicationState.role === Roles.GAME_MASTER) {
                                this.addDiplomaticRelationship(diplomaticRelationship);
                            }
                        });
                } else if (action.type === MarkerActions.DELETE_MARKER) {
                    this._leafletMarkersContainers.filter(leafletMarker => {
                        return store.getters[ MarkerGetters.GET_ALL_MARKERS ].findIndex((marker) => marker.id === leafletMarker.id) === -1;
                    }).forEach(leafletMarker => {
                        this.deleteMarker(leafletMarker);
                    });
                } else if (action.type === DiplomaticRelationshipActions.DELETE_DIPLOMATIC_RELATIONSHIP) {
                    this._leafletDiplomaticRelationshipLines.filter(leafletDiplomaticRelationship => {
                        return store.getters[ DiplomaticRelationshipGetters.GET_ALL_DIPLOMATIC_RELATIONSHIP ].findIndex((diplomaticRelationship) => diplomaticRelationship.id === leafletDiplomaticRelationship.id) === -1;
                    }).forEach(leafletDiplomaticRelationship => {
                        this.deleteDiplomaticRelationship(leafletDiplomaticRelationship);
                    });
                } else if (action.type === MapActions.zoomOnMarker) {
                    this.zoomOnMarker({ id: action.payload });
                } else if (action.type === MapActions.selectEditionDate) {
                    this.clear();
                    displayCursors(cursors, this);
                    store.getters[ MarkerGetters.GET_ALL_MARKERS ].map((marker) => {
                        this.addMarker({ marker });
                    });
                    store.getters[ RumorGetters.GET_ALL_RUMORS ].map((rumor) => {
                        this.addRumor(rumor);
                    });
                    store.getters[ DiplomaticRelationshipGetters.GET_ALL_DIPLOMATIC_RELATIONSHIP ].map((diplomaticRelationship) => {
                        this.addDiplomaticRelationship(diplomaticRelationship);
                    });
                } else if (action.type === MapActions.selectMap
                    || action.type === MapActions.deleteMap
                    || action.type === MapActions.createMap
                    || action.type === MapActions.loadMarkers
                    || action.type === MapActions.loadMarkersFromFile) {
                    this.clear();
                    if (this.currentOverlay) {
                        this.currentOverlay.remove();
                    }
                    const map = store.state.mapState.maps[ store.state.mapState.currentMap ];
                    this._leafletMapSetup(map.url, map.width, map.height);
                    displayCursors(cursors, this);
                    store.getters[ MarkerGetters.GET_ALL_MARKERS ].map((marker) => {
                        this.addMarker({ marker });
                    });
                    store.getters[ RumorGetters.GET_ALL_RUMORS ].map((rumor) => {
                        this.addRumor(rumor);
                    });
                    store.getters[ DiplomaticRelationshipGetters.GET_ALL_DIPLOMATIC_RELATIONSHIP ].map((diplomaticRelationship) => {
                        this.addDiplomaticRelationship(diplomaticRelationship);
                    });
                } else if (action.type === ApplicationActions.zoomOnPlayerMarker) {
                    const playerMarker = store.getters[ MapGetters.getPlayerMarker ];
                    if (playerMarker) {
                        this.zoomOnMarker({ id: playerMarker.id });
                    } else {
                        new Toast('Les joueurs ne sont pas présents à cette date');
                    }
                } else if (action.type === ApplicationActions.toggleMarkerTypeToDimOnMap
                    || action.type === ApplicationActions.updateMarkerToFilterOutFromSearch) {
                    this.dimMarkersOnMap(store.state.applicationState.markerIdsToFilterOut, store.state.applicationState.markerTypesToDim);
                }
            },
        });

        watchEffect(() => {
            const element = document.querySelector('.map-class');
            if (store.state.applicationState.diplomaticRelationshipEnabled) {
                element.style.filter = 'grayscale(0.5) contrast(0.3) brightness(0.5)';
            } else {
                element.style.filter = '';
            }
        });
    }

    _leafletMapSetup(defaultBackground, mapWidth, mapHeight) {
        if (this._leafletMap.value) {
            this._leafletMap.value.remove();
        }
        this._leafletResetZoomLevel = -20;
        const computableBoundsHeight = mapHeight;
        const computableBoundsWidth = mapWidth;
        this._center = [ computableBoundsHeight / 2, computableBoundsWidth / 2 ];
        const scale = 150;
        this._leafletBounds = [ [ -scale, -scale ], [ mapHeight + scale, mapWidth + scale ] ];

        const map = L.map('map', {
            crs       : L.CRS.Simple,
            center    : this._center,
            maxBounds : this._leafletBounds,
            minZoom   : this._leafletResetZoomLevel,
            maxZoom   : 4,
            zoomSnap  : 0.3,
            zoomDelta : 0.3,
        });

        (function() {
            let throttle = function(type, name, obj) {
                obj = obj || window;
                let running = false;
                let func = function() {
                    if (running) { return; }
                    running = true;
                    requestAnimationFrame(function() {
                        obj.dispatchEvent(new CustomEvent(name));
                        running = false;
                    });
                };
                obj.addEventListener(type, func);
            };

            /* init - you can init any event */
            throttle('resize', 'optimizedResize');
            map.doubleClickZoom.disable();
        })();

        // handle event
        this.optimizeListener = () => {
            try {
                map.invalidateSize();
                map.setMinZoom(this._leafletResetZoomLevel);
                const zoom = map.getBoundsZoom(this._leafletBounds);
                map.setMinZoom(zoom);
                console.log('does invalidateSize work?');
            } catch (err) {
                console.error('invalidateSize failed', err);
            }
        };
        window.addEventListener('optimizedResize', this.optimizeListener);

        if (this.currentOverlay) {
            this.currentOverlay.remove();
        }
        this.currentOverlay = L.imageOverlay(defaultBackground, this._leafletBounds, {
            className : 'map-class',
        });
        this.currentOverlay.addTo(map);

        map.fitBounds(this._leafletBounds);
        const zoom = map.getBoundsZoom(this._leafletBounds);
        this._store.dispatch(ApplicationActions.setMapZoomFactor, 2 ** zoom);
        map.setMinZoom(zoom);

        this._controls.forEach(control => map.addControl(control.getControl()));
        if (this._isLoggedIn) {
            this._loggedInControls.forEach(control => map.addControl(control.getControl()));
        }

        nextTick(() => {
            this.setupZoomResetControl();
        });

        map.on('dblclick', event => this.dblClickOnMap(event));
        map.on('click', event => this.clickOnMap(event));
        map.on('zoom', (event) => {
            this._store.dispatch(ApplicationActions.setMapZoomFactor, 2 ** event.target._zoom);
        });
        map.doubleClickZoom.disable();

        document.dispatchEvent(new CustomEvent('mapSetup'));
        this._leafletMap.value = map;
        this.fitScreen();
    }

    setupZoomResetControl() {
        const minusButton = document.getElementsByClassName('leaflet-control-zoom-out')[ 0 ];
        this._lastMinusButtonDownTimer = undefined;
        minusButton.addEventListener('pointerdown', () => {
            this._lastMinusButtonDownTimer = setTimeout(() => {
                this.fitScreen();
            }, 699);
        });
        const clearTimer = () => {
            if (this._lastMinusButtonDownTimer) {
                clearTimeout(this._lastMinusButtonDownTimer);
                this._lastMinusButtonDownTimer = undefined;
            }
        };
        minusButton.addEventListener('pointerup', () => {
            clearTimer();
        });
        minusButton.addEventListener('pointerout', () => {
            clearTimer();
        });
        minusButton.addEventListener('pointerleave', () => {
            clearTimer();
        });
    }

    mapCenter() {
        return this._center;
    }

    addControl(control) {
        this._leafletMap.value.addControl(control);
    }

    clickOnMap(event) {
        if (this._store.state.applicationState.editionModeEnabled
            && this._store.state.applicationState.diplomaticRelationshipEnabled) {
            new Toast('Premier marqueur oublié');
            this._store.dispatch(ApplicationActions.setDiplomaticRelationshipFirstMarkerId, undefined);
        } else if (this._store.state.applicationState.shouldDisplayDistance) {
            if (this.distanceActivated) {
                this._store.dispatch(ApplicationActions.appendLatLng, {
                    lat : event.latlng.lat,
                    lng : event.latlng.lng,
                });
            }
            this.distanceActivated = true;
        }
    }

    zoomIn(event) {
        this._leafletMap.value.setView(event.latlng, this._leafletMap.value.getZoom() + 0.5);
    }

    createRumor(lat, lng) {
        const maxId = this._store.getters[ RumorGetters.MAX_ID_OF_RUMORS ] + 1;
        const rumorsData = new RumorsData(
            maxId,
            lat,
            lng,
            true,
            100,
            []
        );
        this._store.dispatch(RumorActions.ADD_RUMOR, rumorsData);
    }

    createMarker(lat, lng) {
        const maxId = this._store.getters[ MarkerGetters.MAX_ID_OF_MARKERS ] + 1;
        const markerData = new MarkerData(
            maxId,
            lat,
            lng,
            `Marqueur ${maxId} créé le ${new Date().toISOString()}`,
            MarkerType.villages,
            '',
            false,
            true,
            0,
            false,
        );
        this._store.dispatch(MarkerActions.ADD_MARKER, markerData);
    }

    dblClickOnMap(event) {
        L.DomEvent.stopPropagation(event);
        const clickOnMapControl = document.querySelector('.leaflet-control-container').contains(event.originalEvent.explicitOriginalTarget)
            || document.querySelector('.leaflet-control-container').contains(event.originalEvent.target);
        if (clickOnMapControl) {
            return;
        }
        if (!this._store.state.applicationState.editionModeEnabled) {
            this.zoomIn(event);
        } else if (this._store.state.applicationState.diplomaticRelationshipEnabled) {
            new Toast('Premier marqueur oublié');
            this._store.dispatch(ApplicationActions.setDiplomaticRelationshipFirstMarkerId, undefined);
        } else if (this._store.state.applicationState.rumorsEditionEnabled) {
            this.createRumor(event.latlng.lat, event.latlng.lng);
        } else {
            this.createMarker(event.latlng.lat, event.latlng.lng);
        }
    }

    addMarker({ marker }) {
        this._leafletMarkersContainers.push(new LeafletMarkerContainer(marker, this._leafletMap.value, this._eventRouter, this._store));
    }

    addRumor(rumor) {
        this._leafletRumorsContainers.push(new LeafletRumorContainer(rumor, this._leafletMap, this._store));
    }

    addDiplomaticRelationship(diplomaticRelationship) {
        this._leafletDiplomaticRelationshipLines.push(new LeafletDiplomaticRelationshipContainer(diplomaticRelationship, this._leafletMap.value, this._store));
    }

    clear() {
        this._leafletMarkersContainers.forEach(marker => marker.remove());
        this._leafletRumorsContainers.forEach(marker => marker.remove());
        this._leafletDiplomaticRelationshipLines.forEach(marker => marker.remove());
    }

    deleteMarker(leafletMarkerContainer) {
        leafletMarkerContainer.removeEventListeners();
        leafletMarkerContainer.leafletMarker.remove();
        this._leafletMarkersContainers = this._leafletMarkersContainers.filter(lm => lm.id !== leafletMarkerContainer.id);
    }

    deleteDiplomaticRelationship(leafletDiplomaticRelationship) {
        leafletDiplomaticRelationship.stopAll();
        leafletDiplomaticRelationship.leafletPolyline.remove();
        this._leafletDiplomaticRelationshipLines = this._leafletDiplomaticRelationshipLines.filter(ldr => ldr.id !== leafletDiplomaticRelationship.id);
    }

    dimMarkersOnMap(markersIdsToDim, layersToDim) {
        this._leafletMarkersContainers.forEach(leafletMarkerContainer => {
            const isPartOfDimmableMarkers = markersIdsToDim.indexOf(leafletMarkerContainer.id) > -1;
            const isPartOfDimmableLayers = layersToDim.indexOf(leafletMarkerContainer.markerType) > -1;
            if (!isPartOfDimmableMarkers && !isPartOfDimmableLayers) {
                leafletMarkerContainer.turnOpaque();
            } else {
                leafletMarkerContainer.turnHalfTransparent();
            }
        });
    }

    zoomOnMarker({ id }) {
        const marker = this._leafletMarkersContainers.filter(leafletMarker => leafletMarker.id === id)[ 0 ];
        marker._highlightOnMap();
        const leafletMarker = marker.leafletMarker;
        leafletMarker.openPopup();
        this._leafletMap.value.setView(leafletMarker.getLatLng(), 1, { animate: true });
    }

    resetMinZoomLevel() {
        this._leafletMap.value.invalidateSize();
        this._leafletMap.value.setMinZoom(this._leafletResetZoomLevel);
        const zoom = this._leafletMap.value.getBoundsZoom(this._leafletBounds);
        this._leafletMap.value.setMinZoom(zoom);
    }

    fitScreen() {
        this.resetMinZoomLevel();
        this._leafletMap.value.fitBounds(this._leafletBounds);
    }
}
