import { Commit, Dispatch } from "vuex";
import { Roles } from "../../../iam/javascripts/Roles";
import { MapActions } from "../../mapStore/MapActions";
import { MapState } from "../../mapStore/mapState";
import { RootState } from "../../store";
import { MarkerData } from "../markers/MarkerEvents";
import { applyDiff } from "./applyDiff";

export enum EventType {
    ADD_EVENT = 'ADD_EVENT',
    DELETE_EVENT = "DELETE_EVENT",
    CHANGE_EVENT = "CHANGE_EVENT",
}

export type GenericDeleteType = {
    id: number;
}

export type GenericDiffType = {
    id: number;
    [key: string ]: number
        | boolean
        | string
        | number[]
        | boolean[]
        | string[]
        | GenericDiffType
        | GenericDiffType[]
        | { [id: number]: GenericDiffType };
}

export interface GenericData {
    id: number;
    isVisible: boolean;

    toString(): string;
}

export class GenericEvent {
    date: string;
    eventType: EventType;
    data!: any;

    constructor(date: string, eventType: EventType) {
        this.date = date;
        this.eventType = eventType;
    }
}

export class GenericAddEvent<T extends GenericData> extends GenericEvent {
    readonly eventType: EventType.ADD_EVENT = EventType.ADD_EVENT;
    data: T;

    constructor(date: string, data: T) {
        super(date, EventType.ADD_EVENT);
        this.data = data;
    }
}

export class GenericChangeEvent<T extends GenericDiffType> extends GenericEvent {
    readonly eventType: EventType.CHANGE_EVENT = EventType.CHANGE_EVENT;
    data: T;

    constructor(date: string, data: T) {
        super(date, EventType.CHANGE_EVENT);
        this.data = data;
    }
}

export class GenericDeleteEvent<T extends GenericDeleteType> extends GenericEvent {
    readonly eventType: EventType.DELETE_EVENT = EventType.DELETE_EVENT;
    data: T;

    constructor(date: string, data: T) {
        super(date, EventType.DELETE_EVENT);
        this.data = data;
    }
}

function conflateEvents<AddData extends GenericData, ChangeData extends GenericDiffType, DeleteData extends GenericDeleteType>(genericEvents: GenericEvent[]): AddData[] {
    let genericDataList: AddData[] = [];
    genericEvents.forEach(genericEvent => {
        switch (genericEvent.eventType) {
            case EventType.ADD_EVENT:
                const genericAddEvent: GenericAddEvent<AddData> = <GenericAddEvent<AddData>>genericEvent;
                const newData: AddData = <AddData>{ id: genericAddEvent.data.id };
                applyDiff(newData, genericAddEvent.data);
                genericDataList.push(newData);
                break;
            case EventType.DELETE_EVENT:
                const genericDeleteEvent: GenericDeleteEvent<DeleteData> = <GenericDeleteEvent<DeleteData>>genericEvent;
                genericDataList = genericDataList.filter(marker => marker.id !== genericDeleteEvent.data.id);
                break;
            case EventType.CHANGE_EVENT:
                const genericChangeEvent: GenericChangeEvent<ChangeData> = <GenericChangeEvent<ChangeData>>genericEvent;
                const addDataNeedingChange = genericDataList
                    .filter(addData => addData.id === genericChangeEvent.data.id)[0];
                try {
                    applyDiff(addDataNeedingChange, genericChangeEvent.data);
                } catch (error) {
                    console.log('Diff ERROR', error);
                }
                break;
            default:
                throw new Error(`Unexpected event encountered ${JSON.stringify(genericEvent)}`);
        }
    });
    return genericDataList;
}

export class GenericEventStoreFunctions<T extends GenericData> {
    mapStateKey: string;
    commits: any;
    actions: any;
    getters: any;
    commitKeys: string[];
    actionKeys: string[];
    gettersKeys: string[];

    getCommitAddEnum() {
        return this.commitKeys.filter((commitKey) => commitKey.startsWith('ADD_'))[0];
    }

    getCommitDeleteEnum() {
        return this.commitKeys.filter((commitKey) => commitKey.startsWith('DELETE_'))[0];
    }

    getCommitEventDeleteEnum() {
        return this.commitKeys.filter((commitKey) => commitKey.startsWith('EVENT_DELETE_'))[0];
    }

    getCommitChangeEnum() {
        return this.commitKeys.filter((commitKey) => commitKey.startsWith('CHANGE_'))[0];
    }

    getActionAddEnum() {
        return this.actionKeys.filter((actionKey) => actionKey.startsWith('ADD_'))[0];
    }

    getActionDeleteEnum() {
        return this.actionKeys.filter((actionKey) => actionKey.startsWith('DELETE_'))[0];
    }

    getActionEventDeleteEnum() {
        return this.actionKeys.filter((actionKey) => actionKey.startsWith('EVENT_DELETE_'))[0];
    }

    getActionChangeEnum() {
        return this.actionKeys.filter((actionKey) => actionKey.startsWith('CHANGE_'))[0];
    }
    
    getGetterGetElement() {
        return this.gettersKeys.filter((gettersKey) => gettersKey.startsWith('GET_A_'))[0];
    }

    getGetterGetAllElement() {
        return this.gettersKeys.filter((gettersKey) => gettersKey.startsWith('GET_ALL_'))[0];
    }

    getGetterGetEvents() {
        return this.gettersKeys.filter((gettersKey) => gettersKey.startsWith('GET_EVENTS_OF_'))[0];
    }

    getGetterMaxId() {
        return this.gettersKeys.filter((gettersKey) => gettersKey.startsWith('MAX_ID_OF_'))[0];
    }

    debug: boolean = false;
    debugEvent(level: string, type: string, data: any) {
        if (this.debug) {
            console.log(level, type, data);
        }
    }

    constructor(mapStateKey: string, actionKeys:string[], commitKeys: string[], gettersKeys: string[]) {
        this.mapStateKey = mapStateKey;
        this.commitKeys = commitKeys;
        this.actionKeys = actionKeys;
        this.gettersKeys = gettersKeys;
        const self = this;
        this.commits = {
            [this.getCommitAddEnum()](state: MapState, genericData: T) {
                self.debugEvent('COMMIT', self.getCommitAddEnum(), genericData);
                const addData = new GenericAddEvent<T>(state.currentDate, genericData);
                (<Array<GenericEvent>>(<any>state.maps[state.currentMap])[self.mapStateKey]).push(addData);
            },
            [self.getCommitDeleteEnum()](state: MapState, genericDeleteType: GenericDeleteType) {
                self.debugEvent('COMMIT', self.getCommitDeleteEnum(), genericDeleteType);
                const currentMapArray = (<Array<GenericEvent>>(<any>state.maps[state.currentMap])[self.mapStateKey]);

                const presentAndfutureEvents = currentMapArray
                    .filter(genericEvent => genericEvent.data.id !== genericDeleteType.id)
                    .filter(genericEvent => genericEvent.date.localeCompare(state.currentDate) >= 0);
                const pastEvents = currentMapArray
                    .filter(genericEvent => genericEvent.date.localeCompare(state.currentDate) < 0);
                (<Array<GenericEvent>>(<any>state.maps[state.currentMap])[self.mapStateKey]) = pastEvents.concat(presentAndfutureEvents);

                const deleteData: GenericDeleteType = {
                    id: genericDeleteType.id,
                };
                (<Array<GenericEvent>>(<any>state.maps[state.currentMap])[self.mapStateKey]).push(new GenericDeleteEvent(state.currentDate, deleteData));
            },
            [self.getCommitChangeEnum()](state: MapState, diffObject: GenericDiffType) {
                self.debugEvent('COMMIT', self.getCommitChangeEnum(), diffObject);
                const currentMapArray = (<Array<GenericEvent>>(<any>state.maps[state.currentMap])[self.mapStateKey]);

                const genericEvent: GenericEvent = currentMapArray
                    .filter(genericEvent => genericEvent.data.id === diffObject.id)
                    .filter(genericEvent => genericEvent.date.localeCompare(state.currentDate) === 0)
                    .filter(genericEvent =>
                        genericEvent.eventType === EventType.ADD_EVENT
                        || genericEvent.eventType === EventType.CHANGE_EVENT)
                    .sort((a, b) => b.date.localeCompare(a.date))[0];

                if (genericEvent) {
                    applyDiff(genericEvent.data, diffObject);
                } else {
                    (<Array<GenericEvent>>(<any>state.maps[state.currentMap])[self.mapStateKey]).push(new GenericChangeEvent(state.currentDate, diffObject));
                }
            },
            [self.getCommitEventDeleteEnum()](state: MapState, { id }:{ id: number }) {
                self.debugEvent('COMMIT', self.getCommitEventDeleteEnum(), id);
                const currentMapArray = (<Array<GenericEvent>>(<any>state.maps[state.currentMap])[self.mapStateKey]);
                const currentEvent: GenericEvent = currentMapArray
                    .filter(markerEvent => markerEvent.data.id === id && markerEvent.date === state.currentDate)[0];
                if (currentEvent.eventType === EventType.ADD_EVENT) {
                    const presentAndfutureEvents = currentMapArray
                        .filter(genericEvent => genericEvent.data.id !== currentEvent.data.id)
                        .filter(genericEvent => genericEvent.date.localeCompare(state.currentDate) >= 0);
                    const pastEvents = currentMapArray
                        .filter(genericEvent => genericEvent.date.localeCompare(state.currentDate) < 0);
                    (<Array<GenericEvent>>(<any>state.maps[state.currentMap])[self.mapStateKey]) = pastEvents.concat(presentAndfutureEvents);
                } else {
                    (<Array<GenericEvent>>(<any>state.maps[state.currentMap])[self.mapStateKey]) = currentMapArray
                        .filter(markerEvent =>
                            !(markerEvent.data.id === id && markerEvent.date === state.currentDate));
                }

            },
        };
        self.actions = {
            [self.getActionAddEnum()]: (
                    { commit, dispatch }: { commit: Commit, dispatch: Dispatch},
                    genericData: GenericData) => {
                self.debugEvent('ACTION', self.getActionAddEnum(), genericData)
                commit(self.getCommitAddEnum(), genericData);
                dispatch(MapActions.saveState);
            },
            [self.getActionChangeEnum()]: (
                    { commit, dispatch }: { commit: Commit, dispatch: Dispatch},
                    genericDiffType: GenericDiffType) => {
                self.debugEvent('ACTION', self.getActionChangeEnum(), genericDiffType)
                commit(self.getCommitChangeEnum(), genericDiffType)
                dispatch(MapActions.saveState);
            },
            [self.getActionDeleteEnum()]: (
                    { commit, dispatch }: { commit: Commit, dispatch: Dispatch},
                    genericDeleteType: GenericDeleteType) => {
                self.debugEvent('ACTION', self.getActionDeleteEnum(), genericDeleteType)
                commit(self.getCommitDeleteEnum(), genericDeleteType);
                dispatch(MapActions.saveState);
            },
            [self.getActionEventDeleteEnum()]: (
                    { commit, dispatch }: { commit: Commit, dispatch: Dispatch},
                    { id }:{ id: number }) => {
                self.debugEvent('ACTION', self.getActionEventDeleteEnum(), id)
                commit(self.getCommitEventDeleteEnum(), { id })
                dispatch(MapActions.saveState);
            },
        };
        self.getters = {
            [self.getGetterGetElement()]: (state: MapState) => (id: number) => {
                self.debugEvent('GETTER', self.getGetterGetElement(), id);
                const currentMap = (<any>state.maps[state.currentMap]);
                if (!currentMap) return {};
                const currentMapArray = (<Array<GenericEvent>>currentMap[self.mapStateKey]);
                if (!currentMapArray) return {};
                const currentEvents = currentMapArray
                    .filter(event => event.date.localeCompare(state.currentDate) <= 0)
                    .filter(event => event.data.id === id)
                    .sort((a, b) => a.date.localeCompare(b.date))
                    ?? [];
                if (currentEvents.length === 0) return {};
                const data: T = conflateEvents<T, GenericDiffType, GenericDeleteType>(currentEvents)[0];
                if (data === undefined) return {};
                return data;
            },
            [self.getGetterGetAllElement()]: (state: MapState, getters: any, rootState: RootState) => {
                self.debugEvent('GETTER', self.getGetterGetAllElement(), undefined);
                const currentMap = (<any>state.maps[state.currentMap]);
                if (!currentMap) return [];
                const currentMapArray = (<Array<GenericEvent>>currentMap[self.mapStateKey]);
                if (!currentMapArray) return [];
                const currentEvents = currentMapArray
                    .filter(markerEvent => markerEvent.date.localeCompare(state.currentDate) <= 0)
                    .sort((a, b) => a.date.localeCompare(b.date))
                    ?? [];
                const elements = conflateEvents(currentEvents)
                    .filter(marker => marker.isVisible || rootState.applicationState.role === Roles.GAME_MASTER);
                return elements;
            },
            [self.getGetterGetEvents()]: (state: MapState) => {
                self.debugEvent('GETTER', self.getGetterGetEvents(), undefined);
                const currentMap = (<any>state.maps[state.currentMap]);
                if (!currentMap) return [];
                const currentMapArray = (<Array<GenericEvent>>currentMap[self.mapStateKey]);
                if (!currentMapArray) return [];
                const currentEvents = currentMapArray
                    .filter(markerEvent => markerEvent.date === state.currentDate)
                    .map((currentEvent) => {
                        const markerEventsToScan = currentMapArray
                            .filter(markerEvent => markerEvent.data?.id === currentEvent.data?.id)
                            .filter(markerEvent => markerEvent.date.localeCompare(state.currentDate) <= 0)
                            .filter(markerEvent => markerEvent.eventType !== EventType.DELETE_EVENT)
                            .sort((a, b) => a.date.localeCompare(b.date));
                        const addData: MarkerData = <MarkerData>conflateEvents(markerEventsToScan)[0];
                        return {
                            id: currentEvent.data.id,
                            label: addData?.label ?? '',
                            eventType: currentEvent.eventType,
                        }
                    })
                    .sort((
                        a: { label: string, eventType: EventType },
                        b: { label: string, eventType: EventType }) => a.label ? a.label.localeCompare(b.label) : 0)
                    ?? [];
                return currentEvents;
            },
            [self.getGetterMaxId()]: (state: MapState) => {
                self.debugEvent('GETTER', self.getGetterMaxId(), undefined);
                const currentMap = (<any>state.maps[state.currentMap]);
                if (!currentMap) return [];
                const currentMapArray = (<Array<GenericEvent>>currentMap[self.mapStateKey]);
                if (!currentMapArray) return [];
                const maxId = currentMapArray
                    .map((currentEvent) => {
                        return currentEvent.data.id;
                    })
                    .sort((a: number, b: number) => a - b)
                    .reverse()[0]
                    ?? 0;
                return maxId;
            },
        }
    }

    addGetters(givenGetters: any) {
        this.getters = Object.assign(this.getters, givenGetters);
    }
    addCommits(givenCommits: any) {
        this.commits = Object.assign(this.commits, givenCommits);
    }
    addActions(givenActions: any) {
        this.actions = Object.assign(this.actions, givenActions);
    }
}
