import { ApiMapState } from '../models/ApiMapState';
import { MapState } from '../../store/mapStore/mapState';


export function convertMapStateToApi(mapState: MapState): ApiMapState {
    const apiMapState: ApiMapState = {
        version: mapState.version,
        currentPlayersMap: mapState.currentPlayersMap,
        currentPlayersDate: mapState.currentPlayersDate,
        orderedDates: mapState.orderedDates,
        maps: Object.fromEntries(Object.entries(mapState.maps).map(([ key, value ]) => {
            return [ key, {
                id: value.id,
                name: value.name,
                url: value.url,
                width: value.width,
                height: value.height,
                isVisible: value.isVisible,
                markerCategories: value.markerCategories,
                history: value.markerHistory,
                rumorsHistory: value.rumorsHistory,
                diplomaticRelationshipHistory: value.diplomaticRelationshipHistory,
            }];
        })),
    };
    return apiMapState;
}
