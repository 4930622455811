export enum EventEnum {
    ADD_MARKER_EVENT_TO_CORE = 'ADD_MARKER_EVENT_TO_CORE',
    CLEAN_STATE_EVENT_TO_CORE = 'CLEAN_STATE_EVENT_TO_CORE',
    DEACTIVATE_EDITION_MODE_EVENT_FROM_CORE = 'DEACTIVATE_EDITION_MODE_EVENT_FROM_CORE',
    DEACTIVATE_EDITION_MODE_EVENT_TO_CORE = 'DEACTIVATE_EDITION_MODE_EVENT_TO_CORE',
    DELETE_MARKER_EVENT_TO_CORE = 'DELETE_MARKER_EVENT_TO_CORE',
    DELETE_HISTORY_MARKER_EVENT_TO_CORE = 'DELETE_HISTORY_MARKER_EVENT_TO_CORE',
    EDITABLE_MODE_OFF_EVENT_FROM_CORE = 'EDITABLE_MODE_OFF_EVENT_FROM_CORE',
    EDITABLE_MODE_OFF_EVENT_TO_CORE = 'EDITABLE_MODE_OFF_EVENT_TO_CORE',
    EDITABLE_MODE_ON_EVENT_FROM_CORE = 'EDITABLE_MODE_ON_EVENT_FROM_CORE',
    EDITABLE_MODE_ON_EVENT_TO_CORE = 'EDITABLE_MODE_ON_EVENT_TO_CORE',
    EDITION_STARTED_EVENT_FROM_CORE = 'EDITION_STARTED_EVENT_FROM_CORE',
    EDITION_STARTED_EVENT_TO_CORE = 'EDITION_STARTED_EVENT_TO_CORE',
    EDITION_STOPPED_EVENT_FROM_CORE = 'EDITION_STOPPED_EVENT_FROM_CORE',
    EDITION_STOPPED_EVENT_TO_CORE = 'EDITION_STOPPED_EVENT_TO_CORE',
    FULLSCREEN_ON_FROM_CORE = 'FULLSCREEN_ON_FROM_CORE',
    FULLSCREEN_ON_TO_CORE = 'FULLSCREEN_ON_TO_CORE',
    LOCATE_MARKER_EVENT_TO_CORE = 'LOCATE_MARKER_EVENT_TO_CORE',
    MARKER_ADDED_EVENT_FROM_CORE = 'MARKER_ADDED_EVENT_FROM_CORE',
    MARKER_DELETED_EVENT_FROM_CORE = 'MARKER_DELETED_EVENT_FROM_CORE',
    MARKER_LABEL_CHANGED_EVENT_FROM_CORE = 'MARKER_LABEL_CHANGED_EVENT_FROM_CORE',
    MARKER_LABEL_CHANGED_EVENT_TO_CORE = 'MARKER_LABEL_CHANGED_EVENT_TO_CORE',
    MARKER_LOCATION_CHANGED_EVENT_FROM_CORE = 'MARKER_LOCATION_CHANGED_EVENT_FROM_CORE',
    MARKER_LOCATION_CHANGED_EVENT_TO_CORE = 'MARKER_LOCATION_CHANGED_EVENT_TO_CORE',
    MARKER_TEXT_CHANGED_EVENT_FROM_CORE = 'MARKER_TEXT_CHANGED_EVENT_FROM_CORE',
    MARKER_TEXT_CHANGED_EVENT_TO_CORE = 'MARKER_TEXT_CHANGED_EVENT_TO_CORE',
    MARKER_TYPE_CHANGED_EVENT_FROM_CORE = 'MARKER_TYPE_CHANGED_EVENT_FROM_CORE',
    MARKER_TYPE_CHANGED_EVENT_TO_CORE = 'MARKER_TYPE_CHANGED_EVENT_TO_CORE',
    MARKER_VISIBILITY_CHANGED_EVENT_FROM_CORE = 'MARKER_VISIBILITY_CHANGED_EVENT_FROM_CORE',
    MARKER_VISIBILITY_CHANGED_EVENT_TO_CORE = 'MARKER_VISIBILITY_CHANGED_EVENT_TO_CORE',
    MARKER_VISITED_CHANGED_EVENT_FROM_CORE = 'MARKER_VISITED_CHANGED_EVENT_FROM_CORE',
    MARKER_VISITED_CHANGED_EVENT_TO_CORE = 'MARKER_VISITED_CHANGED_EVENT_TO_CORE',
    REWIND_STATE_EVENT_TO_CORE = 'REWIND_STATE_EVENT_TO_CORE',
    SEARCH_EVENT_FROM_CORE = 'SEARCH_EVENT_FROM_CORE',
    SEARCH_EVENT_TO_CORE = 'SEARCH_EVENT_TO_CORE',
    SHOW_LAYER_EVENT_TO_CORE = 'SHOW_LAYER_EVENT_TO_CORE',
    SHOW_MARKER_IN_DOCUMENT_FROM_CORE = 'SHOW_MARKER_IN_DOCUMENT_FROM_CORE',
    SHOW_MARKER_IN_DOCUMENT_TO_CORE = 'SHOW_MARKER_IN_DOCUMENT_TO_CORE',
    STATE_CHANGED_EVENT_FROM_CORE = 'STATE_CHANGED_EVENT_FROM_CORE',
    TOGGLE_VISITED = 'TOGGLE_VISITED',
    ZOOM_ON_MARKER_EVENT_FROM_CORE = 'ZOOM_ON_MARKER_EVENT_FROM_CORE',
}