export enum MarkerType {
    capitales = 'capitales',
    cités = 'cités',
    fleuves = 'fleuves',
    forêts = 'forêts',
    lieux = 'lieux',
    mer = 'mer',
    montagnes = 'montagnes',
    players = 'players',
    regions = 'regions',
    ruines = 'ruines',
    villages = 'villages'
}
