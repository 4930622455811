export enum MarkerEventType {
    ADD_MARKER = 'ADD_MARKER',
    DELETE_MARKER = "DELETE_MARKER",
    CHANGE_MARKER_LOCATION = "CHANGE_MARKER_LOCATION",
    CHANGE_MARKER_LABEL = "CHANGE_MARKER_LABEL",
    CHANGE_MARKER_TEXT = "CHANGE_MARKER_TEXT",
    CHANGE_MARKER_TYPE = "CHANGE_MARKER_TYPE",
    CHANGE_MARKER_IS_VISITED = "CHANGE_MARKER_IS_VISITED",
    CHANGE_MARKER_IS_VISIBLE = "CHANGE_MARKER_IS_VISIBLE",
    CHANGE_MARKER_YOU_ARE_HERE = "CHANGE_MARKER_YOU_ARE_HERE"
}

export class MarkerEvent {
    public eventType: MarkerEventType;
    public date: string;
    public data: any;

    constructor(eventType: MarkerEventType, date: string) {
        this.eventType = eventType;
        this.date = date;
    }
}
