
import { computed, nextTick, reactive, ref, Ref, toRef, watch } from "vue";
import { useStore, Store } from "vuex";
import { RootState } from "../store/store";
import { ApplicationActions } from "../store/applicationState";
import Toast from "../javascripts/toasts/Toast.js";
import { MapActions } from "../store/mapStore/MapActions";
import { MapGetters } from "../store/mapStore/MapGetters";
import { Map } from "../store/domain/Map";
import EditableSelect from "./graphics/EditableSelect.vue";
import { ApiMediaMetadata, getTemporaryUploadUrl } from "./../repository/get-temporary-upload-url";
import { uploadMedia } from "./../repository/upload-media";
import { Roles } from '../iam/javascripts/Roles';
import { marker } from 'leaflet';
import { MarkerCategory } from '../store/domain/MarkerCategory';
import ConfirmationModal from './graphics/ConfirmationModal.vue';
import { Router, useRouter } from 'vue-router';

type InputMap = { name: string; id: string };

export default {
  components: { EditableSelect, ConfirmationModal },
  name: "SelectMap",
  setup() {
    const store: Store<RootState> = useStore();
    const router: Router = useRouter();

    const maps: Ref<Map[]> = toRef(store.getters, MapGetters.getMaps);
    const markerCategories = toRef(store.getters, MapGetters.getMarkerCategories);
    const currentMap: Ref<Map> = toRef(store.getters, MapGetters.getCurrentMap);
    const editionModeActivated = toRef(store.state.applicationState, 'editionModeEnabled');
    const shouldDisplaySelectMap: Ref<boolean> = toRef(
      store.state.applicationState,
      "shouldDisplaySelectMap"
    );

    const mapFileInputRef: Ref<HTMLElement | null> = ref(null);
    const markerFileInputRef: Ref<HTMLElement | null> = ref(null);
    const editMarkerFileInputRef: Ref<HTMLElement | null> = ref(null);

    const markerCategoryId: Ref<string> = ref("");
    const chosenMarkerName: Ref<string> = ref("");
    const chosenMarkerWidth: Ref<number> = ref(20);
    const chosenMarkerHeight: Ref<number> = ref(20);
    const chosenMarkerCenterX: Ref<number> = ref(50);
    const chosenMarkerCenterY: Ref<number> = ref(50);
    const markerGetUrl: Ref<string> = ref("");
    const shouldDisplayMarkerCreationArea: Ref<boolean> = ref(false);

    const chosenMapName: Ref<string> = ref("");
    const mapGetUrl: Ref<string> = ref("");
    const shouldDisplayDeleteModal: Ref<boolean> = ref(false);
    const shouldDisplayMapCreationArea: Ref<boolean> = ref(false);
    const shouldDisplayEditionArea: Ref<boolean> = computed(() => {
      return !shouldDisplayMapCreationArea.value
        && !shouldDisplayMarkerCreationArea.value
        && store.state.applicationState.role === Roles.GAME_MASTER;
    });
    const isVisible = computed({
      get: () => {
        return !currentMap.value.isVisible;
      },
      set: (isVisible: boolean) => {
        store.dispatch(MapActions.changeMapIsVisible, { id: currentMap.value.id, isVisible: !isVisible });
      },
    });

    function startNewMap(newMap: InputMap) {
      chosenMapName.value = newMap.name;
      mapFileInputRef.value?.click();
    }

    function startNewMarker() {
      chosenMarkerName.value = "Nouvelle catégorie";
      chosenMarkerWidth.value = 20;
      chosenMarkerHeight.value = 20;
      chosenMarkerCenterX.value = 50;
      chosenMarkerCenterY.value = 50;
      markerCategoryId.value = "";
      markerGetUrl.value = "";
      markerFileInputRef.value?.click();
    }

    function loadImage(event: Event): Promise<ApiMediaMetadata | undefined> {
        return new Promise((resolve, reject) => {
            const files = (<HTMLInputElement | null>event.target)?.files;
            if (files) {
                let reader = new FileReader();
                reader.onload = (theFile) => {
                    const rawResult: string = <string>theFile.target?.result ?? '';
                    new Toast('Uploading ...');
                    getTemporaryUploadUrl().then(async (apiMediaMetadata) => {
                        await uploadMedia(apiMediaMetadata, files[0]);
                        resolve(apiMediaMetadata);
                    });
                };
                reader.onabort = () => resolve(undefined);
                reader.onerror = (error) => reject(error);
                reader.readAsText(files[0]);
            }
        });
    }

    function mapLoadImage(event: Event) {
      loadImage(event)
        .then((apiMediaMetadata: ApiMediaMetadata | undefined) => {
          if (apiMediaMetadata) {
            mapGetUrl.value = apiMediaMetadata.resourceAccessUrl;
            shouldDisplayMapCreationArea.value = true;
          }
        });
    }

    function markerLoadImage(event: Event) {
      loadImage(event)
        .then((apiMediaMetadata: ApiMediaMetadata | undefined) => {
          if (apiMediaMetadata) {
            markerGetUrl.value = apiMediaMetadata.resourceAccessUrl;
            shouldDisplayMarkerCreationArea.value = true;
          }
        });
    }

    function editMarker(id: string) {
      const markerCategory = store.getters[MapGetters.getMarkerCategories].filter((markerCategory: MarkerCategory) => markerCategory.id === id)[0];
      chosenMarkerName.value = markerCategory.name;
      chosenMarkerWidth.value = markerCategory.width;
      chosenMarkerHeight.value = markerCategory.height;
      chosenMarkerCenterX.value = markerCategory.centerX;
      chosenMarkerCenterY.value = markerCategory.centerY;
      markerCategoryId.value = markerCategory.id;
      markerGetUrl.value = markerCategory.url;
      shouldDisplayMarkerCreationArea.value = true;
    }

    function editImage() {
      editMarkerFileInputRef.value?.click();
    }

    function editMarkerLoadImage(event: Event) {
      loadImage(event)
        .then((apiMediaMetadata: ApiMediaMetadata | undefined) => {
          if (apiMediaMetadata) {
            markerGetUrl.value = apiMediaMetadata.resourceAccessUrl;
          }
        });
    }

    function createMap() {
        shouldDisplayMapCreationArea.value = false;
        const img = new Image();
        img.onload = (event) => {
            const mapWidth = (<HTMLImageElement>(event?.target))?.width;
            const mapHeight = (<HTMLImageElement>(event?.target))?.height;
            store.dispatch(MapActions.createMap, { name: chosenMapName.value, url: mapGetUrl.value, width: mapWidth, height: mapHeight });
        }
        img.src = mapGetUrl.value;
    }

    function cancelMap() {
        shouldDisplayMapCreationArea.value = false;
    }

    function createMarker() {
        shouldDisplayMarkerCreationArea.value = false;
        store.dispatch(
          MapActions.createMarkerCategory, {
            id: markerCategoryId.value,
            name: chosenMarkerName.value,
            url: markerGetUrl.value,
            width: chosenMarkerWidth.value,
            height: chosenMarkerHeight.value,
            centerX: chosenMarkerCenterX.value,
            centerY: chosenMarkerCenterY.value,
          })
    }

    function cancelMarker() {
        shouldDisplayMarkerCreationArea.value = false;
    }

    function selectMap(map: InputMap) {
        store.dispatch(MapActions.selectMap, map);
        store.dispatch(ApplicationActions.markRouteChangeAsProgramatic);
        router.replace({ query: { currentMap: map.id } });
    }

    function showDeleteModal() {
      shouldDisplayDeleteModal.value = true;
    }

    function hideDeleteModal() {
      shouldDisplayDeleteModal.value = false;
    }

    function deleteMap() {
      store.dispatch(MapActions.deleteMap);
      hideDeleteModal();
    }

    return {
      cancelMap,
      cancelMarker,
      chosenMapName,
      chosenMarkerName,
      chosenMarkerWidth,
      chosenMarkerHeight,
      chosenMarkerCenterX,
      chosenMarkerCenterY,
      createMap,
      createMarker,
      currentMap,
      deleteMap,
      editionModeActivated,
      editImage,
      editMarker,
      editMarkerFileInputRef,
      editMarkerLoadImage,
      hideDeleteModal,
      mapFileInputRef,
      markerCategories,
      markerFileInputRef,
      mapGetUrl,
      markerGetUrl,
      isVisible,
      mapLoadImage,
      markerLoadImage,
      maps,
      selectMap,
      showDeleteModal,
      shouldDisplayDeleteModal,
      shouldDisplayEditionArea,
      shouldDisplayMapCreationArea,
      shouldDisplayMarkerCreationArea,
      shouldDisplaySelectMap,
      startNewMap,
      startNewMarker,
    };
  },
};
