<template>
    <div style="width: 15px; height: 20px;">
<svg
   xmlns:dc="http://purl.org/dc/elements/1.1/"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   width="15px"
   height="20px"
   viewBox="0 0 15 20"
   version="1.1"
   id="svg8">
  <defs
     id="defs2" />
  <metadata
     id="metadata5">
  </metadata>
  <g
     id="layer1">
    <g
       id="g889"
       transform="matrix(0.06399448,-0.33206713,0.15946198,0.13003972,4.2268707,15.663446)"
       :style="`opacity:1;fill:#${color};fill-opacity:1;stroke:#${color};stroke-width:3.18177;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:0.501792`">
      <path
         :style="`color:#${color};font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:medium;line-height:normal;font-family:sans-serif;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-variant-east-asian:normal;font-feature-settings:normal;font-variation-settings:normal;text-indent:0;text-align:start;text-decoration:none;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#${color};letter-spacing:normal;word-spacing:normal;text-transform:none;writing-mode:lr-tb;direction:ltr;text-orientation:mixed;dominant-baseline:auto;baseline-shift:baseline;text-anchor:start;white-space:normal;shape-padding:0;shape-margin:0;inline-size:0;clip-rule:nonzero;display:inline;overflow:visible;visibility:visible;isolation:auto;mix-blend-mode:normal;color-interpolation:sRGB;color-interpolation-filters:linearRGB;solid-color:#${color};solid-opacity:1;vector-effect:none;fill:#${color};fill-opacity:1;fill-rule:nonzero;stroke:#${color};stroke-width:3.18177;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:0.501792;color-rendering:auto;image-rendering:auto;shape-rendering:auto;text-rendering:auto;enable-background:accumulate;stop-color:#${color};stop-opacity:1`"
         d="M 17.639791,42.763124 5.8555051,4.4584323 -16.205797,-16.92054 29.586211,-35.331316 Z"
         id="path891" />
      <path
         :style="`color:#${color};font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:medium;line-height:normal;font-family:sans-serif;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-variant-east-asian:normal;font-feature-settings:normal;font-variation-settings:normal;text-indent:0;text-align:start;text-decoration:none;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#${color};letter-spacing:normal;word-spacing:normal;text-transform:none;writing-mode:lr-tb;direction:ltr;text-orientation:mixed;dominant-baseline:auto;baseline-shift:baseline;text-anchor:start;white-space:normal;shape-padding:0;shape-margin:0;inline-size:0;clip-rule:nonzero;display:inline;overflow:visible;visibility:visible;isolation:auto;mix-blend-mode:normal;color-interpolation:sRGB;color-interpolation-filters:linearRGB;solid-color:#${color};solid-opacity:1;vector-effect:none;fill:#${color};fill-opacity:1;fill-rule:nonzero;stroke:#${color};stroke-width:3.18177;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:0.501792;color-rendering:auto;image-rendering:auto;shape-rendering:auto;text-rendering:auto;enable-background:accumulate;stop-color:#${color};stop-opacity:1`"
         d="M 29.835938,-35.642578 -7.2890625,-21.167969 -16.205797,-16.92054 5.8555051,4.4584323 17.785156,43.136719 l 0.05273,-0.34375 5.972656,-39.0468752 z m -0.5,0.623047 L 23.416016,3.6855469 17.494141,42.390625 5.8555051,4.4584323 -16.205797,-16.92054 l 9.0612658,-3.874382 z"
         id="path893" />
    </g>
  </g>
</svg>
    </div>
</template>

<script lang="ts">
import { toRefs, PropType } from 'vue';

export default {
    name: 'Cursor',
    props: {
        color: {
            type: String as PropType<string>,
            required: true
        },
    },
    setup(props: Readonly<{ color: string } & {}>) {
        const { color } = toRefs(props);
        
        return {
            color,
        }
    },
}
</script>

<style lang="postcss" scoped>
.leaflet-div-icon {
    background-color: transparent !important;
}

.map-icon-container {
    position: relative;
    overflow: show;
    display: flex;
    justify-content: center;

    background-color: transparent;

    &.hidden {
        display: none;
    }

    &.gm-hidden {
        filter: grayscale(1) brightness(1) contrast(0.8);
    }

    .aligned-container {
        position: absolute;
        top: -10px;
        left: -10px;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 20px;
        height: 20px;
    }

    .line-container {
        position: absolute;
        top: -5px;
    }

    .visited-background {
        z-index: 1;
        position: absolute;

        box-shadow: 0px 0px 4px 11px #FFF;
        border-radius: 100px;
        opacity: 0.5;

        &.visited {
            display: none;
        }
    }

    @keyframes pulse {
        0% {
            width: 1px;
            height: 1px;
            
            background: white;

            opacity: 1;
        }
        30% {
            width: 38px;
            height: 38px;

            opacity: 0.8;
        }
        100% {
            width: 86px;
            height: 86px;

            opacity: 0;
        }
    }


    .for-animation {
        z-index: 2;
        position: absolute;
        width: 1px;
        height: 1px;

        border-radius: 50px;

        &.animate {
            animation: pulse 1.5s linear infinite;
        }
    }

    .map-icon {
        z-index: 3;
        position: absolute;
        background-color: transparent;

        &.player {
            bottom: 0;
        }

        img {
            background-color: transparent;
        }
    }

    .fleuve {
        width: max-content;
        color: black;
        font-size: calc(15px * var(--map-zoom-factor));
        text-align: center;
        text-transform: lowercase;
        font-weight: 800;
        text-shadow: 0px 0px 8px #fff,0px 0px 8px #fff;
    }
}
</style>