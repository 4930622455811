
import { nextTick, ref, Ref, toRef, SetupContext, toRefs } from 'vue';

type Item = { name: string, id: any };

export default {
    name: 'EditableSelect',
    props: {
        message: {
            type: String,
        },
        okText: {
            type: String,
        },
        cancelText: {
            type: String,
        },
    },
    setup(props: Readonly<{ message?: string | undefined; okText?: string | undefined; cancelText?: string | undefined; } & {}>, { emit }: SetupContext) {
        const preselectedItem: Ref<Item | undefined> = ref();
        const shouldShowList: Ref<boolean> = ref(false);
        const message: Ref<string> = <Ref<string>>toRef(props, 'message');
        const okText: Ref<string> = <Ref<string>>toRef(props, 'okText');
        const cancelText: Ref<string> = <Ref<string>>toRef(props, 'cancelText');
        
        return {
            message,
            okText,
            cancelText,
            emit,
        }
    },
}
