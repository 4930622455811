import { createStore, Store } from 'vuex';
import { applicationState, ApplicationState } from './applicationState';
import { mapState, MapState } from './mapStore/mapState';


export class RootState {
    public mapState: MapState = new MapState();
    public applicationState: ApplicationState = new ApplicationState();
}

export function getStore(): Store<RootState> {
    return createStore<RootState>({
        strict: true,
        modules: {
            mapState,
            applicationState,
        },
    });
}


