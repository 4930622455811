
import { useStore, Store } from 'vuex';
import { RootState } from '../../store/store';
import { ApplicationActions } from '../../store/applicationState';
import { MapGetters } from '../../store/mapStore/MapGetters';
import { computed, markRaw, nextTick, onBeforeMount, onMounted, PropType, Ref, ref, toRaw, toRef, watch } from 'vue';
import { Marker } from '../../store/domain/Marker';
import MarkerTypes from './MarkerTypes.js';
import { MarkerType } from '../../store/domain/MarkerType';
import { MarkerCategory } from '../../store/domain/MarkerCategory';
import { MarkerGetters } from '../../store/domain/markers/MarkerEvents';
import L from 'leaflet';
import { DiplomaticRelationshipActions, DiplomaticRelationshipData, DiplomaticRelationshipGetters, DiplomaticRelationshipNature } from '../../store/domain/diplomaticRelationship/diplomaticRelationshipEvents';
import Toast from '../toasts/Toast';

export default {
    name: 'MarkerIcon',
    props: {
        id: {
            type: Number,
            required: true
        },
        leafletMarker: {
            type: Object,
            required: true,
        },
        map: {
            type: Object,
            required: true,
        },
    },
    setup(props: Readonly<{ id: number; leafletMarker: any; map: any } & {}>) {
        const store: Store<RootState> = useStore();
        const popup = ref();
        const relationshipNature = ref(DiplomaticRelationshipNature.NEUTRAL);
        const marker = computed(() => {
            return store.getters[MarkerGetters.GET_A_MARKER](props.id);
        });
        const markerIcon = computed(() => {
            const category = store.getters[ MapGetters.getMarkerCategories ].filter((category: MarkerCategory) => category.id === marker.value?.type)[0];
            if (category) {
                return {
                    iconUrl     : category.url,
                    iconSize    : [ category.width, category.height ],
                    visitedSize : [ category.width / 7, category.height / 7 ],
                    iconAnchor  : [ category.centerX, category.centerY ],
                    isNewCategory : true,
                }
            } else {
                return new MarkerTypes().markerIcons[ <any>(marker.value?.type) ]
            }
        });

        const markerClass = computed(() => {
            return {
                'aligned-container': true, // marker.value?.type !== MarkerType.fleuves,
                // 'line-container': marker.value?.type === MarkerType.fleuves,
            };
        });
        const unvisitedBackgroundStyle = computed(() => {
            return {
                width: markerIcon.value.visitedSize[0] + 'px',
                height: markerIcon.value.visitedSize[1] + 'px',
            };
        });
        const unvisitedClasses = computed(() => {
            return {
                'unvisited': !marker.value.isVisited,
            }
        });
        const shouldShowVisited = computed(() => {
            return marker.value?.type !== 'players' && store.state.applicationState.showVisited;
        });
        const playerClass = computed(() => {
            return {
                'player': marker.value?.type === MarkerType.players,
            };
        });
        const sourceMapZoomFactor = toRef(store.state.applicationState, 'mapZoomFactor');
        const mapIconStyle = computed(() => {
            const zoomFactor = sourceMapZoomFactor.value <= 12 ? 1 : sourceMapZoomFactor.value / 12;
            return {
                width: (markerIcon.value?.iconSize[0]/zoomFactor) + 'px',
                height: (markerIcon.value?.iconSize[1]/zoomFactor) + 'px',
                top: markerIcon.value?.isNewCategory ? `calc((20px / 2) - (${markerIcon.value?.iconAnchor[1]} * ${markerIcon.value?.iconSize[1]}px / 100))` : false,
                left: markerIcon.value?.isNewCategory ? `calc((20px / 2) - (${markerIcon.value?.iconAnchor[0]} * ${markerIcon.value?.iconSize[0]}px / 100))` : false,
            };
        });
        const mapIconImgStyle = computed(() => {
            const zoomFactor = sourceMapZoomFactor.value <= 12 ? 1 : sourceMapZoomFactor.value / 12;
            return {
                width: (markerIcon.value?.iconSize[0]*zoomFactor) + 'px',
                height: (markerIcon.value?.iconSize[1]*zoomFactor) + 'px',
            };
        });
        const markerIconUrl = computed(() => {
            return markerIcon.value?.iconUrl;
        });
        const fleuveStyle = computed(() => {
            // console.log('orient', marker.value.orientation);
            return {
                display: marker.value?.type === MarkerType.fleuves ? undefined : 'none',
                transform: `rotate(${ marker.value?.orientation ?? 0 }deg)`,
            };
        });
        function selectedMarker() {
            if (store.state.applicationState.shouldDisplayDistance) {
                store.dispatch(ApplicationActions.appendLatLng, {
                    lat: props.leafletMarker.getLatLng().lat,
                    lng: props.leafletMarker.getLatLng().lng,
                });
            } else if (store.state.applicationState.editionModeEnabled
                && store.state.applicationState.diplomaticRelationshipEnabled) {
                if (!store.state.applicationState.diplomaticRelationshipFirstMarkerId) {
                    store.dispatch(ApplicationActions.setDiplomaticRelationshipFirstMarkerId, marker.value.id);
                    nextTick(() => {
                        new Toast('Cliquez sur le second marqueur');
                        new Toast('Cliquez ailleurs sur la carte pour annuler');
                    })
                } else {
                    const maxId = store.getters[DiplomaticRelationshipGetters.MAX_ID_OF_DIPLOMATIC_RELATIONSHIP];
                    const diplomaticRelationshipData = toRaw(new DiplomaticRelationshipData(
                        maxId + 1,
                        [ 
                            store.state.applicationState.diplomaticRelationshipFirstMarkerId,
                            marker.value.id,
                        ],
                        relationshipNature.value as DiplomaticRelationshipNature,
                    ));
                    console.log(diplomaticRelationshipData.id);
                    store.dispatch(DiplomaticRelationshipActions.ADD_DIPLOMATIC_RELATIONSHIP, diplomaticRelationshipData);
                    store.dispatch(ApplicationActions.setDiplomaticRelationshipFirstMarkerId, undefined);
                }
            } else {
                store.dispatch(ApplicationActions.displayMarkerEditionMenu, marker.value?.id);
            }
        }
        function highlightRelationships() {
            if (store.state.applicationState.diplomaticRelationshipEnabled) {
                console.log('highlight', marker.value?.id);
                store.dispatch(ApplicationActions.displayDiplomaticRelationshipLinesFromThisMarkerId, marker.value?.id);
            }
        }
        function hideRelationships() {
            if (store.state.applicationState.diplomaticRelationshipEnabled) {
                if (marker.value?.id === store.state.applicationState.displayDiplomaticRelationshipLinesFromThisMarkerId) {
                    console.log('hide', marker.value?.id);
                    store.dispatch(ApplicationActions.displayDiplomaticRelationshipLinesFromThisMarkerId, undefined);
                }
            }
        }
        const shouldDisplayMarkerIcon = computed(() => {
            let isVisible = true;
            if (!store.state.applicationState.diplomaticRelationshipEnabled) {
                isVisible = true;
            } else {
                const rels = store.getters[DiplomaticRelationshipGetters.GET_ALL_DIPLOMATIC_RELATIONSHIP]
                    .filter((dip: DiplomaticRelationshipData) => dip.markerIds.indexOf(marker.value.id) !== -1);
                isVisible = rels.length > 0;
            }
            return {
                filter: isVisible ? '' : 'grayscale(0.5) contrast(0.3) brightness(0.5)',
            }
        })
        
        return {
            fleuveStyle,
            hideRelationships,
            highlightRelationships,
            mapIconImgStyle,
            mapIconStyle,
            marker,
            markerClass,
            markerIconUrl,
            shouldDisplayMarkerIcon,
            playerClass,
            popup,
            relationshipNature,
            selectedMarker,
            shouldShowVisited,
            unvisitedBackgroundStyle,
            unvisitedClasses,
        }
    },
}
