<template>
    <div v-if="shouldDisplayRumorEditionMenu" @click.stop>
        <div class="rumors-edition-nav" :key="rumors.id">
            <h3>Rumeurs et légendes</h3>
            <div v-if="isGameMaster" class="radius">
              Radius <input data-test="rumor-radius" type="number" :disabled="!editionModeEnabled" :value="rumors.radius" @change="modifyRadius($event)" />
            </div>
            <label v-if="isGameMaster" class="rumors-visiblity">
              <input data-test="rumors-are-visible" type="checkbox" :disabled="!editionModeEnabled" :checked="rumors.isVisible" @change="modifyVisibility($event)" />
              <span>Publique</span>
            </label>
            <div class="new-rumor" v-if="isGameMaster && editionModeEnabled">
                <input type="text" v-model="newLabel">
                <a class="add" @click="addRumor">+</a>
            </div>
            <div v-for="rumor in rumorDataList" :key="rumor.id" class="ruedna-field">
                <div class="accordeon-head" @click="expandRumor(rumor)">
                  <div class="rumor">
                    <div class="icon" data-test="event-delete">
                        <img v-if="rumorsToExpand[rumor.id]" :src="require('../statics/images/arrow-up.svg')"/>
                        <img v-if="!rumorsToExpand[rumor.id]" :src="require('../statics/images/arrow-down.svg')"/>
                    </div>
                    <div v-if="!editionModeEnabled" class="rumor-label" v-html="rumor.label"></div>
                    <input v-if="editionModeEnabled" data-test="label" class="rumor-label raw-text-editable" type="text" :disabled="!editionModeEnabled" :value="rumor.label" @change="modifyRumorLabel(rumor, $event)" @click.stop>
                  </div>
                  <div v-if="isGameMaster" class="icon" data-test="event-delete" @click.stop.exact="deleteRumorFromEvent(rumor)">
                      <img :src="require('../statics/images/recycle.png')"/>
                  </div>
                </div>
                <div v-if="rumorsToExpand[rumor.id]" class="accordeon-body">
                    <Editor data-test='rumor-text' :class="{ 'no-margin': editionModeEnabled, 'rumor-text': !editionModeEnabled }" :modelValue="rumor.text" @update:modelValue="(text) => modifyText(rumor, text)" :key="rumor.id + editionModeEnabled" :readonly="!editionModeEnabled" />
                    <label v-if="isGameMaster">
                      <input data-test="rumor-is-visible" type="checkbox" :disabled="!editionModeEnabled" :checked="rumor.isVisible" @change="modifyRumorVisibility(rumor, $event)" />
                      <span>Publique</span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, markRaw, nextTick, onMounted, reactive, ref, Ref, shallowReactive, shallowReadonly, shallowRef, toRaw, toRef, unref, watch, watchEffect } from 'vue';
import { useStore, Store } from 'vuex';
import * as showdown from 'showdown';
import { RootState } from '../store/store';
import { ApplicationActions, ApplicationState } from '../store/applicationState';
import { MapActions } from "../store/mapStore/MapActions";
import { MapGetters } from "../store/mapStore/MapGetters";
import { Map } from "../store/domain/Map";
import { MapState } from '../store/mapStore/mapState';
import { RumorActions, RumorData, RumorGetters, RumorsData, RumorsDiffType } from '../store/domain/rumors/RumorsEvents';
import Editor from './graphics/Editor.vue';
import { Roles } from '../iam/javascripts/Roles';
import { RouteLocationNormalized, Router, useRoute, useRouter } from 'vue-router';

export default {
    name: 'RumorEdition',
    components: {
        Editor,
    },
    setup() {
        const store: Store<RootState> = useStore();
        const router: Router = useRouter();

        const newLabel: Ref<string> = ref('');

        const editionModeEnabled: Ref<boolean> = toRef(store.state.applicationState, 'editionModeEnabled');
        const rumorsId: Ref<number> = toRef(store.state.applicationState, 'rumorEditionMenuRumorId');
        const shouldDisplayRumorEditionMenu: Ref<boolean> = toRef(store.state.applicationState, 'shouldDisplayRumorEditionMenu');
        const isGameMaster = computed(() => store.state.applicationState.role === Roles.GAME_MASTER );

        watch([ rumorsId, shouldDisplayRumorEditionMenu ], () => {
            if (shouldDisplayRumorEditionMenu.value) {
                store.dispatch(ApplicationActions.markRouteChangeAsProgramatic);
                router.replace({ query: { currentMap: router.currentRoute.value.query.currentMap, rumorsId: rumorsId.value.toString() } });
            }
        });

        const rumors = computed(() => {
            return store.getters[RumorGetters.GET_A_RUMOR](rumorsId.value);
        });

        const rumorDataList = computed(() => {
            const rumorDataList = store.getters[RumorGetters.GET_A_RUMOR](rumorsId.value).rumorDataList;
            return [...rumorDataList]
              .sort((a, b) => a.label.localeCompare(b.label))
              .filter(rumor => {
                return rumor.isVisible===true || isGameMaster.value;
              });
        });

        const rumorsToExpand = ref(
            Object.fromEntries(
                [...Object.values(rumorDataList)].map((rumorData: RumorData): [ number, boolean ] => {
                    return [rumorData.id, false];
                })
            )
        );
        
        function deleteRumor() {
            store.dispatch(RumorActions.DELETE_RUMOR, rumorsId.value);
            store.dispatch(ApplicationActions.hideAllMenu);
        }

        function addRumor() {
            if (newLabel.value.length > 0) {
              const rumorsDiff: RumorsDiffType = toRaw({
                id: rumorsId.value,
                rumorDataList: [
                    {
                        id: (toRaw(rumors.value.rumorDataList).sort((a: RumorData, b: RumorData) => b.id - a.id)[0]?.id ?? 0) + 1,
                        label: newLabel.value,
                        text: '',
                        isVisible: true,
                    },
                ],
              });
              newLabel.value = '';
              store.dispatch(RumorActions.CHANGE_RUMOR, rumorsDiff);
            }
        }

        function deleteRumorFromEvent(rumorToDelete: RumorData) {
          const rumorsDiff: RumorsDiffType = toRaw({
              id: rumorsId.value,
              patch_prune_array: {
                rumorDataList: [rumorToDelete.id],
              }
            });
          store.dispatch(RumorActions.CHANGE_RUMOR, rumorsDiff);
        }

        function expandRumor(rumorToExpand: RumorData) {
          rumorsToExpand.value[rumorToExpand.id] = !rumorsToExpand.value[rumorToExpand.id];
        }

        function modifyText(rumor: RumorData, text: string) {
          const rumorsDiff: RumorsDiffType = toRaw({
              id: rumorsId.value,
              rumorDataList: [
                  {
                      id: rumor.id,
                      text,
                  },
              ]
            });
          store.dispatch(RumorActions.CHANGE_RUMOR, rumorsDiff);
        }
        
        function modifyRumorVisibility(rumor: RumorData, event: Event) {
          const checked: boolean = (<HTMLInputElement>event.target).checked;
          const rumorsDiff: RumorsDiffType = toRaw({
            id: rumorsId.value,
            rumorDataList: [
                {
                    id: rumor.id,
                    isVisible: checked,
                },
            ]
          });
          store.dispatch(RumorActions.CHANGE_RUMOR, rumorsDiff);
        }
        
        function modifyVisibility(event: Event) {
          const checked: boolean = (<HTMLInputElement>event.target).checked;
          const rumorsDiff: RumorsDiffType = toRaw({
            id: rumorsId.value,
            isVisible: checked,
          });
          store.dispatch(RumorActions.CHANGE_RUMOR, rumorsDiff);
        }

        function modifyRadius(event: Event) {
          const value: number = parseInt((<HTMLInputElement>event.target).value);
          const rumorsDiff: RumorsDiffType = toRaw({
            id: rumorsId.value,
            radius: value,
          });
          store.dispatch(RumorActions.CHANGE_RUMOR, rumorsDiff);
        }

        function modifyRumorLabel(rumor: RumorData, event: Event) {
          const label: string = (<HTMLInputElement>event.target).value;
          const rumorsDiff: RumorsDiffType = toRaw({
            id: rumorsId.value,
            rumorDataList: [
                {
                    id: rumor.id,
                    label,
                },
            ]
          });
          store.dispatch(RumorActions.CHANGE_RUMOR, rumorsDiff);
        }

        return {
            addRumor,
            deleteRumorFromEvent,
            deleteRumor,
            editionModeEnabled,
            expandRumor,
            isGameMaster,
            modifyRadius,
            modifyRumorLabel,
            modifyRumorVisibility,
            modifyVisibility,
            modifyText,
            newLabel,
            rumors,
            rumorDataList,
            rumorsId,
            rumorsToExpand,
            shouldDisplayRumorEditionMenu,
        }
    },
}
</script>

<style lang="postcss" scoped>
$side-nav-length: 250px;
$list-side-margin: 5px;
$item-side-margin: 5px;
$inside-text-min-length: $side-nav-length - $list-side-margin * 2 - $item-side-margin * 2;

h3 {
  margin: 10px;
  color: #424242;
}

.rumors-edition-nav {
  position: absolute;
  top:0;
  left: 0;
  right: auto;
  z-index: 10000;
  height: 100vh;
  max-width: 450px;
  width: 70vw;
  background-color: white;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  resize: horizontal;
  transition: all 0.2s ease;
}

.radius {
  margin: 10px;
}

.rumors-visiblity {
  margin: 10px;
}

.new-rumor {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  padding-left: 10px;

  .add {
    font-size: 22px;
    cursor: pointer;
    padding-left: 5px;
  }
}

.accordeon-head {
  display:flex;
  flex-direction: row;
  align-items:center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  height: 40px;

  &:hover {
    background-color: #f0f0f0;
    transition: 0.2s;
  }
}

.accordeon-body {
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  .rumor-text {
    padding: 10px;
    margin-bottom: 5px;
  }
}

.ruedna-field {
  margin: 0 $list-side-margin;
  border-bottom: 0.5px solid #ffc8ca;
  align-items:center;
  display:flex;
  flex-wrap: wrap;
  font-size: 16px;
  line-height: 24px;
  min-width: $inside-text-min-length; 

  .rumor {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;

    .rumor-label {
      font-weight: 700;
      width: 100%;
    }

    .icon {
      padding-right: 8px;
    }
  }

  .icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 12px;

    cursor: pointer;
  }

  textarea {
    resize: vertical;
    height: auto;
    font-family: monospace;
    width: 100%;
  }

  select {
    width: 100%;
    height: 30px;
    background-color: white;
    padding: 0;
    border: none;
    border-radius: 0;
    &:hover {
      background-color: #f0f0f0;
      transition: 0.2s;
    }
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    margin: 0 1px;
    text-transform: capitalize;
  }

  > .no-margin {
      margin: 0;
  }

  :deep() p {
      margin: 0;
  }

  > * {
    color: #A52A2A;
    padding: 0 $item-side-margin;
    min-height: 20px;
    img {
      min-width: 0;
      max-width: 100%;
      max-height: 100%;
    }
  }
  &:first-child {
    font-size: 1.2em;
  }
  &:last-child {
    border-bottom: none;
  }
  .raw-text-editable {
    width: 100%;
    border: none;
    background-color: inherit;
    font-size: inherit;
    font-family: inherit;
  }
  .raw-text-editable:focus {
    box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  }
}

</style>