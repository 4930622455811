<template>
    <div v-if="shouldDisplaySaveLoad" @click.stop>
      <div id="save-load-menu" class="save-load-menu">
          <h3>Save and load state</h3>
          <ul>
              <li class="extended-link"><a id="save-button" class="waves-effect" href="#!" @click="saveState"><label>Save as file</label></a></li>
              <li class="extended-link">
                  <a id="load-file-button" class="waves-effect" href="#!" @click="openFileDialog">
                      <label for="load-file-input">
                          Load file
                          <input type="file" id="load-file-input" style="display:none" ref="fileInputRef" @change="loadStates">
                      </label>
                  </a>
              </li>
          </ul>
      </div>
    </div>
</template>

<script lang="ts">
import { ref, Ref, toRef } from 'vue';
import { useStore, Store } from 'vuex';
import { RootState } from '../store/store';
import { ApplicationActions } from '../store/applicationState';
import Iam from '../iam/javascripts/iam_logic/iam';
import Toast from '../javascripts/toasts/Toast.js';
import { MapActions } from "../store/mapStore/MapActions";
import { convertMapStateToApi } from '../repository/converters/convertFromMapStateToApi';

export default {
    name: 'SaveLoad',
    setup() {
        const store: Store<RootState> = useStore();

        const shouldDisplaySaveLoad: Ref<boolean> = toRef(store.state.applicationState, 'shouldDisplaySaveLoad');
        const fileInputRef: Ref<HTMLElement | null> = ref(null);

        function openFileDialog() {
          fileInputRef.value?.click();
        }
        function loadStates(event: Event) {
          let reader = new FileReader();
          reader.onload = (theFile) => {
              const rawResult: string = <string>theFile.target?.result ?? '';
              const readObject = JSON.parse(decodeURIComponent(rawResult));
              // this._core.loadState(readObject);
              store.dispatch(MapActions.loadMarkersFromFile, readObject);
              new Toast('Context loaded from file');
          };
          const files = (<HTMLInputElement | null>event.target)?.files;
          if (files) {
            reader.readAsText(files[0]);
          }
        }
        function saveState() {
          console.log('saveState');
          const objectToSave = convertMapStateToApi(store.state.mapState);
          if (objectToSave === null) {
            new Toast('Nothing to save');
          } else {
              const element = document.createElement('a');
              element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(objectToSave)));
              element.setAttribute('download', 'save.json');

              element.style.display = 'none';
              document.body.appendChild(element);

              element.click();

              document.body.removeChild(element);
          }
        }

        return {
          fileInputRef,
          loadStates,
          openFileDialog,
          saveState,
          shouldDisplaySaveLoad,
        }
    },
}
</script>

<style lang="postcss" scoped>
$side-nav-length: 250px;

.save-load-menu {
  position: absolute;
  top:0;
  left: 0;
  right: auto;
  z-index: 10000;
  height: 100vh;
  width: $side-nav-length;
  background-color: whitesmoke;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  resize: horizontal;
  transition: all 0.2s ease;
  h3 {
    margin: 10px;
    color: darkgrey;
  }
  ul {
    padding: 0;
    li {
      margin: 0 5px;
      padding: 5px;
      list-style-type: none;
      border-radius: 2px;
      border-bottom: white solid 1px;
      &:last-child {
        border-bottom: none;
      }
      transition: background-color 0.4s ease-out;
      &:hover {
        background-color: #e0e0e0;
      }
      a {
        font-size: 16px;
        color: black;
        text-decoration: none;
      }
    }
  }
}

.svld-content {
  grid-row: 1;
  grid-column: auto;
  display: flex;
  align-items: center;
  p {
    width: 100%;
    text-align: center;
    a {
      color: black;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>