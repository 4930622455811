
import { ref, Ref, toRef } from 'vue';
import { useStore, Store } from 'vuex';
import { RootState } from '../store/store';
import { ApplicationActions } from '../store/applicationState';
import Iam from '../iam/javascripts/iam_logic/iam';
import Toast from '../javascripts/toasts/Toast.js';
import { MapActions } from "../store/mapStore/MapActions";
import { convertMapStateToApi } from '../repository/converters/convertFromMapStateToApi';

export default {
    name: 'SaveLoad',
    setup() {
        const store: Store<RootState> = useStore();

        const shouldDisplaySaveLoad: Ref<boolean> = toRef(store.state.applicationState, 'shouldDisplaySaveLoad');
        const fileInputRef: Ref<HTMLElement | null> = ref(null);

        function openFileDialog() {
          fileInputRef.value?.click();
        }
        function loadStates(event: Event) {
          let reader = new FileReader();
          reader.onload = (theFile) => {
              const rawResult: string = <string>theFile.target?.result ?? '';
              const readObject = JSON.parse(decodeURIComponent(rawResult));
              // this._core.loadState(readObject);
              store.dispatch(MapActions.loadMarkersFromFile, readObject);
              new Toast('Context loaded from file');
          };
          const files = (<HTMLInputElement | null>event.target)?.files;
          if (files) {
            reader.readAsText(files[0]);
          }
        }
        function saveState() {
          console.log('saveState');
          const objectToSave = convertMapStateToApi(store.state.mapState);
          if (objectToSave === null) {
            new Toast('Nothing to save');
          } else {
              const element = document.createElement('a');
              element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(objectToSave)));
              element.setAttribute('download', 'save.json');

              element.style.display = 'none';
              document.body.appendChild(element);

              element.click();

              document.body.removeChild(element);
          }
        }

        return {
          fileInputRef,
          loadStates,
          openFileDialog,
          saveState,
          shouldDisplaySaveLoad,
        }
    },
}
