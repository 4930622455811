import { MapState } from '../store/mapStore/mapState';
import { convertFromApiToMapState } from './converters/convertFromApiToMapState';
import { EventEnum } from './EventEnum';
import { v4 as uuid } from 'uuid';
import * as showdown from 'showdown';
import { MarkerEventType } from '../store/domain/markersEvents/MarkerEvent';
import { EventType, GenericEvent } from '../store/domain/genericEvents/genericMapStoreFunctions';
import { applyDiff } from '../store/domain/genericEvents/applyDiff';

export function migrateStates(state: any) {
    if (state instanceof Array) {
        const arrayStates = state.map(state => _migrateMapState(state));
        state = {
            version : 8,
            states  : arrayStates,
        };
    }
    switch (state.version) {
        case 8:
            state.states.forEach((state: any) => {
                state.date = state.date.split('-').reverse().join('-');
            });
            const orderedDates = state.states.map((state: any) => state.date);
            orderedDates.sort((a: any, b: any) => a.localeCompare(b));
            const currentDate = state.states.filter((state: any) => state.isCurrent)[ 0 ].date;
            const markersStates: any = {};
            let id = 1;
            state.states.forEach((state: any) => {
                const dateIndex = orderedDates.indexOf(state.date);
                state.markersList.forEach((marker: any) => {
                    const markerKey = marker.label;
                    if (!(markerKey in markersStates)) {
                        markersStates[ markerKey ] = {
                            id,
                            markerKey,
                            states : [],
                        };
                        id += 1;
                    }
                    markersStates[ markerKey ].states[ dateIndex ] = marker;
                });
            });
            const history: any = [];
            Object.values(markersStates)
                .forEach((markerStates: any) => {
                    for (let index = 0; index < orderedDates.length; index += 1) {
                        if (markerStates.states[ index ] && index === 0) {
                            history.push({
                                date      : orderedDates[ index ],
                                eventType : EventEnum.ADD_MARKER_EVENT_TO_CORE,
                                data      : {
                                    markerId : markerStates.id,
                                    ...markerStates.states[ index ],
                                },
                            });
                        } else if (markerStates.states[ index ] && !markerStates.states[ index - 1 ]) {
                            markerStates.id = id;
                            id += 1;
                            history.push({
                                date      : orderedDates[ index ],
                                eventType : EventEnum.ADD_MARKER_EVENT_TO_CORE,
                                data      : {
                                    markerId : markerStates.id,
                                    ...markerStates.states[ index ],
                                },
                            });
                        } else if (!markerStates.states[ index ] && markerStates.states[ index - 1 ]) {
                            let lastKnownLabel = '';
                            markerStates.states.forEach((state: any) => {
                                if (state && state.label) {
                                    lastKnownLabel = state.label;
                                }
                            });
                            history.push({
                                date      : orderedDates[ index ],
                                eventType : EventEnum.DELETE_MARKER_EVENT_TO_CORE,
                                data      : {
                                    markerId : markerStates.id,
                                    label    : lastKnownLabel,
                                },
                            });
                        } else if (markerStates.states[ index ]
                                && markerStates.states[ index - 1 ]
                                && (markerStates.states[ index ].lat !== markerStates.states[ index - 1 ].lat
                                || markerStates.states[ index ].lng !== markerStates.states[ index - 1 ].lng)) {
                            history.push({
                                date      : orderedDates[ index ],
                                eventType : EventEnum.MARKER_LOCATION_CHANGED_EVENT_TO_CORE,
                                data      : {
                                    markerId : markerStates.id,
                                    lat      : markerStates.states[ index ].lat,
                                    lng      : markerStates.states[ index ].lng,
                                },
                            });
                        } else if (markerStates.states[ index ]
                                && markerStates.states[ index - 1 ]
                                && markerStates.states[ index ].type !== markerStates.states[ index - 1 ].type) {
                            history.push({
                                date      : orderedDates[ index ],
                                eventType : EventEnum.MARKER_TYPE_CHANGED_EVENT_TO_CORE,
                                data      : {
                                    markerId : markerStates.id,
                                    type     : markerStates.states[ index ].type,
                                },
                            });
                        } else if (markerStates.states[ index ]
                                && markerStates.states[ index - 1 ]
                                && markerStates.states[ index ].text !== markerStates.states[ index - 1 ].text) {
                            history.push({
                                date      : orderedDates[ index ],
                                eventType : EventEnum.MARKER_TEXT_CHANGED_EVENT_TO_CORE,
                                data      : {
                                    markerId : markerStates.id,
                                    text     : markerStates.states[ index ].text,
                                },
                            });
                        } else if (markerStates.states[ index ]
                                && markerStates.states[ index - 1 ]
                                && markerStates.states[ index ].isPublic !== markerStates.states[ index - 1 ].isPublic) {
                            history.push({
                                date      : orderedDates[ index ],
                                eventType : EventEnum.MARKER_VISIBILITY_CHANGED_EVENT_TO_CORE,
                                data      : {
                                    markerId : markerStates.id,
                                    isPublic : markerStates.states[ index ].isPublic,
                                },
                            });
                        }
                    }
                });
            history.sort((a: any, b: any) => a.date.localeCompare(b.date));
            state = {
                version            : 9,
                history,
                currentPlayersDate : currentDate,
                orderedDates,
            };
        case 9: // eslint-disable-line no-fallthrough
            const events = state.history;
            const max = events.sort((a: any, b: any) => a.data.markerId < b.data.markerId)[ 0 ].data.markerId;
            const markerWithIdAtZero = events.filter((event: any) => event.data.markerId === 0);
            if (markerWithIdAtZero.length) {
                markerWithIdAtZero[ 0 ].data.markerId = max + 1;
                state.history = state.history.sort((a: any, b: any) => a.date.localeCompare(b.date));
            }
            state.version = 10;
        case 10: // eslint-disable-line no-fallthrough
            state.history.map((event: any) => {
                event.data.visited = false;
                event.eventType = event.eventType
                    .replace('Changer', 'Changed')
                    .replace(/([A-Z])/g, '_$1')
                    .toUpperCase();
                event.eventType = event.eventType.endsWith('_EVENT_TO_CORE') ? event.eventType : event.eventType + '_EVENT_TO_CORE';
            });
            state.version = 11;
        case 11: // eslint-disable-line no-fallthrough
            const randomMapId: string = uuid();
            state.maps = {
                [randomMapId]: {
                    id: randomMapId,
                    name: 'Régions de Barsaive',
                    url: 'https://storage.gra.cloud.ovh.net/v1/AUTH_ccda20965c9c468baed8885dfa6bab7e/personal-map/49f8a3b9-2ade-4374-a7e3-9d87fc8e282f',
                    history: state.history,
                }
            };
            delete state.history;
            state.currentPlayersMap = randomMapId;
            state.version = 12;
        case 12: // eslint-disable-line no-fallthrough
            Object.values(state.maps).map((map: any) => {
                map.width = 2000;
                map.height = 1414;
                map.url = 'https://storage.gra.cloud.ovh.net/v1/AUTH_ccda20965c9c468baed8885dfa6bab7e/personal-map-osu/a72e26a1-0135-4b67-abed-abeadd4af57e.jpg';
            })
            state.version = 13;
        case 13: // eslint-disable-line no-fallthrough
            Object.values(state.maps)
                .map((map: any) => map.history
                    .filter((event: any) => event.eventType === MarkerEventType.ADD_MARKER || event.eventType === MarkerEventType.CHANGE_MARKER_TEXT)
                    .map((event: any) => {
                        event.data.text = new showdown.Converter().makeHtml(event.data.text);
                    })
                );
            state.version = 14;
        case 14: // eslint-disable-line no-fallthrough
            Object.values(state.maps)
                .map((map: any) => {
                    map.isVisible = true;
                });
                state.version = 15;
        case 15: // eslint-disable-line no-fallthrough
            Object.values(state.maps)
                .map((map: any) => map.history
                    .filter((event: any) => event.eventType === MarkerEventType.ADD_MARKER)
                    .map((event: any) => {
                        event.data.orientation = 0;
                    })
                );
            state.version = 16;
        case 16: // eslint-disable-line no-fallthrough
            Object.values(state.maps)
                .map((map: any) => {
                    map.markerCategories = [];
                });
            state.version = 17;
        case 17: // eslint-disable-line no-fallthrough
            Object.values(state.maps)
                .map((map: any) => map.history
                    .filter((event: any) => event.eventType === MarkerEventType.ADD_MARKER)
                    .map((event: any) => {
                        event.data.youAreHere = false;
                    })
                );
            state.version = 18;
        case 18: // eslint-disable-line no-fallthrough
            Object.values(state.maps)
                .map((map: any) => {
                    map.rumorsHistory = [];
                });
            state.version = 19;
        case 19: // eslint-disable-line no-fallthrough
            Object.values(state.maps)
                .map((map: any) => {
                    map.history
                        .forEach((event: any) => {
                            event.data.id = event.data.markerId;
                            if (event.data.isPublic !== undefined) {
                                event.data.isVisible = event.data.isPublic;
                                delete event.data['isPublic'];
                            }
                            delete event.data['markerId'];
                            if (event.eventType === MarkerEventType.ADD_MARKER) {
                                event.eventType = EventType.ADD_EVENT;
                            } else if(event.eventType === MarkerEventType.DELETE_MARKER) {
                                event.eventType = EventType.DELETE_EVENT;
                            } else {
                                event.eventType = EventType.CHANGE_EVENT;
                            }
                        });
                    const markersIdsToClean: number[] = [];
                    map.history = state.orderedDates.map((date: string) => {
                        const events = map.history.filter((event: any) => event.date === date);
                        if (events.length === 0) return [];
                        const eventIds = new Set<number>(events.map((event: GenericEvent) => event.data.id)).values();
                        const newLocal = Array.from(
                            eventIds
                        ).map((id: number) => {
                            const markerEvents = events
                                .filter((event: any) => event.data.id === id);
                            if (markerEvents.length <= 1) {
                                return markerEvents;
                            } else {
                                if (markerEvents.filter((event: any) => event.eventType === 'ADD_EVENT' || event.eventType === 'DELETE_EVENT').length >= 2){
                                    markersIdsToClean.push(id);
                                }
                                const reducer = (accumulator: any, currentValue: any) => {
                                    applyDiff(accumulator, currentValue);
                                    return accumulator;
                                };
                                return markerEvents.reduce(reducer, {});
                            }
                        }).flat();
                        return newLocal;
                    }).flat()
                    .sort((eventA: any, eventB: any) => eventA.date.localeCompare(eventB.date));
                    map.history = map.history.filter((event: any) => markersIdsToClean.indexOf(event.data.id) === -1);

                    map.history = map.history.filter((event: any) => {
                        if (event.eventType === 'DELETE_EVENT') {
                            return map.history.filter((innerEvent: any) => event.data.id === innerEvent.data.id).length > 1;
                        } else {
                            return true;
                        }
                    });

                    map.history.filter((event: any) => event.data.id === 90).forEach((event: any) => console.log(event));
                });
            state.version = 20;
        case 20: // eslint-disable-line no-fallthrough
            Object.values(state.maps)
                .map((map: any) => {
                    map.diplomaticRelationshipHistory = [];
                });
            state.version = 21;
        case 21: // eslint-disable-line no-fallthrough
            break;
        default:
            throw new Error('bad format');
    }
    return state;
}

function _migrateMapState(objectToExtract: any) {
    switch (objectToExtract[ 'version' ]) {
    case '1.0':
        objectToExtract[ 'markersList' ].forEach((markerObject: any) => {
            switch (markerObject[ 'type' ]) {
            case 'default':
                markerObject[ 'type' ] = 'lieux';
                break;
            case 'kaer':
                markerObject[ 'type' ] = 'cités';
                break;
            case 'ruin':
                markerObject[ 'type' ] = 'ruines';
                break;
            default:
                markerObject[ 'type' ] = 'lieux';
                break;
            }
        });
    case 2: // eslint-disable-line no-fallthrough
    case 3: // eslint-disable-line no-fallthrough
        objectToExtract[ 'markersList' ] = objectToExtract[ 'markersList' ]
            .map((markerObject: any) => {
                markerObject[ 'isPublic' ] = true;
                return markerObject;
            });
    case 4: // eslint-disable-line no-fallthrough
        objectToExtract.date = '23-06-1503';
        objectToExtract.version = 5;
    case 5: // eslint-disable-line no-fallthrough
        objectToExtract.isCurrent = objectToExtract.date === '23-06-1503';
    case 6: // eslint-disable-line no-fallthrough
        objectToExtract.isCurrent = objectToExtract.date === '30-06-1508';
    case 7: // eslint-disable-line no-fallthrough
        break;
    default:
        alert('incompatible map format, your version is not supported');
        break;
    }
    return objectToExtract;
}

export function migrateState(fileObject: any): MapState {
    const state = migrateStates(fileObject);
    const mapState = convertFromApiToMapState(state);
    return mapState;
}