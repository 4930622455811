import L from 'leaflet';
import { watch, createApp, nextTick, watchEffect } from 'vue';
import DiplomaticRelationshipPopup from './DiplomaticRelationshipPopup.vue';
import { DiplomaticRelationshipGetters, DiplomaticRelationshipNature } from '../../store/domain/diplomaticRelationship/diplomaticRelationshipEvents';
import { MarkerGetters } from '../../store/domain/markers/MarkerEvents';
import { Roles } from '../../iam/javascripts/Roles';

export default class LeafletDiplomaticRelationshipContainer {
    get id() {
        return this._id;
    }

    get leafletPolyline() {
        return this._leafletPolyline;
    }

    constructor(diplomaticRelationship, map, store) {
        this._id = diplomaticRelationship.id;
        this._store = store;
        this._map = map;
        const startMarker = store.getters[ MarkerGetters.GET_A_MARKER ](diplomaticRelationship.markerIds[ 0 ]);
        const endMarker = store.getters[ MarkerGetters.GET_A_MARKER ](diplomaticRelationship.markerIds[ 1 ]);
        this._leafletPolyline = new L.polyline([ // eslint-disable-line new-cap
            [ startMarker.lat, startMarker.lng ],
            [ endMarker.lat, endMarker.lng ],
        ], {
            color       : this.currentRelationshipColor(diplomaticRelationship.nature),
            riseOnHover : false,
            weight      : store.state.applicationState.diplomaticRelationshipEnabled ? 3 : 0,
        }).addTo(map);
        this._colorWatchStop = watch(store.state.mapState.maps[ store.state.mapState.currentMap ].diplomaticRelationshipHistory, () => {
            const diplomaticRelationshipUpdate = store.getters[ DiplomaticRelationshipGetters.GET_A_DIPLOMATIC_RELATIONSHIP ](diplomaticRelationship.id);
            this._leafletPolyline.setStyle({
                color : this.currentRelationshipColor(diplomaticRelationshipUpdate.nature),
            });
        }, { deep: true });
        this._latlngWatchStop = watchEffect(() => {
            this._leafletPolyline.setLatLngs([
                [ startMarker.lat, startMarker.lng ],
                [ endMarker.lat, endMarker.lng ],
            ]);
        });
        this._weightWatchStop = watchEffect(() => {
            if (store.state.applicationState.diplomaticRelationshipEnabled) {
                if (!store.state.applicationState.displayDiplomaticRelationshipLinesFromThisMarkerId
                    || diplomaticRelationship.markerIds[ 0 ] === store.state.applicationState.displayDiplomaticRelationshipLinesFromThisMarkerId
                    || diplomaticRelationship.markerIds[ 1 ] === store.state.applicationState.displayDiplomaticRelationshipLinesFromThisMarkerId) {
                    this._leafletPolyline.setStyle({
                        weight : 3,
                    });
                } else {
                    this._leafletPolyline.setStyle({
                        weight : 0,
                    });
                }
            } else {
                this._leafletPolyline.setStyle({
                    weight : 0,
                });
            }
        });
        this._leafletPolyline.getElement().classList.add('diplomaticRelationship-circle');

        if (store.state.applicationState.role === Roles.GAME_MASTER) {
            this._leafletPolyline
                .bindPopup(`<div style="width: 150px;" id="relationship-line-${this._id}"></div>`)
                .on('popupopen', () => {
                    nextTick(() => {
                        createApp(DiplomaticRelationshipPopup, {
                            id : this._id,
                        })
                            .use(this._store)
                            .mount('#relationship-line-' + this._id);
                    });
                });
        }
        this._dragging = false;

        this._isMac = window.navigator.userAgent.indexOf('Mac') !== -1;
    }

    currentRelationshipColor(relationshipNature) {
        switch (relationshipNature) {
        case DiplomaticRelationshipNature.NEUTRAL:
            return '#ffffffaa';
        case DiplomaticRelationshipNature.ENEMY:
            return '#ff1111dd';
        case DiplomaticRelationshipNature.ALLY:
            return '#00ff0088';
        default:
            return '#0000ffff';
        }
    }

    endpointsDoesNotExistAnymore(startMarker, endMarker) {
        return (!startMarker || !endMarker) || (startMarker.lat === undefined || endMarker.lat === undefined);
    }

    stopAll() {
        if (this._weightWatchStop) this._weightWatchStop();
        if (this._latlngWatchStop) this._latlngWatchStop();
        if (this._colorWatchStop) this._colorWatchStop();
    }

    remove() {
        this.stopAll();
        this._leafletPolyline.remove();
    }
}
