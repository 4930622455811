
import { computed, ComputedRef, nextTick, onMounted, ref, Ref, toRef, watch, watchEffect } from 'vue';
import { useStore, Store } from 'vuex';
import * as showdown from 'showdown';
import { RootState } from '../store/store';
import { ApplicationActions } from '../store/applicationState';
import { MapActions } from "../store/mapStore/MapActions";
import { MapGetters } from "../store/mapStore/MapGetters";
import { Map } from "../store/domain/Map";
import { Marker } from "../store/domain/Marker";
import { MapState } from '../store/mapStore/mapState';
import { MarkerType } from '../store/domain/MarkerType';
import { MarkerCategory } from '../store/domain/MarkerCategory';
import { Roles } from '../iam/javascripts/Roles';
import { MarkerData, MarkerGetters } from '../store/domain/markers/MarkerEvents';
import Fuse from 'fuse.js';
import EditableSelect from './graphics/EditableSelect.vue';

export default {
  components: { EditableSelect },
    name: 'ExplorationMenu',
    setup() {
        const store: Store<RootState> = useStore();
        const markerFilterQuery = ref('');
        const markerTypeCategoriesToExpand: Ref<any> = ref({});

        type MarkerTypeContainer = { markerType: MarkerType, filterOutOnMap: boolean, markers: Marker[] };

        const markersInCategories = computed(() => {
          const markers = store.getters[MarkerGetters.GET_ALL_MARKERS];
          return store.getters[MapGetters.getMarkerCategories]
            .map((markerCategory: MarkerCategory) => {
              return {
                id: markerCategory.id,
                name: markerCategory.name,
                filterOutOnMap: store.state.applicationState.markerTypesToDim.indexOf(markerCategory.id) > -1,
                markers: markers
                  .filter((marker: Marker) => marker.type === markerCategory.id)
                  .sort((markerA: Marker, markerB: Marker) => markerA.label.localeCompare(markerB.label)),
              }
            })
            .filter((markerCategory: Record<string, any>) => markerCategory.markers.length > 0)
            .sort((markerCategoryA: MarkerCategory, markerCategoryB: MarkerCategory) => {
              markerCategoryA.name.localeCompare(markerCategoryB.name);
            });
        });
        const shouldDisplayExplorationMenu: Ref<boolean> = toRef(store.state.applicationState, 'shouldDisplayExplorationMenu');
        
        watch(markersInCategories, () => {
          markersInCategories.value.forEach((markersInCategory: any) => {
            markerTypeCategoriesToExpand.value[markersInCategory.id] = {
              expand: false,
              hide: false,
            }
          })
        });

        function inputOnGoing(name: string) {
          markerFilterQuery.value = name;
        }
        function selectedSearchResult({ name } : { name: string }) {
          markerFilterQuery.value = name;
        }
        const fuzzyResults = computed(() => {
          if (markerFilterQuery.value.length) {
            const markers = store.getters[MarkerGetters.GET_ALL_MARKERS];
            const options = {
                includeScore: true,
                isCaseSensitive: false,
                shouldSort: true,
                threshold: 0.6,
                useExtendedSearch: true,
                keys: ['label'],
            }
            const fuse = new Fuse(markers, options);
            const fuzzyLabelMatches: any[] = fuse.search(markerFilterQuery.value)
              .filter((result: any): any => {
                return result.score <= 0.5;
              })
              .map((result: any): any => {
                return {
                  id: result.item.id,
                  name: result.item.label,
                }
              });
            return fuzzyLabelMatches;
          } else {
            return [];
          }
        });

        function toggleMarkerTypeFilter(markerType: MarkerType) {
            store.dispatch(ApplicationActions.toggleMarkerTypeToDimOnMap, markerType);
        }
        function toggleCategoryDisplay(markerType: MarkerType) {
            markerTypeCategoriesToExpand.value[markerType].expand = !markerTypeCategoriesToExpand.value[markerType].expand;
        }
        watch(markerFilterQuery, () => {
          Object.keys(<any>markerTypeCategoriesToExpand.value).forEach((key: any) => {
            if (markerFilterQuery.value.length === 0) {
              markerTypeCategoriesToExpand.value[key].expand = false;
              markerTypeCategoriesToExpand.value[key].hide = false;
            } else {
              markersInCategories.value.forEach((markersInCategory: any) => {
                const shouldShow = markersInCategory.markers
                  .filter((marker: MarkerData) => {
                    return marker.label.toLocaleLowerCase().indexOf(markerFilterQuery.value.toLocaleLowerCase()) !== -1 
                    || marker.text.toLocaleLowerCase().indexOf(markerFilterQuery.value.toLocaleLowerCase()) !== -1;
                  }).length > 0;
                markerTypeCategoriesToExpand.value[markersInCategory.id].expand = shouldShow;
                markerTypeCategoriesToExpand.value[markersInCategory.id].hide = !shouldShow;
              });
            }
          });
        });
        watch(markerFilterQuery, () => {
          store.dispatch(ApplicationActions.updateMarkerToFilterOutFromSearch, markerFilterQuery.value);
        });
        function zoomOnMarker(id: number) {
          store.dispatch(MapActions.zoomOnMarker, id);
        }

        return {
          fuzzyResults,
          inputOnGoing,
          shouldDisplayExplorationMenu,
          markerFilterQuery,
          markersInCategories,
          markerTypeCategoriesToExpand,
          selectedSearchResult,
          toggleCategoryDisplay,
          toggleMarkerTypeFilter,
          zoomOnMarker,
        }
    },
}
