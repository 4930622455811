import L from 'leaflet';
import { ApplicationActions } from '../../../store/applicationState';
import editableSwitchIcon from '../../../statics/images/rumors.svg';
import { toRef, watch } from 'vue';

export default class RumorsToggleControl {
    constructor(store) {
        const rumorsEditionEnabled = toRef(store.state.applicationState, 'rumorsEditionEnabled');
        const editionModeEnabled = toRef(store.state.applicationState, 'editionModeEnabled');
        watch(editionModeEnabled, () => {
            if (editionModeEnabled.value) {
                document.querySelector('.rumors-toggle-map-button-in-toolbar').style.display = 'inherit';
            } else {
                document.querySelector('.rumors-toggle-map-button-in-toolbar').style.display = 'none';
            }
        });
        watch(rumorsEditionEnabled, () => {
            if (rumorsEditionEnabled.value) {
                document.querySelector('.map-control.rumors-toggle-map-button').parentElement.style.backgroundColor = 'red';
            } else {
                document.querySelector('.map-control.rumors-toggle-map-button').parentElement.style.backgroundColor = 'white';
            }
        });
        const LeafletControlConstructor = L.Control.extend({
            options : {
                position : 'topleft',
            },

            onAdd : () => {
                let container = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom rumors-toggle-map-button-in-toolbar');
                container.type = 'div';
                container.innerHTML = `<a>
                <img class="map-control rumors-toggle-map-button" src="${editableSwitchIcon}" alt=""/>
                <span class="tooltip-container">
                <span class="tooltip">Rumeurs</span>
                </span>
                </a>`;
                container.style.color = 'black';
                container.style.backgroundColor = 'white';
                container.style.width = '30px';
                container.style.height = '30px';
                container.style.display = 'none';

                container.addEventListener('pointerup', (event) => {
                    event.stopPropagation();
                    store.dispatch(ApplicationActions.toggleRumorsEdition);
                });

                return container;
            },
        });
        this._control = new LeafletControlConstructor();
    }

    getControl() {
        return this._control;
    }
}
