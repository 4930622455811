<template>
    <div class="event-menu" v-if="shouldDisplayEventMenu" @click.stop>
        <h4>Sélection de la date</h4>
        <div class="date">
            <span class="dropdown-label">Date d'édition :</span>
            <EditableSelect
                :items="availableDates"
                :currentItem="currentDate"
                :validationMethod="dateValidationMethod"
                :editionDisabled="!editionModeActivated"
                @newItem="item => inputNewDate(item.name)"
                @selectedItem="item => selectDate(item.id)"
            />
            <div class="default-container">
                <div>
                    <input
                        type="checkbox"
                        :disabled="!editionModeActivated"
                        :checked="isPlayerDate(currentDate.id)"
                        @change="selectAsPlayersDate()"
                        data-test="is-player-date"
                    />
                </div>
                <div>
                    <label for="default-date-checkbox">
                        Date des joueurs
                    </label>
                </div>
            </div>
        </div>
        <h4>Évènements du <span id="date">{{ currentDate.id }}</span></h4>
        <div class="warn">Un grand pouvoir implique de grandes responsabilités.</div>
        <div class="event-list">
            <div v-for="markerEvent in markerEvents" :key="markerEvent.id + markerEvent.eventType" class="element" data-test="marker-event">
                <div class="text" @click='zoomOnMarker(markerEvent.id, markerEvent.eventType)'>
                    <div data-test="event-label">{{ markerEvent.label }}</div>
                    <div data-test="event-type">{{ markerEvent.eventType }}</div>
                </div>
                <div v-if="editionModeActivated" class="icon" data-test="event-delete" @click="deleteMarkerEvent(markerEvent.id, markerEvent.eventType)">
                    <img :src="require('../statics/images/recycle.png')"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, onMounted, ref, Ref, toRef } from 'vue';
import { useStore, Store, mapActions } from 'vuex';
import { RootState } from '../store/store';
import { MapActions } from "../store/mapStore/MapActions";
import { MapGetters } from "../store/mapStore/MapGetters";
import { Marker } from "../store/domain/Marker";
import { MapState } from '../store/mapStore/mapState';
import { MarkerEvent, MarkerEventType } from '../store/domain/markersEvents/MarkerEvent';
import Toast from '../javascripts/toasts/Toast';
import EditableSelect from './graphics/EditableSelect.vue';
import { MarkerActions, MarkerGetters } from '../store/domain/markers/MarkerEvents';
import { EventType, GenericEvent } from '../store/domain/genericEvents/genericMapStoreFunctions';

export default {
  components: { EditableSelect },
    name: 'EventMenu',
    setup() {
        const store: Store<RootState> = useStore();

        const availableDates = computed(() => {
            return store.getters[MapGetters.getAvailableDates].map((date: string) => {
                if (date === store.state.mapState.currentPlayersDate) {
                    return { name: date + ' - date joueurs', id: date };
                } else {
                    return { name: date, id: date };
                }
            });
        });
        const markerEvents: Ref<GenericEvent[]> = toRef(store.getters, MarkerGetters.GET_EVENTS_OF_MARKERS);
        const editionModeActivated = toRef(store.state.applicationState, 'editionModeEnabled');
        
        const currentDate = computed(() => {
            return { name: store.state.mapState.currentDate, id: store.state.mapState.currentDate };
        });
        const shouldDisplayEventMenu: Ref<boolean> = toRef(store.state.applicationState, 'shouldDisplayEventMenu');
        
        let shouldDisplayDropdown: Ref<boolean> = ref(false);
        let preselectedDate: Ref<string> = ref('');

        function isPlayerDate(date: string) {
            return date === store.state.mapState.currentPlayersDate;
        }
        function deleteMarkerEvent(id: number, eventType: MarkerEventType) {
            store.dispatch(MarkerActions.EVENT_DELETE_MARKER, { id });
        }
        function selectAsPlayersDate() {
            store.dispatch(MapActions.selectAsPlayersDate);
        }
        function zoomOnMarker(id: number, eventType: EventType) {
            if (eventType !== EventType.DELETE_EVENT) {
                store.dispatch(MapActions.zoomOnMarker, id);
            }
        }
        function selectDate(newEditionDate: string) {
            store.dispatch(MapActions.selectEditionDate, newEditionDate);
        }
        function inputNewDate(newEditionDate: string) {
            store.dispatch(MapActions.selectEditionDate, newEditionDate);
        }
        function dateValidationMethod(dateToParse: string) {
            return dateToParse.match(/^\d{4}-\d{2}-\d{2}$/);
        }

        return {
            availableDates,
            currentDate,
            deleteMarkerEvent,
            editionModeActivated,
            inputNewDate,
            isPlayerDate,
            markerEvents,
            selectAsPlayersDate,
            selectDate,
            shouldDisplayDropdown,
            shouldDisplayEventMenu,
            dateValidationMethod,
            zoomOnMarker,
        };
    },
}
</script>

<style lang="postcss" scoped>

$side-nav-length: 250px;

.event-menu {
    position: absolute;
    top:0;
    left: 0;
    right: auto;
    z-index: 10000;
    height: 100vh;
    width: $side-nav-length;
    background-color: whitesmoke;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    resize: horizontal;
    transition: all 0.2s ease;
    
    h4 {
        margin: 10px;
        color: gray;
        &:nth-of-type(2) {
            margin-top: 25px;
        }
    }
    .warn {
        color: #656565;
        font-size: 8px;
        padding: 0 5px;
    }
    .event-list {
        display: flex;
        flex-direction: column;

        .element {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 15px 5px;

            transition: background-color 0.3s ease;

            cursor: pointer;

            &:hover {
                background-color: rgba(255, 59, 59, 0.33);
            }

            .text {
                display: flex;
                flex-direction: column;
                width: 100%;
                font-size: 11px;
                color: brown;
                overflow-wrap: anywhere;
            }

            .icon img {
                width: 20px;
                height: 20px;
            }
        }
    }
}

.date {
  padding: 5px;

    .dropdown-label {
        display: inline-block;
        width: 100%;
    }

    .default-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 5px;

      label {
        width: 100%;
        white-space: nowrap;
      }
    }

    .dates-dropdown-trigger {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        border-radius: 2px;
        padding: 5px;
        
        .dates-input {
            background-color: white;
            display: inline;
            border: none;
            text-align: right;
            margin: 0;
            padding: 0;
            width: 100%;
            font-size: 12px;
        }
    }
}

.dates-dropdown {
    background-color: white;
    display: block;
    position: absolute;
    text-align: right;
    margin: 0 -10px;
    overflow: hidden;
    padding: 0 5px;
    border-radius: 2px;
    width: 100%;

    li {
        list-style-type: none;
        font-size: 12px;
        margin: 0;
        padding: 5px 0;
        border-radius: 2px;
        transition: background-color 0.4s ease-out;
        border-bottom: #e0e0e0 solid 1px;

        a {
            color: black;
            text-decoration: none;
        }

        &:hover {
            background-color: #e0e0e0;
        }
        &:last-child {
            border-bottom: none;
        }
    }
}

</style>