import L, { Marker } from 'leaflet';
import Cursor from './Cursor.vue';
import { CursorUser } from './cursorsWebsocket';
import { watch, nextTick, createApp, Ref } from 'vue';
import Map from './Map';
import { v4 as uuid } from 'uuid';

export default class LeafletCursorContainer {
    _leafletCursor: Marker;
    iid: string;

    constructor(user: Ref<CursorUser>, map: Map) {
        this.iid = uuid();
        const template = document.createElement('div');
        template.setAttribute('id', 'map-cursor-' + user.value.id);
        nextTick(() => {
            createApp(Cursor, {
                color : user.value.color,
            })
                .mount('#map-cursor-' + user.value.id);
        });
        const leafletIcon = L.divIcon({
            html     : template,
            iconSize : [0, 0],
        });
        this._leafletCursor = L.marker([ user.value.lat, user.value.lng ], {
            icon: leafletIcon,
        }).addTo(<L.Map>map._leafletMap.value); // eslint-disable-line new-cap

        watch(user, () => {
            // console.log('update cursor', user.value);
            this._leafletCursor.setLatLng([ user.value.lat, user.value.lng ]);
        }, { deep: true });
    }

    delete() {
        this._leafletCursor.remove();
    }
}
