import { GenericData, GenericDiffType } from "./genericMapStoreFunctions";

export function applyDiff(source: GenericData | GenericDiffType, modification: GenericData | GenericDiffType) {
    Object.entries(modification)
        .map(([key, value]) => {
            if (key === 'patch_prune_array') {
                Object.entries((<any>modification)[key])
                    .map(([arrayKeyToPrune, idsToDelete]) => {
                        const obj: any = source;
                        (<Array<any>>obj[arrayKeyToPrune]) = (<Array<any>>obj[arrayKeyToPrune])
                            .filter((element) => (<Array<number>>idsToDelete).indexOf(element.id) === -1)
                    })
            } else if (key === 'patch_prune_object') {
                Object.entries((<any>modification)[key])
                    .map(([objectKeyToPrune, idsToDelete]) => {
                        const obj: any = source;
                        (<Array<number>>idsToDelete).forEach(idToDelete => {
                            delete (<Array<any>>obj[objectKeyToPrune])[idToDelete]    
                        });
                    })
            } else if (Array.isArray(value)) {
                if ((<any>source)[key] === undefined) {
                    (<any>source)[key] = [];
                }
                if ((<Array<any>>value).length === 0) {
                    (<any>source)[key] = [];
                } else if (isBasicType((<Array<any>>value)[0])) {
                    (<any>source)[key] = value;
                } else {
                    (<Array<any>>value)
                    .map((subModification: any) => {
                        const subSource = (<any>source)[key]
                            .filter((subSource: any) => {
                                return subSource.id === subModification.id;
                            })[0];
                        if (subSource) {
                            applyDiff(subSource, subModification);
                        } else {
                            (<any>source)[key].push(subModification);
                        }
                    });
                }
            } else if (value instanceof Object && Object.keys(source).indexOf(key) !== -1) {
                applyDiff((<any>source)[key], value);
            } else if ((<any>source)[key] != value) {
                (<any>source)[key] = value;
            }
        });
}

function isBasicType(element: any): boolean {
    return typeof element === 'string'
        || typeof element === 'number'
        || typeof element === 'boolean';
}
