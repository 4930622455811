import { Ref, watch } from "vue";
import { CursorUser } from "./cursorsWebsocket";
import LeafletCursorContainer from "./LeafletCursorContainer";
import Map from "./Map";

type MapCursor = {
    id            : string,
    user          : Ref<CursorUser>,
    leafletCursor : undefined | LeafletCursorContainer,
}

export function displayCursors(users: Ref<Ref<CursorUser>[]>, map: Map) {
    if (map._undisplayCursors) {
        map._undisplayCursors();
    }
    const unwatch = watch(users, () => {
        // look for deleted users
        const deletedMapCursors: MapCursor[] = map._leafletMapCursors.filter((mapCursor) => {
            const result = users.value.find((user) => user.value.id === mapCursor.id);
            return !result;
        });
        deletedMapCursors.forEach(mapCursorToDelete => {
            mapCursorToDelete.leafletCursor?.delete();
            const index = map._leafletMapCursors.indexOf(mapCursorToDelete);
            map._leafletMapCursors.splice(index, 1);
        });
        // look for new users
        const addedUsers: Ref<CursorUser>[] = users.value.filter((distantUser) => {
            const result = map._leafletMapCursors.find((mapCursor) => mapCursor.id === distantUser.value.id);
            return !result;
        });
        addedUsers.forEach((addedUser: Ref<CursorUser>) => {
            const mapCursor: MapCursor = {
                id            : addedUser.value.id,
                user          : addedUser,
                leafletCursor : undefined,
            };
            const leafletCursor = new LeafletCursorContainer(mapCursor.user, map);
            mapCursor.leafletCursor = leafletCursor;
            map._leafletMapCursors.push(mapCursor);
        });
    }, { deep: true });
    map._undisplayCursors = () => {
        unwatch();
        map._leafletMapCursors.forEach(element => {
            element.leafletCursor.delete();
        });
        map._leafletMapCursors = [];
    };
}