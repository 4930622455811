import { Module } from 'vuex';
import { Roles } from '../iam/javascripts/Roles';
import { MarkerType } from './domain/MarkerType';
import { RootState } from './store';
import { MarkerData, MarkerGetters } from './domain/markers/MarkerEvents';

export enum ApplicationActions {
    displayEventMenu = 'displayEventMenu',
    displayMarkerEditionMenu = 'displayMarkerEditionMenu',
    displayExplorationMenu = 'displayExplorationMenu',
    displayAccount = 'displayAccount',
    displayRumorEditionMenu = 'displayRumorEditionMenu',
    displaySaveLoad = 'displaySaveLoad',
    displaySelectMap = 'displaySelectMap',
    hideAllMenu = 'hideAllMenu',
    toggleEditionMode = 'toggleEditionMode',
    toggleShowVisited = 'toggleShowVisited',
    zoomOnPlayerMarker = 'zoomOnPlayerMarker',
    toggleMarkerTypeToDimOnMap = 'toggleMarkerTypeToDimOnMap',
    updateMarkerToFilterOutFromSearch = 'updateMarkerToFilterOutFromSearch',
    setRole = 'setRole',
    setMapZoomFactor = 'setMapZoomFactor',
    disableDragging = "disableDragging",
    enableDragging = "enableDragging",
    toggleRumorsEdition = "toggleRumorsEdition",
    toggleDiplomaticRelationship = "toggleDiplomaticRelationship",
    setDiplomaticRelationshipFirstMarkerId = "setDiplomaticRelationshipFirstMarkerId",
    displayDiplomaticRelationshipLinesFromThisMarkerId = "displayDiplomaticRelationshipLinesFromThisMarkerId",
    markRouteChangeAsProgramatic = "markRouteChangeAsProgramatic",
    unmarkRouteChangeAsProgramatic = "unmarkRouteChangeAsProgramatic",
    toggleDistance = "toggleDistance",
    appendLatLng = "appendLatLng",
    cleanDistance = "cleanDistance",
}

enum ApplicationCommits {
    displayEventMenu = 'displayEventMenu',
    displayMarkerEditionMenu = 'displayMarkerEditionMenu',
    displayExplorationMenu = 'displayExplorationMenu',
    displayAccount = 'displayAccount',
    displayRumorEditionMenu = 'displayRumorEditionMenu',
    displaySaveLoad = 'displaySaveLoad',
    displaySelectMap = 'displaySelectMap',
    hideAllMenu = 'hideAllMenu',
    toggleEditionMode = 'toggleEditionMode',
    toggleShowVisited = 'toggleShowVisited',
    toggleMarkerTypeToDimOnMap = 'toggleMarkerTypeToDimOnMap',
    updateMarkerToFilterOutFromSearch = 'updateMarkerToFilterOutFromSearch',
    setRole = 'setRole',
    setMapZoomFactor = 'setMapZoomFactor',
    enableDragging = "enableDragging",
    disableDragging = "disableDragging",
    toggleRumorsEdition = "toggleRumorsEdition",
    toggleDiplomaticRelationship = "toggleDiplomaticRelationship",
    setDiplomaticRelationshipFirstMarkerId = "setDiplomaticRelationshipFirstMarkerId",
    displayDiplomaticRelationshipLinesFromThisMarkerId = "displayDiplomaticRelationshipLinesFromThisMarkerId",
    markRouteChangeAsProgramatic = "markRouteChangeAsProgramatic",
    unmarkRouteChangeAsProgramatic = "unmarkRouteChangeAsProgramatic",
    toggleDistance = "toggleDistance",
    appendLatLng = "appendLatLng",
    cleanDistance = "cleanDistance",
}

export class ApplicationState {
    public editionModeEnabled: boolean = false;
    public shouldDisplayEventMenu: boolean = false;
    public shouldDisplayDistance: boolean = false;
    public shouldDisplayMarkerEditionMenu: boolean = false;
    public shouldDisplayRumorEditionMenu: boolean = false;
    public shouldDisplayExplorationMenu: boolean = false;
    public shouldDisplayAccount: boolean = false;
    public shouldDisplaySaveLoad: boolean = false;
    public shouldDisplaySelectMap: boolean = false;
    public markerEditionMenuMarkerId: number = 0;
    public rumorEditionMenuRumorId: number = 0;
    public showVisited: boolean = false;
    public markerTypesToDim: string[] = [];
    public distanceMarkers: {lat: number, lng: number}[] = [];
    public markerIdsToFilterOut: number[] = [];
    public role: Roles = Roles.PLAYER;
    public mapZoomFactor: number = 1;
    public draggingEnabled: boolean = false;
    public rumorsEditionEnabled: boolean = false;
    public diplomaticRelationshipEnabled: boolean = false;
    public diplomaticRelationshipFirstMarkerId: number | undefined = undefined;
    public displayDiplomaticRelationshipLinesFromThisMarkerId: number | undefined = undefined;
    public isProgramaticRouteChange: boolean = false;
}

export const applicationState: Module<ApplicationState, RootState> = {
    state() {
        return new ApplicationState();
    },
    mutations: {
        [ApplicationCommits.displayEventMenu](state: ApplicationState) {
            state.shouldDisplayEventMenu = true;
        },
        [ApplicationCommits.displayMarkerEditionMenu](state: ApplicationState, id: number) {
            state.shouldDisplayMarkerEditionMenu = true;
            state.markerEditionMenuMarkerId = id;
        },
        [ApplicationCommits.displayRumorEditionMenu](state: ApplicationState, id: number) {
            state.shouldDisplayRumorEditionMenu = true;
            state.rumorEditionMenuRumorId = id;
        },
        [ApplicationCommits.displayExplorationMenu](state: ApplicationState) {
            state.shouldDisplayExplorationMenu = true;
        },
        [ApplicationCommits.displayAccount](state: ApplicationState) {
            state.shouldDisplayAccount = true;
        },
        [ApplicationCommits.displaySaveLoad](state: ApplicationState) {
            state.shouldDisplaySaveLoad = true;
        },
        [ApplicationCommits.displaySelectMap](state: ApplicationState) {
            state.shouldDisplaySelectMap = true;
        },
        [ApplicationCommits.toggleDistance](state: ApplicationState, shouldDisplayDistance: boolean) {
            state.shouldDisplayDistance = shouldDisplayDistance;
        },
        [ApplicationCommits.appendLatLng](state: ApplicationState, { lat, lng }:{ lat: number, lng: number }) {
            if (state.distanceMarkers.length === 0) {
                state.distanceMarkers.push({lat, lng});
            } else if (state.distanceMarkers.length === 1) {
                state.distanceMarkers.push({lat, lng});
            } else if (state.distanceMarkers.length === 2) {
                do {
                    state.distanceMarkers.pop();
                } while (state.distanceMarkers.length > 0);
                state.distanceMarkers.push({lat, lng});
            }
        },
        [ApplicationCommits.cleanDistance](state: ApplicationState) {
            do {
                state.distanceMarkers.pop();
            } while (state.distanceMarkers.length > 0);
        },
        [ApplicationCommits.hideAllMenu](state: ApplicationState) {
            state.shouldDisplayDistance = false;
            state.shouldDisplayEventMenu = false;
            state.shouldDisplayMarkerEditionMenu = false;
            state.shouldDisplayRumorEditionMenu = false;
            state.shouldDisplayExplorationMenu = false;
            state.shouldDisplayAccount = false;
            state.shouldDisplaySaveLoad = false;
            state.shouldDisplaySelectMap = false;
        },
        [ApplicationCommits.toggleEditionMode](state: ApplicationState) {
            state.editionModeEnabled = !state.editionModeEnabled;
        },
        [ApplicationCommits.toggleShowVisited](state: ApplicationState) {
            state.showVisited = !state.showVisited;
        },
        [ApplicationCommits.toggleMarkerTypeToDimOnMap](state: ApplicationState, markerTypeDimToToggle: MarkerType) {
            if (state.markerTypesToDim.indexOf(markerTypeDimToToggle) === -1) {
                state.markerTypesToDim.push(markerTypeDimToToggle);
            } else {
                state.markerTypesToDim = state.markerTypesToDim.filter(markerType => markerType !== markerTypeDimToToggle);
            }
        },
        [ApplicationCommits.updateMarkerToFilterOutFromSearch](state: ApplicationState, markerIdsToFilterOut: number[]) {
            state.markerIdsToFilterOut = markerIdsToFilterOut;
        },
        [ApplicationCommits.setRole](state: ApplicationState, role: Roles) {
            state.role = role;
        },
        [ApplicationCommits.setMapZoomFactor](state: ApplicationState, mapZoomFactor: number) {
            state.mapZoomFactor = mapZoomFactor;
        },
        [ApplicationCommits.enableDragging](state: ApplicationState) {
            state.draggingEnabled = true;
        },
        [ApplicationCommits.disableDragging](state: ApplicationState) {
            state.draggingEnabled = false;
        },
        [ApplicationCommits.toggleRumorsEdition](state: ApplicationState) {
            state.rumorsEditionEnabled = !state.rumorsEditionEnabled;
        },
        [ApplicationCommits.toggleDiplomaticRelationship](state: ApplicationState) {
            state.diplomaticRelationshipEnabled = !state.diplomaticRelationshipEnabled;
        },
        [ApplicationCommits.setDiplomaticRelationshipFirstMarkerId](state: ApplicationState, id: number) {
            state.diplomaticRelationshipFirstMarkerId = id;
        },
        [ApplicationCommits.displayDiplomaticRelationshipLinesFromThisMarkerId](state: ApplicationState, id: number | undefined) {
            state.displayDiplomaticRelationshipLinesFromThisMarkerId = id;
        },
        [ApplicationCommits.markRouteChangeAsProgramatic](state: ApplicationState) {
            state.isProgramaticRouteChange = true;
        },
        [ApplicationCommits.unmarkRouteChangeAsProgramatic](state: ApplicationState) {
            state.isProgramaticRouteChange = false;
        },
    },
    actions: {
        [ApplicationActions.displayEventMenu]: ({ commit }) => {
            commit(ApplicationCommits.hideAllMenu);
            return commit(ApplicationCommits.displayEventMenu);
        },
        [ApplicationActions.displayMarkerEditionMenu]: ({ commit }, id: number) => {
            commit(ApplicationCommits.hideAllMenu);
            return commit(ApplicationCommits.displayMarkerEditionMenu, id);
        },
        [ApplicationActions.displayRumorEditionMenu]: ({ commit }, id: number) => {
            commit(ApplicationCommits.hideAllMenu);
            return commit(ApplicationCommits.displayRumorEditionMenu, id);
        },
        [ApplicationActions.displayExplorationMenu]: ({ commit }) => {
            commit(ApplicationCommits.hideAllMenu);
            return commit(ApplicationCommits.displayExplorationMenu);
        },
        [ApplicationActions.displayAccount]: ({ commit }) => {
            commit(ApplicationCommits.hideAllMenu);
            return commit(ApplicationCommits.displayAccount);
        },
        [ApplicationActions.displaySaveLoad]: ({ commit }) => {
            commit(ApplicationCommits.hideAllMenu);
            return commit(ApplicationCommits.displaySaveLoad);
        },
        [ApplicationActions.displaySelectMap]: ({ commit }) => {
            commit(ApplicationCommits.hideAllMenu);
            return commit(ApplicationCommits.displaySelectMap);
        },
        [ApplicationActions.toggleDistance]: ({ commit, state }) => {
            const shouldDisplayDistance: boolean = !state.shouldDisplayDistance;
            commit(ApplicationCommits.hideAllMenu);
            return commit(ApplicationCommits.toggleDistance, shouldDisplayDistance);
        },
        [ApplicationActions.appendLatLng]: ({ commit }, { lat, lng }:{ lat: number, lng: number }) => {
            return commit(ApplicationCommits.appendLatLng, { lat, lng });
        },
        [ApplicationActions.cleanDistance]: ({ commit }) => {
            return commit(ApplicationCommits.cleanDistance);
        },
        [ApplicationActions.hideAllMenu]: ({ commit }) => {
            return commit(ApplicationCommits.hideAllMenu);
        },
        [ApplicationActions.toggleEditionMode]: ({ commit }) => {
            return commit(ApplicationCommits.toggleEditionMode);
        },
        [ApplicationActions.toggleShowVisited]: ({ commit }) => {
            return commit(ApplicationCommits.toggleShowVisited);
        },
        [ApplicationActions.zoomOnPlayerMarker]: () => {},
        [ApplicationActions.toggleMarkerTypeToDimOnMap]: ({ commit }, markerTypeDimToToggle: MarkerType) => {
            return commit(ApplicationCommits.toggleMarkerTypeToDimOnMap, markerTypeDimToToggle);
        },
        [ApplicationActions.updateMarkerToFilterOutFromSearch]: ({ commit, getters }, markerFilterQuery: string) => {
            const markerIdsToFilterOut: number[] = getters[MarkerGetters.GET_ALL_MARKERS].filter((marker: MarkerData) => {
                return marker.label.toLocaleLowerCase().indexOf(markerFilterQuery.toLocaleLowerCase()) === -1
                    && marker.text.toLocaleLowerCase().indexOf(markerFilterQuery.toLocaleLowerCase()) === -1;
            }).map((marker: MarkerData) => marker.id);
            return commit(ApplicationCommits.updateMarkerToFilterOutFromSearch, markerIdsToFilterOut);
        },
        [ApplicationActions.setRole]: ({ commit }, role: string) => {
            return commit(ApplicationCommits.setRole, role as Roles);
        },
        [ApplicationActions.setMapZoomFactor]: ({ commit }, mapZoomFactor: number) => {
            return commit(ApplicationCommits.setMapZoomFactor, mapZoomFactor);
        },
        [ApplicationActions.enableDragging]: ({ commit }) => {
            return commit(ApplicationCommits.enableDragging);
        },
        [ApplicationActions.disableDragging]: ({ commit }) => {
            return commit(ApplicationCommits.disableDragging);
        },
        [ApplicationActions.toggleRumorsEdition]: ({ commit }) => {
            return commit(ApplicationCommits.toggleRumorsEdition);
        },
        [ApplicationActions.toggleDiplomaticRelationship]: ({ commit }) => {
            return commit(ApplicationCommits.toggleDiplomaticRelationship);
        },
        [ApplicationActions.setDiplomaticRelationshipFirstMarkerId]: ({ commit }, id: number) => {
            return commit(ApplicationCommits.setDiplomaticRelationshipFirstMarkerId, id);
        },
        [ApplicationActions.displayDiplomaticRelationshipLinesFromThisMarkerId]: ({ commit }, id: number | undefined) => {
            return commit(ApplicationCommits.displayDiplomaticRelationshipLinesFromThisMarkerId, id);
        },
        [ApplicationActions.markRouteChangeAsProgramatic]: ({ commit }) => {
            return commit(ApplicationCommits.markRouteChangeAsProgramatic);
        },
        [ApplicationActions.unmarkRouteChangeAsProgramatic]: ({ commit }) => {
            return commit(ApplicationCommits.unmarkRouteChangeAsProgramatic);
        },
    }
}
