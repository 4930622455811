import { GenericData, GenericEventStoreFunctions } from "../genericEvents/genericMapStoreFunctions";

export type MarkerDeleteType = {
    id?: number;
    label?: string;
}

export type MarkerDiffType = {
    id?: number;
    lat?: number;
    lng?: number;
    label?: string;
    type?: string;
    text?: string;
    isVisited?: boolean;
    isVisible?: boolean;
    youAreHere?: boolean;
}

export class MarkerData implements GenericData {
    id: number;
    lat: number;
    lng: number;
    label: string;
    type: string;
    text: string;
    isVisited: boolean;
    isVisible: boolean;
    youAreHere: boolean;

    constructor(
        id: number,
        lat: number,
        lng: number,
        label: string,
        type: string,
        text: string,
        isVisited: boolean,
        isVisible: boolean,
        youAreHere: boolean
    ) {
        this.id = id;
        this.lat = lat;
        this.lng = lng;
        this.label = label;
        this.type = type;
        this.text = text;
        this.isVisited = isVisited;
        this.isVisible = isVisible;
        this.youAreHere = youAreHere;
    }
}

enum MarkerCommits {
    ADD_MARKER='ADD_MARKER',
    DELETE_MARKER='DELETE_MARKER',
    EVENT_DELETE_MARKER='EVENT_DELETE_MARKER',
    CHANGE_MARKER='CHANGE_MARKER',
}

export enum MarkerActions {
    ADD_MARKER='ADD_MARKER',
    DELETE_MARKER='DELETE_MARKER',
    EVENT_DELETE_MARKER='EVENT_DELETE_MARKER',
    CHANGE_MARKER='CHANGE_MARKER',
}

export enum MarkerGetters {
    GET_A_MARKER='GET_A_MARKER',
    GET_ALL_MARKERS='GET_ALL_MARKERS',
    GET_EVENTS_OF_MARKERS='GET_EVENTS_OF_MARKERS',
    MAX_ID_OF_MARKERS='MAX_ID_OF_MARKERS',
}

export const markerEventStoreFunctions = new GenericEventStoreFunctions<MarkerData>(
    'markerHistory',
    [
        MarkerCommits.ADD_MARKER,
        MarkerCommits.DELETE_MARKER,
        MarkerCommits.EVENT_DELETE_MARKER,
        MarkerCommits.CHANGE_MARKER,
    ],
    [
        MarkerActions.ADD_MARKER,
        MarkerActions.DELETE_MARKER,
        MarkerActions.EVENT_DELETE_MARKER,
        MarkerActions.CHANGE_MARKER,
    ],
    [
        MarkerGetters.GET_A_MARKER,
        MarkerGetters.GET_ALL_MARKERS,
        MarkerGetters.GET_EVENTS_OF_MARKERS,
        MarkerGetters.MAX_ID_OF_MARKERS,
    ]);
