import { captureException } from '@sentry/browser';
import axios, { AxiosResponse } from 'axios';
import { MapState } from '../store/mapStore/mapState';
import { migrateState } from './migrateState';

declare const _API_URL_: boolean;

export function getMapState() {
    return axios
        .get(_API_URL_ + '/api/saves')
        .then((response: AxiosResponse<any>): MapState => migrateState(response.data))
        .catch(error => {
            console.log(error);
            captureException(error);
            return new MapState();
        });
}