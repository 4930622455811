import loadHtml from './toast.html';
import { fillTemplate } from '../htmlTemplateBuilder';

export default class Toast {
    constructor(text) {
        const list = this.addList();
        const id = text.replace(/\s/g, '_') + Math.random();
        const toast = document.createRange().createContextualFragment(fillTemplate(loadHtml, { id, text }));
        list.appendChild(toast);
        const toastElement = document.getElementById(id);
        setTimeout(() => {
            try {
                list.removeChild(toastElement);
            } catch (e) {
                console.log(document, toastElement, e);
            }
        }, 4000);
    }

    addList() {
        const listId = 'toast-list';
        let list = document.getElementById(listId);
        if (list === null) {
            list = document.createElement('ul');
            document.body.appendChild(list);
            list.setAttribute('id', listId);
        }
        return list;
    }
}
