import L from 'leaflet';
import { ApplicationActions } from '../../../store/applicationState';
import youAreHereIcon from '../../../statics/images/you-are-here.png';

export default class YouAreHereControl {
    constructor(store) {
        const LeafletControlConstructor = L.Control.extend({
            options : {
                position : 'topleft',
            },

            onAdd : () => {
                let container = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom');
                container.type = 'div';
                container.innerHTML = `<a>
                <img class="map-control youarehere-map-button" src="${youAreHereIcon}" alt=""/>
                <span class="tooltip-container">
                <span class="tooltip">Vous êtes ici</span>
                </span>
                </a>`;
                container.style.color = 'black';
                container.style.backgroundColor = 'white';
                container.style.width = '30px';
                container.style.height = '30px';

                container.addEventListener('pointerup', (event) => {
                    event.stopPropagation();
                    store.dispatch(ApplicationActions.zoomOnPlayerMarker);
                });

                return container;
            },
        });
        this._control = new LeafletControlConstructor();
    }

    getControl() {
        return this._control;
    }
}
