
import { computed, markRaw, nextTick, onMounted, reactive, ref, Ref, shallowReactive, shallowReadonly, shallowRef, toRaw, toRef, unref, watch, watchEffect } from 'vue';
import { useStore, Store } from 'vuex';
import * as showdown from 'showdown';
import { RootState } from '../store/store';
import { ApplicationActions, ApplicationState } from '../store/applicationState';
import { MapActions } from "../store/mapStore/MapActions";
import { MapGetters } from "../store/mapStore/MapGetters";
import { Map } from "../store/domain/Map";
import { MapState } from '../store/mapStore/mapState';
import { RumorActions, RumorData, RumorGetters, RumorsData, RumorsDiffType } from '../store/domain/rumors/RumorsEvents';
import Editor from './graphics/Editor.vue';
import { Roles } from '../iam/javascripts/Roles';
import { RouteLocationNormalized, Router, useRoute, useRouter } from 'vue-router';

export default {
    name: 'RumorEdition',
    components: {
        Editor,
    },
    setup() {
        const store: Store<RootState> = useStore();
        const router: Router = useRouter();

        const newLabel: Ref<string> = ref('');

        const editionModeEnabled: Ref<boolean> = toRef(store.state.applicationState, 'editionModeEnabled');
        const rumorsId: Ref<number> = toRef(store.state.applicationState, 'rumorEditionMenuRumorId');
        const shouldDisplayRumorEditionMenu: Ref<boolean> = toRef(store.state.applicationState, 'shouldDisplayRumorEditionMenu');
        const isGameMaster = computed(() => store.state.applicationState.role === Roles.GAME_MASTER );

        watch([ rumorsId, shouldDisplayRumorEditionMenu ], () => {
            if (shouldDisplayRumorEditionMenu.value) {
                store.dispatch(ApplicationActions.markRouteChangeAsProgramatic);
                router.replace({ query: { currentMap: router.currentRoute.value.query.currentMap, rumorsId: rumorsId.value.toString() } });
            }
        });

        const rumors = computed(() => {
            return store.getters[RumorGetters.GET_A_RUMOR](rumorsId.value);
        });

        const rumorDataList = computed(() => {
            const rumorDataList = store.getters[RumorGetters.GET_A_RUMOR](rumorsId.value).rumorDataList;
            return [...rumorDataList]
              .sort((a, b) => a.label.localeCompare(b.label))
              .filter(rumor => {
                return rumor.isVisible===true || isGameMaster.value;
              });
        });

        const rumorsToExpand = ref(
            Object.fromEntries(
                [...Object.values(rumorDataList)].map((rumorData: RumorData): [ number, boolean ] => {
                    return [rumorData.id, false];
                })
            )
        );
        
        function deleteRumor() {
            store.dispatch(RumorActions.DELETE_RUMOR, rumorsId.value);
            store.dispatch(ApplicationActions.hideAllMenu);
        }

        function addRumor() {
            if (newLabel.value.length > 0) {
              const rumorsDiff: RumorsDiffType = toRaw({
                id: rumorsId.value,
                rumorDataList: [
                    {
                        id: (toRaw(rumors.value.rumorDataList).sort((a: RumorData, b: RumorData) => b.id - a.id)[0]?.id ?? 0) + 1,
                        label: newLabel.value,
                        text: '',
                        isVisible: true,
                    },
                ],
              });
              newLabel.value = '';
              store.dispatch(RumorActions.CHANGE_RUMOR, rumorsDiff);
            }
        }

        function deleteRumorFromEvent(rumorToDelete: RumorData) {
          const rumorsDiff: RumorsDiffType = toRaw({
              id: rumorsId.value,
              patch_prune_array: {
                rumorDataList: [rumorToDelete.id],
              }
            });
          store.dispatch(RumorActions.CHANGE_RUMOR, rumorsDiff);
        }

        function expandRumor(rumorToExpand: RumorData) {
          rumorsToExpand.value[rumorToExpand.id] = !rumorsToExpand.value[rumorToExpand.id];
        }

        function modifyText(rumor: RumorData, text: string) {
          const rumorsDiff: RumorsDiffType = toRaw({
              id: rumorsId.value,
              rumorDataList: [
                  {
                      id: rumor.id,
                      text,
                  },
              ]
            });
          store.dispatch(RumorActions.CHANGE_RUMOR, rumorsDiff);
        }
        
        function modifyRumorVisibility(rumor: RumorData, event: Event) {
          const checked: boolean = (<HTMLInputElement>event.target).checked;
          const rumorsDiff: RumorsDiffType = toRaw({
            id: rumorsId.value,
            rumorDataList: [
                {
                    id: rumor.id,
                    isVisible: checked,
                },
            ]
          });
          store.dispatch(RumorActions.CHANGE_RUMOR, rumorsDiff);
        }
        
        function modifyVisibility(event: Event) {
          const checked: boolean = (<HTMLInputElement>event.target).checked;
          const rumorsDiff: RumorsDiffType = toRaw({
            id: rumorsId.value,
            isVisible: checked,
          });
          store.dispatch(RumorActions.CHANGE_RUMOR, rumorsDiff);
        }

        function modifyRadius(event: Event) {
          const value: number = parseInt((<HTMLInputElement>event.target).value);
          const rumorsDiff: RumorsDiffType = toRaw({
            id: rumorsId.value,
            radius: value,
          });
          store.dispatch(RumorActions.CHANGE_RUMOR, rumorsDiff);
        }

        function modifyRumorLabel(rumor: RumorData, event: Event) {
          const label: string = (<HTMLInputElement>event.target).value;
          const rumorsDiff: RumorsDiffType = toRaw({
            id: rumorsId.value,
            rumorDataList: [
                {
                    id: rumor.id,
                    label,
                },
            ]
          });
          store.dispatch(RumorActions.CHANGE_RUMOR, rumorsDiff);
        }

        return {
            addRumor,
            deleteRumorFromEvent,
            deleteRumor,
            editionModeEnabled,
            expandRumor,
            isGameMaster,
            modifyRadius,
            modifyRumorLabel,
            modifyRumorVisibility,
            modifyVisibility,
            modifyText,
            newLabel,
            rumors,
            rumorDataList,
            rumorsId,
            rumorsToExpand,
            shouldDisplayRumorEditionMenu,
        }
    },
}
