<template>
    <div v-if="shouldDisplayMarkerEditionMenu" @click.stop>
        <div class="marker-edition-nav marker-edition-nav-animation">
            <div class="maedna-field">
                <input data-test="label" class="raw-text-editable" type="text" :disabled="!editionModeEnabled" v-model="markerLabel">
            </div>
            <div class="maedna-field">
                <Editor data-test='marker-text' :class="{ 'no-margin': editionModeEnabled }" v-model="markerText" :key="markerEditionMenuMarkerId + editionModeEnabled" :readonly="!editionModeEnabled" />
            </div>
            <div class="maedna-field player-toggable" v-if="isGameMaster">
                <select data-test="type" :disabled="!editionModeEnabled" v-model="markerType">
                    <option
                        v-for="markerCategory in markerCategories"
                        :value="markerCategory.id"
                        :key="markerCategory.id"
                        >
                        {{ markerCategory.name }}
                    </option>
                </select>
            </div>
            <div class="maedna-field player-toggable" v-if="isGameMaster">
                <label>
                    <input data-test="is-visited" type="checkbox" :disabled="!editionModeEnabled" v-model="markerIsVisited" />
                    <span>Visité</span>
                </label>
            </div>
            <div class="maedna-field" v-if="isGameMaster">
                <label>
                    <input data-test="is-visible" type="checkbox" :disabled="!editionModeEnabled" v-model="markerIsVisible" />
                    <span>Publique</span>
                </label>
            </div>
            <div class="maedna-field" v-if="isGameMaster">
                <label>
                    <input data-test="you-are-here" type="checkbox" :disabled="!editionModeEnabled" v-model="markerYouAreHere" />
                    <span>Vous êtes ici (activable sur un seul marqueur)</span>
                </label>
            </div>
            <div v-if="editionModeEnabled && markerType !== 'players'" class="maedna-field">
                <a @click="deleteMarker">Supprimer</a>
            </div>
            <!-- <div v-if="markerType === 'fleuves'">
                <input type="number" v-model="markerOrientation" />
            </div> -->
        </div>
    </div>
</template>

<script lang="ts">
import { computed, markRaw, nextTick, onBeforeUnmount, onDeactivated, onMounted, onUnmounted, ref, Ref, shallowRef, toRaw, toRef, unref, watch, watchEffect } from 'vue';
import { useStore, Store } from 'vuex';
import * as showdown from 'showdown';
import { RootState } from '../store/store';
import { ApplicationActions } from '../store/applicationState';
import { MapGetters } from "../store/mapStore/MapGetters";
import { Map } from "../store/domain/Map";
import { Marker } from "../store/domain/Marker";
import { MapState } from '../store/mapStore/mapState';
import { MarkerType } from '../store/domain/MarkerType';
import Editor from './graphics/Editor.vue';
import { Roles } from '../iam/javascripts/Roles';
import { MarkerActions, MarkerDiffType, MarkerGetters } from '../store/domain/markers/MarkerEvents';
import { DiplomaticRelationshipActions, DiplomaticRelationshipData, DiplomaticRelationshipGetters } from '../store/domain/diplomaticRelationship/diplomaticRelationshipEvents';
import { RouteLocationNormalized, Router, useRoute, useRouter } from 'vue-router';

export default {
    name: 'MarkerEdition',
    components: {
        Editor,
    },
    setup() {
        const store: Store<RootState> = useStore();
        const router: Router = useRouter();

        let editionArea: Ref<any> = ref(null);
        const editingText: Ref<boolean> = ref(false);
        const markerCategories = toRef(store.getters, MapGetters.getMarkerCategories);
        const editionModeEnabled: Ref<boolean> = toRef(store.state.applicationState, 'editionModeEnabled');
        const markerEditionMenuMarkerId: Ref<number> = toRef(store.state.applicationState, 'markerEditionMenuMarkerId');
        const shouldDisplayMarkerEditionMenu: Ref<boolean> = toRef(store.state.applicationState, 'shouldDisplayMarkerEditionMenu');
        const isGameMaster = computed(() => store.state.applicationState.role === Roles.GAME_MASTER );
        
        watch([ markerEditionMenuMarkerId, shouldDisplayMarkerEditionMenu ], () => {
            if (shouldDisplayMarkerEditionMenu.value) {
                store.dispatch(ApplicationActions.markRouteChangeAsProgramatic);
                router.replace({ query: { currentMap: router.currentRoute.value.query.currentMap, markerId: markerEditionMenuMarkerId.value.toString() } });
            }
        });

        const markerText = computed({
            get: () => {
                const marker = store.getters[MarkerGetters.GET_A_MARKER](markerEditionMenuMarkerId.value, true);
                return marker?.text;
            },
            set: (text) => {
                store.dispatch(MarkerActions.CHANGE_MARKER, { id: markerEditionMenuMarkerId.value, text });
            },
        });
        const markerLabel = computed({
            get: () => {
                const marker = store.getters[MarkerGetters.GET_A_MARKER](markerEditionMenuMarkerId.value);
                return marker?.label;
            },
            set: (label) => {
                store.dispatch(MarkerActions.CHANGE_MARKER, { id: markerEditionMenuMarkerId.value, label });
            },
        });
        const markerIsVisited = computed({
            get: () => {
                const marker = store.getters[MarkerGetters.GET_A_MARKER](markerEditionMenuMarkerId.value);
                return marker?.isVisited;
            },
            set: (isVisited) => {
                store.dispatch(MarkerActions.CHANGE_MARKER, { id: markerEditionMenuMarkerId.value, isVisited });
            },
        });
        const markerIsVisible = computed({
            get: () => {
                const marker = store.getters[MarkerGetters.GET_A_MARKER](markerEditionMenuMarkerId.value);
                return marker?.isVisible;
            },
            set: (isVisible) => {
                store.dispatch(MarkerActions.CHANGE_MARKER, { id: markerEditionMenuMarkerId.value, isVisible });
            },
        });
        const markerYouAreHere = computed({
            get: () => {
                const marker = store.getters[MarkerGetters.GET_A_MARKER](markerEditionMenuMarkerId.value);
                return marker?.youAreHere;
            },
            set: (youAreHere) => {
                store.dispatch(MarkerActions.CHANGE_MARKER, { id: markerEditionMenuMarkerId.value, youAreHere });
            },
        });
        const markerType = computed({
            get: () => {
                const marker = store.getters[MarkerGetters.GET_A_MARKER](markerEditionMenuMarkerId.value);
                return marker?.type;
            },
            set: (type) => {
                store.dispatch(MarkerActions.CHANGE_MARKER, { id: markerEditionMenuMarkerId.value, type });
            },
        });
        const markerOrientation = computed({
            get: () => {
                const marker = store.getters[MarkerGetters.GET_A_MARKER](markerEditionMenuMarkerId.value);
                return marker?.orientation;
            },
            set: (orientation) => {
                store.dispatch(MarkerActions.CHANGE_MARKER, { id: markerEditionMenuMarkerId.value, orientation: parseInt(orientation) });
            },
        });
        function deleteMarker() {
            store.getters[DiplomaticRelationshipGetters.GET_ALL_DIPLOMATIC_RELATIONSHIP]
                .filter((relationship: DiplomaticRelationshipData ) => {
                    return relationship.markerIds.indexOf(markerEditionMenuMarkerId.value) !== -1;
                })
                .forEach((relationship: DiplomaticRelationshipData ) => {
                    store.dispatch(DiplomaticRelationshipActions.DELETE_DIPLOMATIC_RELATIONSHIP, { id: relationship.id, label: markerLabel.value });        
                });
            store.dispatch(MarkerActions.DELETE_MARKER, { id: markerEditionMenuMarkerId.value, label: markerLabel.value });
            store.dispatch(ApplicationActions.hideAllMenu);
        }

        return {
            deleteMarker,
            editingText,
            editionArea,
            editionModeEnabled,
            isGameMaster,
            markerLabel,
            markerEditionMenuMarkerId,
            markerIsVisible,
            markerIsVisited,
            markerOrientation,
            markerText,
            markerType,
            markerTypes: [...Object.values(MarkerType)].filter(markerType => markerType !== MarkerType.players),
            markerYouAreHere,
            markerCategories,
            shouldDisplayMarkerEditionMenu,
        }
    },
}
</script>

<style lang="postcss" scoped>
$side-nav-length: 250px;
$list-side-margin: 5px;
$item-side-margin: 5px;
$inside-text-min-length: $side-nav-length - $list-side-margin * 2 - $item-side-margin * 2;

.marker-edition-nav {
  position: absolute;
  top:0;
  left: 0;
  right: auto;
  z-index: 10000;
  height: 100vh;
  max-width: 450px;
  width: 70vw;
  background-color: white;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  resize: horizontal;
  transition: all 0.2s ease;
}

.maedna-field {
  min-height: 30px;
  margin: 0 $list-side-margin;
  border-bottom: 0.5px solid #ffc8ca;
  align-items:center;
  display:flex;
  flex-wrap: wrap;
  font-size: 16px;
  line-height: 24px;
  min-width: $inside-text-min-length;

  textarea {
    resize: vertical;
    height: auto;
    font-family: monospace;
    width: 100%;
  }

  select {
    width: 100%;
    height: 30px;
    background-color: white;
    padding: 0;
    border: none;
    border-radius: 0;
    &:hover {
      background-color: #f0f0f0;
      transition: 0.2s;
    }
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    margin: 0 1px;
    text-transform: capitalize;
  }

  > .no-margin {
      margin: 0;
  }

  :deep() p {
      margin: 0;
  }

  > * {
    color: #A52A2A;
    margin: 0 $item-side-margin;
    min-height: 20px;
    img {
      min-width: 0;
      max-width: 100%;
      max-height: 100%;
    }
  }
  &:hover {
    background-color: #f0f0f0;
    transition: 0.2s;
  }
  &:first-child {
    font-size: 1.2em;
  }
  &:last-child {
    border-bottom: none;
  }
  .raw-text-editable {
    width: 100%;
    border: none;
    background-color: inherit;
    font-size: inherit;
    font-family: inherit;
  }
  .raw-text-editable:focus {
    box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  }
}

</style>