export enum MapActions {
  changeMapIsVisible="changeMapIsVisible",
  createMap="createMap",
  deleteMap="deleteMap",
  selectMap="selectMap",
  
  selectEditionDate='selectEditionDate',
  selectAsPlayersDate='selectAsPlayersDate',
  createMarkerCategory="createMarkerCategory",
  
  loadMarkers='loadMarkers',
  loadMarkersFromFile='loadMarkersFromFile',
  saveState='saveState',
  zoomOnMarker='zoomOnMarker',
}
