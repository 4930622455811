import L from 'leaflet';
import { ApplicationActions } from '../../../store/applicationState';
import editableSwitchIcon from '../../../statics/images/pen.png';

export default class EditableSwitchControl {
    constructor(store) {
        store.subscribeAction({
            after : (action, state) => {
                if (action.type === ApplicationActions.toggleEditionMode) {
                    if (store.state.applicationState.editionModeEnabled) {
                        document.querySelector('.map-control.editable-switch-map-button').parentElement.style.backgroundColor = 'red';
                    } else {
                        document.querySelector('.map-control.editable-switch-map-button').parentElement.style.backgroundColor = 'white';
                    }
                }
            },
        });
        const LeafletControlConstructor = L.Control.extend({
            options : {
                position : 'topleft',
            },

            onAdd : () => {
                let container = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom editable-switch-map-button-for-test');
                container.type = 'div';
                container.innerHTML = `<a>
                <img class="map-control editable-switch-map-button" src="${editableSwitchIcon}" alt=""/>
                <span class="tooltip-container">
                <span class="tooltip">Edition</span>
                </span>
                </a>`;
                container.style.color = 'black';
                container.style.backgroundColor = 'white';
                container.style.width = '30px';
                container.style.height = '30px';

                container.addEventListener('pointerup', (event) => {
                    event.stopPropagation();
                    store.dispatch(ApplicationActions.toggleEditionMode);
                });

                return container;
            },
        });
        this._control = new LeafletControlConstructor();
    }

    getControl() {
        return this._control;
    }
}
