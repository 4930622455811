
import EventMenu from './EventMenu.vue';
import MarkerEdition from './MarkerEdition.vue';
import ExplorationMenu from './ExplorationMenu.vue';
import { useStore, Store } from 'vuex';
import { RootState } from '../store/store';
import { ApplicationActions } from '../store/applicationState';
import { ref, Ref, toRef } from 'vue';
import Iam from '../iam/javascripts/iam_logic/iam';
import Toast from '../javascripts/toasts/Toast.js';

export default {
    name: 'Account',
    setup() {
        const store: Store<RootState> = useStore();

        const shouldDisplayAccount: Ref<boolean> = toRef(store.state.applicationState, 'shouldDisplayAccount');
        const login: Ref<string> = ref('');
        const password: Ref<string> = ref('');
        const loggedIn: Ref<boolean> = ref(false);
        Iam.getSession()
                .then(() => loggedIn.value = true)
                .catch(() => loggedIn.value = false);

        function signIn() {
            Iam.login(login.value, password.value)
                .then(() => window.location.reload())
                .catch((error: Error) => new Toast(error.message));
        }
        function signOut() {
            Iam.getSession()
                .then((session: Iam) => {
                    session.logout();
                    window.location.reload();
                })
                .catch((error: Error) => new Toast(error.message));
        }

        return {
            login,
            loggedIn,
            password,
            signIn,
            signOut,
            shouldDisplayAccount,
        }
    },
}
