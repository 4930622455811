<template>
    <div class="popup">
        <div class="modify">
            <div>Relation: </div><div class="relation-container"><select v-model="relationshipNature" class="relation">
                <option value="ALLY">Allié</option>
                <option value="ENEMY">Enemi</option>
                <option value="NEUTRAL">Neutre</option>
            </select></div>
        </div>
        <div class="delete">
            <a @click="deleteRelationship">Supprimer</a>
        </div>
    </div>
</template>

<script lang="ts">
import { useStore, Store } from 'vuex';
import { RootState } from '../../store/store';
import { ApplicationActions } from '../../store/applicationState';
import { MapGetters } from '../../store/mapStore/MapGetters';
import { computed, markRaw, nextTick, onBeforeMount, onMounted, PropType, Ref, ref, toRef, watch } from 'vue';
import { Marker } from '../../store/domain/Marker';
import MarkerTypes from './MarkerTypes.js';
import { MarkerType } from '../../store/domain/MarkerType';
import { MarkerCategory } from '../../store/domain/MarkerCategory';
import { MarkerGetters } from '../../store/domain/markers/MarkerEvents';
import L from 'leaflet';
import { DiplomaticRelationshipActions, DiplomaticRelationshipData, DiplomaticRelationshipDeleteType, DiplomaticRelationshipDiffType, DiplomaticRelationshipGetters, DiplomaticRelationshipNature } from '../../store/domain/diplomaticRelationship/diplomaticRelationshipEvents';

export default {
    name: 'DiplomaticRelationshipPopup',
    props: {
        id: {
            type: Number,
            required: true
        },
    },
    setup(props: Readonly<{ id: number; } & {}>) {
        const store: Store<RootState> = useStore();
        
        const diplomaticRelationship: Ref<DiplomaticRelationshipData> = computed(() => {
            return store.getters[DiplomaticRelationshipGetters.GET_A_DIPLOMATIC_RELATIONSHIP](props.id);
        });
        const relationshipNature = computed({
            get: () => {
                return diplomaticRelationship.value.nature;
            },
            set: (newNature: DiplomaticRelationshipNature) => {
                const diff: DiplomaticRelationshipDiffType = {
                    id: diplomaticRelationship.value.id,
                    nature: newNature,
                };
                store.dispatch(DiplomaticRelationshipActions.CHANGE_DIPLOMATIC_RELATIONSHIP, diff);
            },
        });
        function deleteRelationship() {
            const deleteRelationshipData: DiplomaticRelationshipDeleteType = {
                id: diplomaticRelationship.value.id,
            };
            store.dispatch(DiplomaticRelationshipActions.DELETE_DIPLOMATIC_RELATIONSHIP, deleteRelationshipData);
        }
        return {
            deleteRelationship,
            diplomaticRelationship,
            relationshipNature,
        }
    },
}
</script>

<style lang="postcss" scoped>
.modify {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
}
.relation-container {
    padding-left: 5px;
}
.delete {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;

    padding-top: 12px;
    cursor: pointer;
}
</style>