import { nextTick, ref, Ref, watch,  } from 'vue';
import Map from "./Map";
import { v4 as generateUid } from 'uuid';

declare const _API_URL_: string;

enum MessageType {
    WHO_IS_IT = 'WHO_IS_IT',
    USER_LIST = 'USER_LIST',
    FORWARD_CURSOR = 'FORWARD_CURSOR',
}

class Message {
    type!: MessageType;
    [key: string]: any,
}

class WhoIsItMessage extends Message {
    type!: MessageType.WHO_IS_IT;
    id!: string;
}

class UserListMessage extends Message {
    type!: MessageType.WHO_IS_IT;
    users!: {id: string}[];
}

class ForwardCursorMessage extends Message {
    type!: MessageType.FORWARD_CURSOR;
    id!: string;
    lat!: number;
    lng!: number;
}

export type CursorUser = {
    id: string,
    lat: number,
    lng: number,
    color: string,
};

const colors: string[] = [
    'b58900', // yellow
    'cb4b16', // orange
    'dc322f', // red
    'd33682', // magenta
    '6c71c4', // violet
    '268bd2', // blue
    '2aa198', // cyan
    '859900', // green
];

function establishConnection(users: Ref<Ref<CursorUser>[]>, map: Map, trial_counter: number) {
    if (trial_counter === 0) {
        console.log('end of websocket retries');
        users.value.forEach(deletedUser => {
            colors.push(deletedUser.value.color);
            const index = users.value.indexOf(deletedUser);
            users.value.splice(index, 1);
        });
        return ;
    }
    try {
        const uid = generateUid();
        console.log('API_URL', _API_URL_);
        let ws: WebSocket;
        if (_API_URL_) {
            const protocolStrippedApiUrl = _API_URL_.replace(/https?:\/\//, '');
            console.log('-ws- url', `ws://${protocolStrippedApiUrl}`);
            ws = new WebSocket(`ws://${protocolStrippedApiUrl}`);
        } else {
            console.log('-ws- url', `wss://${location.hostname}`);
            ws = new WebSocket(`wss://${location.hostname}`);
        }

        ws.onmessage = (messageEvent: MessageEvent<any>) => {
            const message: Message = JSON.parse(messageEvent.data);
            const send = (data: Message) => ws.send(JSON.stringify(data));
            switch (message.type) {
                case MessageType.WHO_IS_IT: {
                    console.log('-ws- RECEIVED who is it, answering with ' + uid);
                    send({
                        type: MessageType.WHO_IS_IT,
                        id: uid,
                    });
                    let handler: number | undefined = undefined;
                    document.addEventListener('mapSetup', () => {
                        console.log('-ws- map ready');
                        if (handler) {
                            clearInterval(handler);
                        }
                        let coords: undefined | { lat: number, lng: number } = undefined;
                        let sentCoords: undefined | { lat: number, lng: number } = undefined;
                        map._leafletMap.value?.on(<any>'mousemove', (event) => {
                            // console.log('-ws- mousemove', (<any>event).latlng.lat, (<any>event).latlng.lng);
                            coords = {
                                lat: (<any>event).latlng.lat,
                                lng: (<any>event).latlng.lng,
                            };
                        });
                        console.log('-ws- starting interval');
                        handler = window.setInterval(() => {
                            // console.log('-ws- sending cursor position');
                            if (coords && coords != sentCoords) {
                                send({
                                    type: MessageType.FORWARD_CURSOR,
                                    id: uid,
                                    lat: coords.lat,
                                    lng: coords.lng,
                                });
                                sentCoords = coords;
                            }
                        }, 1);
                    });
                    if (map._leafletMap.value) {
                        console.log('-ws- map already ready');
                        document.dispatchEvent(new Event('mapSetup'));
                    }
                    return ;
                }
                case MessageType.USER_LIST: {
                    console.log('-ws- RECEIVED user list with ' + message.users.length + ' users');
                    const userListMessage = <UserListMessage>message;
                    const deletedUsers = users.value.filter((localUser) => {
                        const result = userListMessage.users.find((user) => user.id === localUser.value.id);
                        return !result;
                    });
                    deletedUsers.forEach(deletedUser => {
                        colors.push(deletedUser.value.color);
                        const index = users.value.indexOf(deletedUser);
                        users.value.splice(index, 1);
                    });
                    const addedUsers = userListMessage.users
                        .filter((distantUser) => distantUser.id !== uid)
                        .filter((distantUser) => {
                            const result = users.value.find((user) => user.value.id === distantUser.id);
                            return !result;
                        });
                    addedUsers.forEach(addedUser => {
                        console.log('-ws- adding user', JSON.stringify(addedUser));
                        const user: Ref<CursorUser> = ref({
                            id: addedUser.id,
                            lat: 0,
                            lng: 0,
                            color: colors.pop() ?? '000000',
                        });
                        users.value.push(user);
                    });
                    return ;
                }
                case MessageType.FORWARD_CURSOR: {
                    console.log('-ws- RECEIVED forward cursor');
                    const forwardCursorMessage = <ForwardCursorMessage>message;
                    const user = users.value.find(user => user.value.id === forwardCursorMessage.id);
                    if (user) {
                        user.value.lat = forwardCursorMessage.lat;
                        user.value.lng = forwardCursorMessage.lng;
                    }
                    return ;
                }
                default: {
                    throw new Error('Message type unknown: ' + messageEvent.type);
                }
            }
        };
        ws.onclose = () => {
            console.log('-ws- websocket closing, retrying ...');
            establishConnection(users, map, trial_counter - 1);
        }
    } catch (error) {
        console.log(error);
    }
}

export function setupCursors(map: Map): Ref<Ref<CursorUser>[]> {
    const users: Ref<Ref<CursorUser>[]> = ref([]);
    setTimeout(() => establishConnection(users, map, 10), 1000);
    return users;
}