
import { nextTick, ref, Ref, toRef, SetupContext, toRefs } from 'vue';

type Item = { name: string, id: any };

export default {
    name: 'EditableSelect',
    props: {
        currentItem: {
            type: Object,
            default: undefined,
        },
        items: {
            type: Array,
            default: () => [],
        },
        validationMethod: {
            type: Function,
            default: () => true,
        },
        editionDisabled: {
            type: Boolean,
            default: () => false,
        }
    },
    setup(props: Readonly<{ currentItem: Record<string, any>; items: unknown[]; validationMethod: CallableFunction; editionDisabled: boolean } & {}>, { emit }: SetupContext) {
        const preselectedItem: Ref<Item | undefined> = ref();
        const shouldShowList: Ref<boolean> = ref(false);
        const currentItem: Ref<Item> = <Ref<Item>>toRef(props, 'currentItem');
        const items: Ref<Item[]> = <Ref<Item[]>>toRef(props, 'items');
        const editionDisabled: Ref<boolean> = toRef(props, 'editionDisabled');
        const input = ref();

        function showList() {
            emit('showList');
            shouldShowList.value = true;
        }
        function hideList() {
            if (preselectedItem.value) {
                selectItem(preselectedItem.value);
            } else {
                emit('hideList');
            }
            shouldShowList.value = false;
        }
        function inputNewItem(nameChange: Event) {
            const nameInput: HTMLInputElement = <HTMLInputElement>nameChange.currentTarget;
            const newName: string = nameInput.value;
            if (props.validationMethod(newName)) {
                input.value.blur();
                const existingItem = items.value.filter((item: Item) => item.name === newName)[0];
                if (existingItem) {
                    emit('selectedItem', existingItem);
                } else {
                    emit('newItem', { id: undefined, name: newName });
                }
            }
        }
        function inputOnGoing(nameChange: Event) {
            const nameInput: HTMLInputElement = <HTMLInputElement>nameChange.currentTarget;
            emit('inputOnGoing', nameInput.value);
        }
        function selectItem(item: Item) {
            emit('selectedItem', item);
        }
        function preselectItem(item: Item) {
            emit('preselectedItem', item);
            preselectedItem.value = item;
        }
        function clearPreselectedItem() {
            emit('clearPreselectedItem', preselectedItem.value);
            preselectedItem.value = undefined;
        }
        function escapeInput() {
            clearPreselectedItem();
            input.value.blur();
        }
        function currentStyle(item: Item) {
            return {
                'font-weight': item.id === currentItem.value.id ? '700' : '',
                'background-color': item.id === preselectedItem.value?.id ? 'whitesmoke' : '',
            };
        }
        function itemUp() {
            if (preselectedItem.value === undefined) {
                preselectedItem.value = items.value[0];
            } else {
                const currentIndex = items.value.findIndex(item => item.id === preselectedItem.value?.id);
                let nextIndex;
                if (currentIndex === 0) {
                    nextIndex = items.value.length - 1;    
                } else {
                    nextIndex = currentIndex - 1;
                }
                preselectedItem.value = items.value[nextIndex];
            }
        }
        function itemDown() {
            if (preselectedItem.value === undefined) {
                preselectedItem.value = items.value[0];
            } else {
                const currentIndex = items.value.findIndex(item => item.id === preselectedItem.value?.id);
                let nextIndex;
                if (currentIndex === items.value.length - 1) {
                    nextIndex = 0;
                } else {
                    nextIndex = currentIndex + 1;
                }
                preselectedItem.value = items.value[nextIndex];
            }
        }
        
        return {
            clearPreselectedItem,
            currentItem,
            currentStyle,
            editionDisabled,
            escapeInput,
            hideList,
            input,
            inputNewItem,
            inputOnGoing,
            itemUp,
            itemDown,
            items,
            preselectItem,
            selectItem,
            showList,
            shouldShowList,
        }
    },
}
