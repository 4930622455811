import { captureException } from '@sentry/browser';
import axios, { AxiosResponse } from 'axios';
import { ApiMediaMetadata } from './get-temporary-upload-url';

declare const _API_URL_: boolean;

export function uploadMedia(apiMediaMetadata: ApiMediaMetadata, file: File) {
    return axios
        .put(apiMediaMetadata.temporaryUploadUrl, file, {
            headers: {
                // 'X-Container-Meta-Access-Control-Allow-Origin': '*',
                // 'Access-Control-Allow-Origin': '*',
                'Content-Type': file.type,
            }
        })
        .catch(error => {
            console.log(error);
            captureException(error);
            throw error;
        });
}