import L from 'leaflet';
import { nextTick, toRef, watch } from 'vue';
import distanceIcon from '../../../statics/images/path-icon.png';
import { ApplicationActions } from '../../../store/applicationState';

export default class DistanceControl {
    constructor(store) {
        this._showDistance = false;
        const shouldDisplayDistance = toRef(store.state.applicationState, 'shouldDisplayDistance');
        watch(shouldDisplayDistance, () => {
            if (shouldDisplayDistance.value) {
                document.querySelector('.map-control.distance-map-button').parentElement.style.backgroundColor = 'red';
            } else {
                document.querySelector('.map-control.distance-map-button').parentElement.style.backgroundColor = 'white';
            }
        });
        const LeafletControlConstructor = L.Control.extend({
            options : {
                position : 'topleft',
            },

            onAdd : () => {
                let container = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom');
                container.type = 'div';
                container.innerHTML = `<a>
                <img class="map-control distance-map-button" src="${distanceIcon}" alt=""/>
                <span class="tooltip-container">
                <span class="tooltip">Distance</span>
                </span>
                </a>`;
                container.style.color = 'black';
                container.style.backgroundColor = 'white';
                container.style.width = '30px';
                container.style.height = '30px';

                container.addEventListener('pointerup', (event) => {
                    event.stopPropagation();
                    nextTick(() => {
                        store.dispatch(ApplicationActions.toggleDistance);
                    });
                });

                return container;
            },
        });
        this._control = new LeafletControlConstructor();
    }

    getControl() {
        return this._control;
    }
}
