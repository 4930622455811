<template>
    <div v-if="shouldDisplayAccount" @click.stop>
        <div id="iam-menu" class="iam-menu">
            <h3>Connexion</h3>
            <ul id="iam-login-form" v-show="!loggedIn">
                <li>
                    <input id="iam-user-name" type="text" class="validate" placeholder="Nom d'utilisateur" v-model="login">
                </li>

                <li>
                    <input id="iam-password" type="password" class="validate" placeholder="Mot de passe" v-model="password">
                </li>

                <li>
                    <button id="iam-login-user" class="btn waves-effect waves-light" @click="signIn">Login
                    </button>
                </li>
            </ul>
            <ul id="iam-logout-form" v-show="loggedIn">
                <li>
                    <button id="iam-logout-user" class="btn waves-effect waves-light" @click="signOut">Logout
                    </button>
                </li>
            </ul>
            <a href="/iam.html">Page de compte</a>
        </div>
    </div>
</template>

<script lang="ts">
import EventMenu from './EventMenu.vue';
import MarkerEdition from './MarkerEdition.vue';
import ExplorationMenu from './ExplorationMenu.vue';
import { useStore, Store } from 'vuex';
import { RootState } from '../store/store';
import { ApplicationActions } from '../store/applicationState';
import { ref, Ref, toRef } from 'vue';
import Iam from '../iam/javascripts/iam_logic/iam';
import Toast from '../javascripts/toasts/Toast.js';

export default {
    name: 'Account',
    setup() {
        const store: Store<RootState> = useStore();

        const shouldDisplayAccount: Ref<boolean> = toRef(store.state.applicationState, 'shouldDisplayAccount');
        const login: Ref<string> = ref('');
        const password: Ref<string> = ref('');
        const loggedIn: Ref<boolean> = ref(false);
        Iam.getSession()
                .then(() => loggedIn.value = true)
                .catch(() => loggedIn.value = false);

        function signIn() {
            Iam.login(login.value, password.value)
                .then(() => window.location.reload())
                .catch((error: Error) => new Toast(error.message));
        }
        function signOut() {
            Iam.getSession()
                .then((session: Iam) => {
                    session.logout();
                    window.location.reload();
                })
                .catch((error: Error) => new Toast(error.message));
        }

        return {
            login,
            loggedIn,
            password,
            signIn,
            signOut,
            shouldDisplayAccount,
        }
    },
}
</script>

<style lang="postcss" scoped>
$side-nav-length: 250px;

.iam-menu {
  position: absolute;
  top:0;
  left: 0;
  right: auto;
  z-index: 10000;
  height: 100vh;
  width: $side-nav-length;
  background-color: whitesmoke;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  resize: horizontal;
  transition: all 0.2s ease;
  h3 {
    margin: 10px;
    color: darkgrey;
  }
  ul {
    padding: 0;
    li {
      margin: 0 5px;
      padding: 5px;
      list-style-type: none;
      border-radius: 2px;
      border-bottom: white solid 1px;
      &:last-child {
        border-bottom: none;
      }
      transition: background-color 0.4s ease-out;
      &:hover {
        background-color: #e0e0e0;
      }
      a {
        font-size: 16px;
        color: black;
        text-decoration: none;
      }
    }
  }
  a {
    margin-left: 10px;
    color: slategray;
    font-size: 12px;
  }
}

.iam-content {
  grid-row: 1;
  grid-column: auto;
  display: flex;
  align-items: center;
  p {
    width: 100%;
    text-align: center;
    a {
      color: black;
    }
  }
}

</style>