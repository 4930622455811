<template>
    <div class="container">
        <div class="background">
            <div class="dialog">
                <div class="message" v-html="message" />
                <div class="buttons">
                    <a v-html="cancelText" @click="emit('cancel')"></a>
                    <a v-html="okText" @click="emit('ok')"></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { nextTick, ref, Ref, toRef, SetupContext, toRefs } from 'vue';

type Item = { name: string, id: any };

export default {
    name: 'EditableSelect',
    props: {
        message: {
            type: String,
        },
        okText: {
            type: String,
        },
        cancelText: {
            type: String,
        },
    },
    setup(props: Readonly<{ message?: string | undefined; okText?: string | undefined; cancelText?: string | undefined; } & {}>, { emit }: SetupContext) {
        const preselectedItem: Ref<Item | undefined> = ref();
        const shouldShowList: Ref<boolean> = ref(false);
        const message: Ref<string> = <Ref<string>>toRef(props, 'message');
        const okText: Ref<string> = <Ref<string>>toRef(props, 'okText');
        const cancelText: Ref<string> = <Ref<string>>toRef(props, 'cancelText');
        
        return {
            message,
            okText,
            cancelText,
            emit,
        }
    },
}
</script>

<style lang="postcss" scoped>
.container {
    position: relative;

    .background {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .dialog {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 350px;
        padding: 15px;

        background-color: white;

        border-radius: 7px;

        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.20), 0 3px 1px -2px rgba(0, 0, 0, 0.20), 0 1px 5px 0 rgba(0, 0, 0, 0.2);

        .buttons {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 20px;

            width: 100%;

            margin-top: 15px;

            a {
                grid-column: auto;
                border: 1px solid lightgrey;
                border-radius: 5px;
                text-decoration: none;
                width: 100%;
                text-align: center;

                cursor: pointer;

                &:hover {
                    background-color: lightgrey;
                }
            }
        }
    }
}
</style>