import { Module } from 'vuex';
import { getMapState } from '../../repository/get-map';
import { RootState } from '../store';
import { migrateState } from '../../repository/migrateState';
import { saveMapState } from '../../repository/save-map';
import { Roles } from '../../iam/javascripts/Roles';
import { v4 as uuid } from 'uuid';
import { MapActions } from './MapActions';
import { MapCommits } from './MapCommits';
import { MapGetters } from './MapGetters';
import { Map } from '../domain/Map';
import { MarkerCategory } from '../domain/MarkerCategory';
import { rumorsEventStoreFunctions } from '../domain/rumors/RumorsEvents';
import { diplomaticRelationshipEventStoreFunctions } from '../domain/diplomaticRelationship/diplomaticRelationshipEvents';
import { MarkerData, markerEventStoreFunctions, MarkerGetters } from '../domain/markers/MarkerEvents';
import { RouteLocationNormalized, useRoute, useRouter } from 'vue-router';
import { ApplicationActions } from '../applicationState';

export class MapState {
    public version: number = 0;
    public orderedDates: string[] = [];
    public currentPlayersMap: string = '';
    public currentPlayersDate: string = '';
    public maps: { [id: string]: Map } = {};
    
    public currentMap: string = '';
    public currentDate: string = '';
    public maxId: number = 0;
    public zoomedMarkerId: number = 0;
    public saveHandler: number | undefined = undefined;
}

export const mapState: Module<MapState, RootState> = {
    state() {
        return new MapState();
    },
    mutations: {
        ...markerEventStoreFunctions.commits,
        ...rumorsEventStoreFunctions.commits,
        ...diplomaticRelationshipEventStoreFunctions.commits,
        [MapCommits.updateMapState](state: MapState, mapState: MapState) {
            state.currentDate = '';
            state.maxId = mapState.maxId;
            state.version = mapState.version;
            state.currentPlayersMap = mapState.currentPlayersMap;
            state.currentPlayersDate = mapState.currentPlayersDate;
            state.maps = mapState.maps;
            state.orderedDates = mapState.orderedDates;
            state.currentDate = mapState.currentDate;
            state.currentMap = mapState.currentMap;
        },
        [MapCommits.selectEditionDate](state: MapState, newEditionDate: string) {
            if (state.orderedDates.indexOf(newEditionDate) === -1) {
                state.orderedDates.push(newEditionDate);
                state.orderedDates = state.orderedDates.sort();
            }
            state.currentDate = newEditionDate;
        },
        [MapCommits.selectAsPlayersDate](state: MapState) {
            state.currentPlayersDate = state.currentDate;
        },
        [MapCommits.zoomOnMarker](state: MapState, id: number) {
            state.zoomedMarkerId = id;
        },
        [MapCommits.saveHandler](state: MapState, saveHandler: number) {
            state.saveHandler = saveHandler;
        },
        [MapCommits.createMap](state: MapState, { name, url, width, height }:{ name: string, url: string, width: number, height: number }) {
            const mapId: string = uuid();
            state.maps[mapId] = new Map(mapId, name, url, width, height, false, [], [], [], []);
            state.currentMap = mapId;
        },
        [MapCommits.selectMap](state: MapState, { id }:{ id: string }) {
            state.currentMap = id;
        },
        [MapCommits.deleteMap](state: MapState) {
            state.maps = Object.fromEntries(
                Object.values(state.maps)
                    .filter((map: Map) => map.id !== state.currentMap)
                    .map((map: Map) => [map.id, map]));
            state.currentMap = Object.values(state.maps)[0].id;
        },
        [MapCommits.changeMapIsVisible](state: MapState, { id, isVisible }:{ id: string, isVisible: boolean }) {
            Object.values(state.maps)
                .filter((map: Map) => map.id === id)
                .map((map: Map) => {
                    map.isVisible = isVisible;
                });
        },
        [MapCommits.createMarkerCategory](state: MapState, {
                id,
                name,
                url,
                width,
                height,
                centerX,
                centerY,
            } : {
                id: string
                name: string,
                url: string,
                width: number,
                height: number,
                centerX: number,
                centerY: number,
            }) {
                if (id && id.length > 0) {
                    state.maps[state.currentMap].markerCategories
                        .filter((markerCategory: MarkerCategory) => {
                            return markerCategory.id === id;         
                        })
                        .forEach((markerCategory: MarkerCategory) => {
                            markerCategory.name = name;
                            markerCategory.url = url;
                            markerCategory.width = width;
                            markerCategory.height = height;
                            markerCategory.centerX = centerX;
                            markerCategory.centerY = centerY;
                        });
                } else {
                    const categoryId = uuid();
                    state.maps[state.currentMap].markerCategories.push(new MarkerCategory(
                        categoryId,
                        name,
                        url,
                        width,
                        height,
                        centerX,
                        centerY,
                    ));
                }
                state.maps[state.currentMap].markerCategories = state.maps[state.currentMap].markerCategories.sort((markerCategoryA: MarkerCategory, markerCategoryB: MarkerCategory) => markerCategoryA.name.localeCompare(markerCategoryB.name));
        },
    },
    actions: {
        ...markerEventStoreFunctions.actions,
        ...rumorsEventStoreFunctions.actions,
        ...diplomaticRelationshipEventStoreFunctions.actions,
        [MapActions.saveState]: ({ commit, state }) => {            
            if (state.saveHandler) {
                clearTimeout(state.saveHandler);
            }
            const handler: number = <number><unknown>setTimeout(() => {
                return saveMapState(state);
            }, 1500);
            commit(MapCommits.saveHandler, handler);
        },
        [MapActions.loadMarkers]: ({ commit, dispatch }, route: RouteLocationNormalized) => {
            return getMapState()
                .then((mapState: MapState) => {
                    commit(MapCommits.updateMapState, mapState);
                });
        },
        [MapActions.loadMarkersFromFile]: ({ commit }, fileObject: any) => {
            const mapState = migrateState(fileObject);
            commit(MapCommits.updateMapState, mapState)
        },
        [MapActions.selectEditionDate]: ({ commit, state, dispatch }, newEditionDate: string) => {
            if (state.orderedDates.indexOf(newEditionDate) === -1) {
                dispatch(MapActions.saveState);
            }
            return commit(MapCommits.selectEditionDate, newEditionDate);
        },
        [MapActions.selectAsPlayersDate]: ({ commit, dispatch }) => {
            dispatch(MapActions.saveState);
            commit(MapCommits.selectAsPlayersDate);
        },
        [MapActions.zoomOnMarker]: ({ commit }, id: number) => {
            return commit(MapCommits.zoomOnMarker, id);
        },
        [MapActions.createMap]: ({ commit, dispatch }, { name, url, width, height }:{ name: string, url: string, width: number, height: number }) => {
            commit(MapCommits.createMap, {name, url, width, height});
            dispatch(MapActions.saveState);
        },
        [MapActions.selectMap]: ({ commit }, { id }:{ id: string }) => {
            commit(MapCommits.selectMap, { id });
        },
        [MapActions.deleteMap]: ({ commit, dispatch }) => {
            commit(MapCommits.deleteMap);
            dispatch(MapActions.saveState);
        },
        [MapActions.changeMapIsVisible]: ({ commit, dispatch }, { id, isVisible }:{ id: string, isVisible: boolean }) => {
            commit(MapCommits.changeMapIsVisible, { id, isVisible });
            dispatch(MapActions.saveState);
        },
        [MapActions.createMarkerCategory]: ({ commit, dispatch }, {
                id,
                name,
                url,
                width,
                height,
                centerX,
                centerY,
            } : {
                id: string,
                name: string,
                url: string,
                width: number,
                height: number,
                centerX: number,
                centerY: number,
            }) => {
            commit(MapCommits.createMarkerCategory, {
                id: id,
                name: name,
                url: url,
                width: width,
                height: height,
                centerX: centerX,
                centerY: centerY,
              });
            dispatch(MapActions.saveState);
        },
    },
    getters: {
        ...markerEventStoreFunctions.getters,
        ...rumorsEventStoreFunctions.getters,
        ...diplomaticRelationshipEventStoreFunctions.getters,
        [MapGetters.getAvailableDates]: (state: MapState) => {
            return state.orderedDates;
        },
        [MapGetters.getPlayerMarker]: (state: MapState, getters) => {
            return getters[MarkerGetters.GET_ALL_MARKERS].filter((markerData: MarkerData) => {
                return markerData.youAreHere;
            })[0];
        },
        [MapGetters.getMaps]: (state: MapState, getters, rootState: RootState) => {
            return Object.values(state.maps)
                .map((map: Map) => map)
                .filter(map => map.isVisible || rootState.applicationState.role === Roles.GAME_MASTER)
                .sort((a, b) => a.name.localeCompare(b.name));
        },
        [MapGetters.getCurrentMap]: (state: MapState) => {
            return state.maps[state.currentMap];
        },
        [MapGetters.getMarkerCategories]: (state: MapState) => {
            return state.maps[state.currentMap]?.markerCategories ?? [];
        }
    }
}
