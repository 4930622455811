
import { toRefs, PropType } from 'vue';

export default {
    name: 'Cursor',
    props: {
        color: {
            type: String as PropType<string>,
            required: true
        },
    },
    setup(props: Readonly<{ color: string } & {}>) {
        const { color } = toRefs(props);
        
        return {
            color,
        }
    },
}
