import Cookies from 'js-cookie';
import AxiosStatic, { AxiosPromise } from 'axios';
import jwtDecode from 'jwt-decode';
import { Roles } from '../Roles';

const AUTH_COOKIE_NAME = 'auth_cookie';

declare var _API_URL_: string;

class AuthorizationContent {
    username: string;
    role: Roles;

    constructor(content: { username: string, role: string }) {
        this.username = content.username;
        this.role = Roles[content.role as Roles];
    }
}

export default class Iam {
    authorization: AuthorizationContent;

    constructor() {
        const authCookie: string | undefined = Cookies.get(AUTH_COOKIE_NAME);
        if (authCookie === undefined) {
            throw new Error('Not authenticated');
        } else {
            this.authorization = new AuthorizationContent(<{ username: string, role: string }>jwtDecode(authCookie));
        }
    }

    static getSession(): Promise<Iam> {
        return new Promise((resolve: CallableFunction, reject: CallableFunction) => {
            try {
                resolve(new Iam());
            } catch (error) {
                reject(error);
            }
        });
    }

    static register(username: string, password: string, email: string): AxiosPromise<void> {
        return AxiosStatic
            // eslint-disable-next-line no-undef
            .post(_API_URL_ + '/api/register', { username, password, email })
            .catch((error) => {
                console.log('error', error);
                throw error;
            });
    }

    static login(username: string, password: string) {
        return AxiosStatic
            // eslint-disable-next-line no-undef
            .post(_API_URL_ + '/api/login', { username, password })
            .then(response => {
                Cookies.set(AUTH_COOKIE_NAME, response.data.token, { secure: true, sameSite: 'strict' });
            })
            .catch((error) => {
                console.log('error', error);
                throw error;
            });
    }

    changePassword(oldPassword: string, newPassword: string): Promise<void> {
        return AxiosStatic
            .post(_API_URL_ + '/api/change-password', { oldPassword, newPassword }, {
                headers : {
                    Authorization : Cookies.get(AUTH_COOKIE_NAME),
                },
            })
            .then(response => {
                Cookies.set(AUTH_COOKIE_NAME, response.data, { secure: true, sameSite: 'strict' });
            })
            .catch((error) => {
                console.log('error', error);
                throw error;
            });
    }

    logout() {
        Cookies.remove(AUTH_COOKIE_NAME);
    }

    getAuthorizationToken(): string | undefined {
        return Cookies.get(AUTH_COOKIE_NAME);
    }

    getRole(): Roles {
        return this.authorization.role;
    }
}
