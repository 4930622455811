import { Map } from "../../store/domain/Map";
import { ApiMap } from '../models/ApiMap';
import { ApiMapState } from '../models/ApiMapState';
import { MapState } from '../../store/mapStore/mapState';
import { ApiMarkerCategory } from '../models/ApiMarkerCategory';
import { GenericEvent } from '../../store/domain/genericEvents/genericMapStoreFunctions';


export function convertFromApiToMapState(apiMapState: ApiMapState): MapState {
    const mapState: MapState = new MapState();
    mapState.version = apiMapState.version;
    mapState.currentPlayersMap = apiMapState.currentPlayersMap;
    mapState.currentPlayersDate = apiMapState.currentPlayersDate;
    mapState.currentDate = apiMapState.currentPlayersDate;
    mapState.currentMap = apiMapState.currentPlayersMap;
    mapState.orderedDates = [...(new Set(apiMapState.orderedDates).values())];
    mapState.maps = Object.fromEntries(Object.entries(apiMapState.maps).map(([ key, map ]) => {
        return [key, new Map(
            map.id,
            map.name,
            map.url,
            map.width,
            map.height,
            map.isVisible,
            map.markerCategories.sort((markerCategoryA: ApiMarkerCategory, markerCategoryB: ApiMarkerCategory) => markerCategoryA.name.localeCompare(markerCategoryB.name)),
            map.history
                .sort((markerEventA: GenericEvent, markerEventB: GenericEvent) => markerEventA.date.localeCompare(markerEventB.date)),
            map.rumorsHistory,
            map.diplomaticRelationshipHistory,
        )];
    }));
    mapState.maxId = Math.max(...Object.values(apiMapState.maps)
        .map((apiMap: ApiMap): number[] => {
            return apiMap.history.map((apiMarkerEvent: GenericEvent): number => apiMarkerEvent.data.id);
        })
        .flat()
    );
    return mapState;
}
