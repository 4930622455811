import L from 'leaflet';

import MarkerTypes from './MarkerTypes';
import Iam from '../../iam/javascripts/iam_logic/iam';
import { Roles } from '../../iam/javascripts/Roles';
import { nextTick, createApp } from 'vue';
import MarkerIcon from './MarkerIcon.vue';
import { MarkerActions, MarkerGetters } from '../../store/domain/markers/MarkerEvents';
import { EventType } from '../../store/domain/genericEvents/genericMapStoreFunctions';
import { ApplicationActions } from '../../store/applicationState';

export default class LeafletMarkerContainer {
    get id() {
        return this._id;
    }

    get leafletMarker() {
        return this._leafletMarker;
    }

    get markerType() {
        return this._markerType;
    }

    constructor(marker, map, eventRouter, store) {
        try {
            this._store = store;
            this._eventRouter = eventRouter;
            this._marker = marker;
            this._id = marker.id;
            this._markerType = marker.type;
            this._markerLabel = marker.label;
            this._markerIsVisible = marker.isVisible;
            this._animationHandle = undefined;
            this._map = map;
            const markerTypes = new MarkerTypes();
            const markerZindex = markerTypes.markerMapZIndex[ marker.type ] * 1000;
            this._leafletMarker = new L.marker([ marker.lat, marker.lng ], { // eslint-disable-line new-cap
                zIndexOffset : markerZindex,
                riseOnHover  : true,
                riseOffset   : 1000000,
            }).addTo(map); // eslint-disable-line new-cap
            this._leafletMarker.bindTooltip(marker.label, { className: 'map-marker-tooltip' });

            this.createMarkerIcon();

            this._isMac = window.navigator.userAgent.indexOf('Mac') !== -1;
            this._addEventListeners();

            this._leafletMarker.on('dragend', () => this.propagateMarkerPosition());

            const recursiveKeepTooltipOpen = (remainingTimes) => {
                if (remainingTimes > 0
                    && this._store.state.applicationState.markerEditionMenuMarkerId === this._id
                    && this._store.state.applicationState.shouldDisplayMarkerEditionMenu) {
                    this._leafletMarker.openTooltip();
                    setTimeout(() => {
                        recursiveKeepTooltipOpen(remainingTimes - 1);
                    }, (5 - remainingTimes) * 150);
                }
            };
            const keepTooltipOpen = () => {
                // such a hack, but it works, and it's the only way I found to keep the tooltip open in the right position on map load
                // recursiveKeepTooltipOpen(5);
                if (this._store.state.applicationState.markerEditionMenuMarkerId === this._id
                    && this._store.state.applicationState.shouldDisplayMarkerEditionMenu) {
                    this._leafletMarker.openTooltip();
                }
            };

            this._leafletMarker.on('mouseover', () => {
                setTimeout(() => {
                    this._leafletMarker.closeTooltip();
                    setTimeout(() => {
                        this._leafletMarker.openTooltip();
                    }, 1);
                }, 1);
            });
            const onPageLoadTooltipOpen = () => {
                this._leafletMarker.off('tooltipopen', onPageLoadTooltipOpen);
                recursiveKeepTooltipOpen(5);
            };
            this._leafletMarker.on('tooltipopen', onPageLoadTooltipOpen);

            store.subscribeAction({
                after : (action, state) => {
                    if ((action.type === MarkerActions.CHANGE_MARKER && action.payload.id === this._id)
                        || (action.type === MarkerActions.EVENT_DELETE_MARKER
                            && action.payload.eventType === EventType.CHANGE_EVENT
                            && action.payload.id === this._id)) {
                        const marker = store.getters[ MarkerGetters.GET_A_MARKER ](this._id);
                        this.changeMarkerTooltip(marker.label);
                        this.changeIconVisibility(marker.isVisible);
                        this.changeMarkerPosition({ id: marker.id, lat: marker.lat, lng: marker.lng });
                    } else if (action.type === ApplicationActions.displayMarkerEditionMenu) {
                        if (action.payload === this._id) {
                            keepTooltipOpen();
                            this._leafletMarker.on('mouseout', keepTooltipOpen);
                        } else {
                            this._leafletMarker.closeTooltip();
                            this._leafletMarker.off('mouseout', keepTooltipOpen);
                        }
                    } else if (action.type === ApplicationActions.hideAllMenu) {
                        this._leafletMarker.closeTooltip();
                        this._leafletMarker.off('mouseout', keepTooltipOpen);
                    }
                },
            });
        } catch (e) {
            console.error(e);
        }
    }

    _highlightOnMap() {
        const existingIcon = document.getElementById(`map-icon-${this._id}`);
        if (existingIcon && !this._animationHandle) {
            existingIcon.querySelector('.for-animation').classList.add('animate');
            this._animationHandle = setTimeout(() => {
                this._animationHandle = undefined;
                existingIcon.querySelector('.for-animation').classList.remove('animate');
            }, 4500);
        }
    }

    createMarkerIcon() {
        const iconHtml = this.buildMarkerIconElement();
        const leafletIcon = L.divIcon({
            html     : iconHtml,
            iconSize : 1,
        });
        this._leafletMarker.setIcon(leafletIcon);
        this.changeIconVisibility(this._markerIsVisible);
    }

    buildMarkerIconElement() {
        const template = document.createElement('div');
        template.setAttribute('id', 'map-icon-' + this._id);
        nextTick(() => {
            this._app = createApp(MarkerIcon, {
                id            : this._id,
                leafletMarker : this._leafletMarker,
                map           : this._map,
            })
                .use(this._store)
                .mount('#map-icon-' + this._id);
        });
        return template;
    }

    _enableDragging(event) {
        const matchingKeyCode = this._isMac ? event.metaKey : event.keyCode === 17;
        if (matchingKeyCode && this._store.state.applicationState.editionModeEnabled && this._leafletMarker && this._leafletMarker.dragging) {
            this._leafletMarker.dragging.enable();
        }
    }
    _disableDragging(event) {
        const matchingKeyCode = this._isMac ? event.metaKey : event.keyCode === 17;
        if (matchingKeyCode && this._store.state.applicationState.editionModeEnabled && this._leafletMarker && this._leafletMarker.dragging) {
            this._leafletMarker.dragging.disable();
        }
    }

    _addEventListeners() {
        this._enableDraggingFunction = event => this._enableDragging(event);
        this._disableDraggingFunction = event => this._disableDragging(event);
        document.addEventListener('keydown', this._enableDraggingFunction);
        document.addEventListener('keyup', this._disableDraggingFunction);
    }

    removeEventListeners() {
        document.removeEventListener('keydown', this._enableDraggingFunction);
        document.removeEventListener('keyup', this._disableDraggingFunction);
    }

    propagateMarkerPosition() {
        this._store.dispatch(MarkerActions.CHANGE_MARKER, {
            id  : this._id,
            lat : this._leafletMarker._latlng.lat,
            lng : this._leafletMarker._latlng.lng,
        });
    }

    changeMarkerPosition({ id, lat, lng }) {
        if (id === this._id) {
            this._leafletMarker.setLatLng(new L.LatLng(lat, lng));
        }
    }

    async changeIconVisibility(isVisible) {
        this._markerIsVisible = isVisible;
        const isGm = await this._isGM();
        const hiddenClass = isGm ? 'gm-hidden' : 'hidden';
        const mapIconContainer = document.getElementById(`map-icon-${this._id}`);
        if (mapIconContainer && isVisible) {
            mapIconContainer.classList.remove(hiddenClass);
        } else if (mapIconContainer && !isVisible) {
            mapIconContainer.classList.add(hiddenClass);
        }
    }

    changeMarkerTooltip(label) {
        this._leafletMarker.bindTooltip(label);
    }

    turnHalfTransparent() {
        if (this._markerType !== 'players') {
            this._leafletMarker.setOpacity(0.4);
        }
    }

    turnOpaque() {
        if (this._markerType !== 'players') {
            this._leafletMarker.setOpacity(1);
        }
    }

    _isGM() {
        return Iam.getSession()
            .then(session => {
                if (session.getRole() === Roles.GAME_MASTER) {
                    return true;
                } else {
                    return false;
                }
            })
            .catch(() => {
                return false;
            });
    }

    remove() {
        this.removeEventListeners();
        this._leafletMarker.remove();
    }
}
