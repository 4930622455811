<template>
    <div class="editor-container" @click.stop>
        <div ref="editor" v-html="text">
        </div>
        
        <input
            type="file"
            style="display: none"
            ref="fileInputRef"
            @change="loadTextImage"
        />
    </div>
</template>

<script lang="ts">
import { computed, nextTick, onMounted, ref, Ref, SetupContext, toRef, watch } from 'vue';
import Quill from 'quill';
import Delta from 'quill-delta';
import { ApiMediaMetadata, getTemporaryUploadUrl } from '../../repository/get-temporary-upload-url';
import Toast from '../../javascripts/toasts/Toast';
import { uploadMedia } from '../../repository/upload-media';

export default {
    name: 'Editor',
    props: {
        modelValue: {
            type: String,
            default: ''
        },
        readonly: {
            type: Boolean,
            default: false,
        }
    },
    setup(props: Readonly<{ modelValue: string; readonly: boolean } & {}>, { emit }: SetupContext) {
        const editor = ref();
        const fileInputRef: Ref<HTMLElement | null> = ref(null);
        const imageUrl = ref('');

        function loadImage(event: Event): Promise<ApiMediaMetadata | undefined> {
            return new Promise((resolve, reject) => {
                const files = (<HTMLInputElement | null>event.target)?.files;
                if (files) {
                    let reader = new FileReader();
                    reader.onload = (theFile) => {
                        const rawResult: string = <string>theFile.target?.result ?? '';
                        new Toast('Uploading ...');
                        getTemporaryUploadUrl().then(async (apiMediaMetadata) => {
                            await uploadMedia(apiMediaMetadata, files[0]);
                            resolve(apiMediaMetadata);
                        });
                    };
                    reader.onabort = () => resolve(undefined);
                    reader.onerror = (error) => reject(error);
                    reader.readAsText(files[0]);
                }
            });
        }

        function loadTextImage(event: Event) {
            loadImage(event)
                .then((apiMediaMetadata: ApiMediaMetadata | undefined) => {
                    if (apiMediaMetadata) {
                        imageUrl.value = apiMediaMetadata.resourceAccessUrl;
                    }
                })
        }

        onMounted(() => {
            const quillEditor = new Quill(editor.value, {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        ['blockquote', 'link', 'image'],
                        [{ 'header': 1 }, { 'header': 2 }],
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        [{ 'align': [] }, 'clean'],
                    ],
                },
                theme: props.readonly ? 'bubble' : 'snow',
                readOnly: props.readonly,
            });

            function imageHandler() {
                fileInputRef.value?.click();
                const watchStopHandle = watch(imageUrl, () => {
                    var range = quillEditor.getSelection();
                    quillEditor.insertEmbed(range.index, 'image', imageUrl.value, Quill.sources.USER);
                    watchStopHandle();
                });
            }
            var toolbar = quillEditor.getModule('toolbar');
            toolbar.addHandler('image', imageHandler);

            quillEditor.on('text-change', (delta: any, oldDelta: any, source: any) => {
                emit('update:modelValue', quillEditor.getText() ? quillEditor.root.innerHTML : '');
            });
        })

        return {
            editor,
            fileInputRef,
            loadTextImage,
            text: props.modelValue,
        };
    },
}
</script>

<style lang="postcss" scoped>

.editor-container {
    width: 100%;
}

:deep() .ql-snow {
    font-family: "DejaVu Sans", sans-serif;
    font-size: 16px;
    line-height: 24px;
}

:deep() .ql-bubble {
    font-family: "DejaVu Sans", sans-serif;
    font-size: 16px;
    line-height: 24px;

    .ql-editor {
        padding: 0;
    }
}
</style>
