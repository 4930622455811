import { MapState } from "../../mapStore/mapState";
import { GenericData, GenericEventStoreFunctions } from "../genericEvents/genericMapStoreFunctions";

export type DiplomaticRelationshipDeleteType = {
    id?: number;
    label?: string;
}

export type DiplomaticRelationshipDiffType = {
    id: number;
    markerIds?: number[];
    nature?: DiplomaticRelationshipNature;
    isVisible?: boolean;
}

export enum DiplomaticRelationshipNature {
    ALLY = 'ALLY',
    ENEMY = 'ENEMY',
    NEUTRAL = 'NEUTRAL',
}

export class DiplomaticRelationshipData implements GenericData {
    id: number;
    markerIds: number[];
    nature: DiplomaticRelationshipNature;
    isVisible: boolean;

    constructor(
        id: number,
        markerIds: number[],
        nature: DiplomaticRelationshipNature,
    ) {
        this.id = id;
        this.markerIds = markerIds;
        this.nature = nature;
        this.isVisible = true;
    }
}

enum DiplomaticRelationshipCommits {
    ADD_DIPLOMATIC_RELATIONSHIP='ADD_DIPLOMATIC_RELATIONSHIP',
    DELETE_DIPLOMATIC_RELATIONSHIP='DELETE_DIPLOMATIC_RELATIONSHIP',
    EVENT_DELETE_DIPLOMATIC_RELATIONSHIP='EVENT_DELETE_DIPLOMATIC_RELATIONSHIP',
    CHANGE_DIPLOMATIC_RELATIONSHIP='CHANGE_DIPLOMATIC_RELATIONSHIP',
}

export enum DiplomaticRelationshipActions {
    ADD_DIPLOMATIC_RELATIONSHIP='ADD_DIPLOMATIC_RELATIONSHIP',
    DELETE_DIPLOMATIC_RELATIONSHIP='DELETE_DIPLOMATIC_RELATIONSHIP',
    EVENT_DELETE_DIPLOMATIC_RELATIONSHIP='EVENT_DELETE_DIPLOMATIC_RELATIONSHIP',
    CHANGE_DIPLOMATIC_RELATIONSHIP='CHANGE_DIPLOMATIC_RELATIONSHIP',
}

export enum DiplomaticRelationshipGetters {
    GET_A_DIPLOMATIC_RELATIONSHIP='GET_A_DIPLOMATIC_RELATIONSHIP',
    GET_ALL_DIPLOMATIC_RELATIONSHIP='GET_ALL_DIPLOMATIC_RELATIONSHIP',
    GET_EVENTS_OF_DIPLOMATIC_RELATIONSHIP='GET_EVENTS_OF_DIPLOMATIC_RELATIONSHIP',
    MAX_ID_OF_DIPLOMATIC_RELATIONSHIP='MAX_ID_OF_DIPLOMATIC_RELATIONSHIP',
}

export const diplomaticRelationshipEventStoreFunctions = new GenericEventStoreFunctions<DiplomaticRelationshipData>(
    'diplomaticRelationshipHistory',
    [
        DiplomaticRelationshipCommits.ADD_DIPLOMATIC_RELATIONSHIP,
        DiplomaticRelationshipCommits.DELETE_DIPLOMATIC_RELATIONSHIP,
        DiplomaticRelationshipCommits.EVENT_DELETE_DIPLOMATIC_RELATIONSHIP,
        DiplomaticRelationshipCommits.CHANGE_DIPLOMATIC_RELATIONSHIP,
    ],
    [
        DiplomaticRelationshipActions.ADD_DIPLOMATIC_RELATIONSHIP,
        DiplomaticRelationshipActions.DELETE_DIPLOMATIC_RELATIONSHIP,
        DiplomaticRelationshipActions.EVENT_DELETE_DIPLOMATIC_RELATIONSHIP,
        DiplomaticRelationshipActions.CHANGE_DIPLOMATIC_RELATIONSHIP,
    ],
    [
        DiplomaticRelationshipGetters.GET_A_DIPLOMATIC_RELATIONSHIP,
        DiplomaticRelationshipGetters.GET_ALL_DIPLOMATIC_RELATIONSHIP,
        DiplomaticRelationshipGetters.GET_EVENTS_OF_DIPLOMATIC_RELATIONSHIP,
        DiplomaticRelationshipGetters.MAX_ID_OF_DIPLOMATIC_RELATIONSHIP,
    ]);
