export enum MapCommits {
    changeMapIsVisible = "changeMapIsVisible",
    createMap = "createMap",
    deleteMap = "deleteMap",
    selectMap = "selectMap",

    selectEditionDate = 'selectEditionDate',
    selectAsPlayersDate = 'selectAsPlayersDate',
    createMarkerCategory = "createMarkerCategory",
    
    saveHandler = 'saveHandler',
    saveRequired = 'saveRequired',
    updateMapState = 'updateMapState',
    zoomOnMarker = 'zoomOnMarker',
}
