import L from 'leaflet';
import { watch } from 'vue';
import { ApplicationActions } from '../../store/applicationState';
import { RumorActions, RumorGetters } from '../../store/domain/rumors/RumorsEvents';

export default class LeafletRumorContainer {
    get id() {
        return this._id;
    }

    get leafletCircle() {
        return this._leafletCircle;
    }

    constructor(rumor, mapRef, store) {
        this._id = rumor.id;
        this._store = store;
        this._mapRef = mapRef;
        this._leafletCircle = new L.circle([ rumor.lat, rumor.lng ], { // eslint-disable-line new-cap
            fillColor   : rumor.isVisible ? '#dddd99' : '#cccccc',
            color       : '',
            fillOpacity : 0.45,
            radius      : rumor.radius,
            riseOnHover : true,
            riseOffset  : 1000000,
        }).addTo(mapRef.value);
        watch(store.state.mapState.maps[ store.state.mapState.currentMap ].rumorsHistory, () => {
            const rumorsUpdate = store.getters[ RumorGetters.GET_A_RUMOR ](rumor.id);
            this._leafletCircle.setRadius(rumorsUpdate.radius);
            this._leafletCircle.setStyle({
                fillColor : rumorsUpdate.isVisible ? '#dddd99' : '#cccccc',
            });
        }, { deep: true });
        this._leafletCircle.getElement().style[ 'filter' ] = 'blur(3px)';
        this._leafletCircle.getElement().classList.add('rumor-circle');
        this._leafletCircle.bindTooltip('Rumeurs et légendes');
        this._dragging = false;

        this._isMac = window.navigator.userAgent.indexOf('Mac') !== -1;

        this._addEventListeners();
    }

    _addEventListeners() {
        this._leafletCircle.on('click', () => {
            if (this._store.state.applicationState.shouldDisplayDistance) {

            } else {
                this._store.dispatch(ApplicationActions.displayRumorEditionMenu, this._id);
            }
        });
        const releaseCircle = (event) => {
            if (this._dragging) {
                this._dragging = false;
                this._mapRef.value.dragging.enable();
                window.removeEventListener('pointerup', releaseCircle);
                this._mapRef.value.removeEventListener('mousemove');
            }
        };
        this._leafletCircle.on('mousedown', () => {
            if (this._store.state.applicationState.editionModeEnabled && this._store.state.applicationState.draggingEnabled) {
                this._dragging = true;
                this._mapRef.value.dragging.disable();
                window.addEventListener('pointerup', releaseCircle);
                this._mapRef.value.on('mousemove', (event) => {
                    this._leafletCircle.setLatLng(event.latlng);
                    this._store.dispatch(RumorActions.CHANGE_RUMOR, { id: this._id, lat: event.latlng.lat, lng: event.latlng.lng });
                });
            }
        });
    }

    remove() {
        this._leafletCircle.remove();
    }
}
