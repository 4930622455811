import { captureException } from '@sentry/browser';
import axios from 'axios';
import Toast from '../javascripts/toasts/Toast';
import Iam from '../iam/javascripts/iam_logic/iam';
import { MapState } from '../store/mapStore/mapState';
import { convertMapStateToApi } from './converters/convertFromMapStateToApi';

declare const _API_URL_: boolean;

export function saveMapState(mapState: MapState) {
    const stateToSave = convertMapStateToApi(mapState);
    Iam.getSession()
            .then((session: Iam) => {
                new Toast('saving session ...');
                axios
                    .put(_API_URL_ + '/api/saves', stateToSave, {
                        headers : {
                            authorization : session.getAuthorizationToken(),
                        },
                    })
                    .then(() => {
                        new Toast('session saved');
                    })
                    .catch(error => {
                        new Toast(error.message || JSON.stringify(error));
                        console.log(error);
                        captureException(error);
                    });
            })
            .catch((error: Error) => {
                new Toast(error.message || JSON.stringify(error));
                console.log(error);
                captureException(error);
            });
}