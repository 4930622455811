
import { useStore, Store } from 'vuex';
import { RootState } from '../store/store';
import { ApplicationActions } from '../store/applicationState';
import { ref, Ref, toRef, computed, watch } from 'vue';
import L from 'leaflet';

export default {
    name: 'Distance',
    setup() {
        const store: Store<RootState> = useStore();

        const shouldDisplayDistance: Ref<boolean> = toRef(store.state.applicationState, 'shouldDisplayDistance');
        const distanceMarkers: Ref<{ lat: number, lng: number }[]> = toRef(store.state.applicationState, 'distanceMarkers');

        const distanceStep = computed(() => {
          if (distanceMarkers.value.length === 0) {
            return 'Cliquez sur le point de départ';
          } else if (distanceMarkers.value.length === 1) {
            return 'Cliquez sur le point d\'arrivée';
          } else if (distanceMarkers.value.length === 2) {
            const distance = L.CRS.Simple.distance(distanceMarkers.value[0], distanceMarkers.value[1]);

            let walkingDays = Math.round((distance * 5) / 170 * 2) / 2;
            let mountedDays = Math.round((distance * 5) / 307 * 2) / 2;
            let riverBoatDays = Math.round((distance * 1) / 108.5 * 2) / 2;
            let skyBoatDays = Math.round((distance * 1) / 347.5 * 2) / 2;

            if (walkingDays !== 0 || mountedDays !== 0 || riverBoatDays !== 0 || skyBoatDays !== 0) {
              walkingDays = walkingDays < 0.5 ? 0.5 : walkingDays;
              mountedDays = mountedDays < 0.5 ? 0.5 : mountedDays;
              riverBoatDays = riverBoatDays < 0.5 ? 0.5 : riverBoatDays;
              skyBoatDays = skyBoatDays < 0.5 ? 0.5 : skyBoatDays;
            }

            return `${walkingDays} jour${walkingDays > 1 ? 's' : ''} de marche
                    <br>${mountedDays} jour${mountedDays > 1 ? 's' : ''} de cheval
                    <br>${riverBoatDays} jour${riverBoatDays > 1 ? 's' : ''} de bateau
                    <br>${skyBoatDays} jour${skyBoatDays > 1 ? 's' : ''} de navire du ciel`;
          }
        });

        return {
          distanceStep,
          shouldDisplayDistance,
        }
    },
}
