<template>
  <div v-if="shouldDisplaySelectMap" @click.stop>
    <div class="select-map-menu">
      <h3>Cartes Earthdawn</h3>
      <div v-if="shouldDisplayEditionArea" class="edition-area">
        <div class="map-section">
          <EditableSelect
            :currentItem="currentMap"
            :items="maps"
            :editionDisabled="!editionModeActivated"
            @newItem="startNewMap"
            @selectedItem="selectMap"
            class="map-list"
          />
          <div class="edition-option"><label><input type="checkbox" :disabled="!editionModeActivated" v-model="isVisible">Privée</label></div>
          <div class="edition-option" v-if="editionModeActivated"><a class="delete-button" @click="showDeleteModal">Supprimer</a></div>
          <ConfirmationModal
            v-show="shouldDisplayDeleteModal"
            :message="'Supprimer la carte ?'"
            :okText="'Supprimer'"
            :cancelText="'Annuler'"
            @cancel="hideDeleteModal"
            @ok="deleteMap"
          />
        </div>
        <div class="edition-option markers-categories-section">
          <div class="categories">
            <div class="marker-category" @click="startNewMarker">
              <span class="new-marker-category">+</span>
              <span class="label">Nouvelle catégorie de marqueur</span>
            </div>
            <div class="marker-category" v-for="markerCategory in markerCategories" :key="markerCategory.id" @click="editMarker(markerCategory.id)">
              <span class="marker-category-icon">
                <img class="raw-thumbnail" :src="markerCategory.url" :width="20" :height="20" />
              </span>
              <span class="label" v-html="markerCategory.name"></span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="shouldDisplayMarkerCreationArea" class="creation-area">
          Aperçu (cliquer pour changer)
        <div :style="{ 'width': chosenMarkerWidth + 'px', 'height': chosenMarkerHeight + 'px', position: 'relative' }" @click="editImage">
          <img class="raw-thumbnail" :src="markerGetUrl" :width="chosenMarkerWidth" :height="chosenMarkerHeight" />
          <div class="center" :style="{ 'top': `calc(${chosenMarkerCenterY}% - 3px)`, 'left': `calc(${chosenMarkerCenterX}% - 3px)` }"></div>
        </div>
        Nom de catégorie
        <input type="text" v-model="chosenMarkerName" />
        Largeur x Hauteur (px, par défaut à 20px * 20px)
        <div><input type="number" v-model="chosenMarkerWidth" /><input type="number" v-model="chosenMarkerHeight" /></div>
        Centre (%, par défaut à 50% de la largeur, 50% de la hauteur)
        <div><input type="number" v-model="chosenMarkerCenterX" /><input type="number" v-model="chosenMarkerCenterY" /></div>
        <a class="creation-button" @click="createMarker">Confirmer</a>
        <a class="creation-button" @click="cancelMarker">Annuler</a>
      </div>
      <div v-if="shouldDisplayMapCreationArea" class="creation-area">
          Aperçu
        <img class="thumbnail" :src="mapGetUrl" />
        Nom
        <input type="text" v-model="chosenMapName" />
        <a class="creation-button" @click="createMap">Confirmer</a>
        <a class="creation-button" @click="cancelMap">Annuler</a>
      </div>
    </div>
    <input
      type="file"
      style="display: none"
      ref="editMarkerFileInputRef"
      @change="editMarkerLoadImage"
    />
    <input
      type="file"
      style="display: none"
      ref="markerFileInputRef"
      @change="markerLoadImage"
    />
    <input
      type="file"
      style="display: none"
      ref="mapFileInputRef"
      @change="mapLoadImage"
    />
  </div>
</template>

<script lang="ts">
import { computed, nextTick, reactive, ref, Ref, toRef, watch } from "vue";
import { useStore, Store } from "vuex";
import { RootState } from "../store/store";
import { ApplicationActions } from "../store/applicationState";
import Toast from "../javascripts/toasts/Toast.js";
import { MapActions } from "../store/mapStore/MapActions";
import { MapGetters } from "../store/mapStore/MapGetters";
import { Map } from "../store/domain/Map";
import EditableSelect from "./graphics/EditableSelect.vue";
import { ApiMediaMetadata, getTemporaryUploadUrl } from "./../repository/get-temporary-upload-url";
import { uploadMedia } from "./../repository/upload-media";
import { Roles } from '../iam/javascripts/Roles';
import { marker } from 'leaflet';
import { MarkerCategory } from '../store/domain/MarkerCategory';
import ConfirmationModal from './graphics/ConfirmationModal.vue';
import { Router, useRouter } from 'vue-router';

type InputMap = { name: string; id: string };

export default {
  components: { EditableSelect, ConfirmationModal },
  name: "SelectMap",
  setup() {
    const store: Store<RootState> = useStore();
    const router: Router = useRouter();

    const maps: Ref<Map[]> = toRef(store.getters, MapGetters.getMaps);
    const markerCategories = toRef(store.getters, MapGetters.getMarkerCategories);
    const currentMap: Ref<Map> = toRef(store.getters, MapGetters.getCurrentMap);
    const editionModeActivated = toRef(store.state.applicationState, 'editionModeEnabled');
    const shouldDisplaySelectMap: Ref<boolean> = toRef(
      store.state.applicationState,
      "shouldDisplaySelectMap"
    );

    const mapFileInputRef: Ref<HTMLElement | null> = ref(null);
    const markerFileInputRef: Ref<HTMLElement | null> = ref(null);
    const editMarkerFileInputRef: Ref<HTMLElement | null> = ref(null);

    const markerCategoryId: Ref<string> = ref("");
    const chosenMarkerName: Ref<string> = ref("");
    const chosenMarkerWidth: Ref<number> = ref(20);
    const chosenMarkerHeight: Ref<number> = ref(20);
    const chosenMarkerCenterX: Ref<number> = ref(50);
    const chosenMarkerCenterY: Ref<number> = ref(50);
    const markerGetUrl: Ref<string> = ref("");
    const shouldDisplayMarkerCreationArea: Ref<boolean> = ref(false);

    const chosenMapName: Ref<string> = ref("");
    const mapGetUrl: Ref<string> = ref("");
    const shouldDisplayDeleteModal: Ref<boolean> = ref(false);
    const shouldDisplayMapCreationArea: Ref<boolean> = ref(false);
    const shouldDisplayEditionArea: Ref<boolean> = computed(() => {
      return !shouldDisplayMapCreationArea.value
        && !shouldDisplayMarkerCreationArea.value
        && store.state.applicationState.role === Roles.GAME_MASTER;
    });
    const isVisible = computed({
      get: () => {
        return !currentMap.value.isVisible;
      },
      set: (isVisible: boolean) => {
        store.dispatch(MapActions.changeMapIsVisible, { id: currentMap.value.id, isVisible: !isVisible });
      },
    });

    function startNewMap(newMap: InputMap) {
      chosenMapName.value = newMap.name;
      mapFileInputRef.value?.click();
    }

    function startNewMarker() {
      chosenMarkerName.value = "Nouvelle catégorie";
      chosenMarkerWidth.value = 20;
      chosenMarkerHeight.value = 20;
      chosenMarkerCenterX.value = 50;
      chosenMarkerCenterY.value = 50;
      markerCategoryId.value = "";
      markerGetUrl.value = "";
      markerFileInputRef.value?.click();
    }

    function loadImage(event: Event): Promise<ApiMediaMetadata | undefined> {
        return new Promise((resolve, reject) => {
            const files = (<HTMLInputElement | null>event.target)?.files;
            if (files) {
                let reader = new FileReader();
                reader.onload = (theFile) => {
                    const rawResult: string = <string>theFile.target?.result ?? '';
                    new Toast('Uploading ...');
                    getTemporaryUploadUrl().then(async (apiMediaMetadata) => {
                        await uploadMedia(apiMediaMetadata, files[0]);
                        resolve(apiMediaMetadata);
                    });
                };
                reader.onabort = () => resolve(undefined);
                reader.onerror = (error) => reject(error);
                reader.readAsText(files[0]);
            }
        });
    }

    function mapLoadImage(event: Event) {
      loadImage(event)
        .then((apiMediaMetadata: ApiMediaMetadata | undefined) => {
          if (apiMediaMetadata) {
            mapGetUrl.value = apiMediaMetadata.resourceAccessUrl;
            shouldDisplayMapCreationArea.value = true;
          }
        });
    }

    function markerLoadImage(event: Event) {
      loadImage(event)
        .then((apiMediaMetadata: ApiMediaMetadata | undefined) => {
          if (apiMediaMetadata) {
            markerGetUrl.value = apiMediaMetadata.resourceAccessUrl;
            shouldDisplayMarkerCreationArea.value = true;
          }
        });
    }

    function editMarker(id: string) {
      const markerCategory = store.getters[MapGetters.getMarkerCategories].filter((markerCategory: MarkerCategory) => markerCategory.id === id)[0];
      chosenMarkerName.value = markerCategory.name;
      chosenMarkerWidth.value = markerCategory.width;
      chosenMarkerHeight.value = markerCategory.height;
      chosenMarkerCenterX.value = markerCategory.centerX;
      chosenMarkerCenterY.value = markerCategory.centerY;
      markerCategoryId.value = markerCategory.id;
      markerGetUrl.value = markerCategory.url;
      shouldDisplayMarkerCreationArea.value = true;
    }

    function editImage() {
      editMarkerFileInputRef.value?.click();
    }

    function editMarkerLoadImage(event: Event) {
      loadImage(event)
        .then((apiMediaMetadata: ApiMediaMetadata | undefined) => {
          if (apiMediaMetadata) {
            markerGetUrl.value = apiMediaMetadata.resourceAccessUrl;
          }
        });
    }

    function createMap() {
        shouldDisplayMapCreationArea.value = false;
        const img = new Image();
        img.onload = (event) => {
            const mapWidth = (<HTMLImageElement>(event?.target))?.width;
            const mapHeight = (<HTMLImageElement>(event?.target))?.height;
            store.dispatch(MapActions.createMap, { name: chosenMapName.value, url: mapGetUrl.value, width: mapWidth, height: mapHeight });
        }
        img.src = mapGetUrl.value;
    }

    function cancelMap() {
        shouldDisplayMapCreationArea.value = false;
    }

    function createMarker() {
        shouldDisplayMarkerCreationArea.value = false;
        store.dispatch(
          MapActions.createMarkerCategory, {
            id: markerCategoryId.value,
            name: chosenMarkerName.value,
            url: markerGetUrl.value,
            width: chosenMarkerWidth.value,
            height: chosenMarkerHeight.value,
            centerX: chosenMarkerCenterX.value,
            centerY: chosenMarkerCenterY.value,
          })
    }

    function cancelMarker() {
        shouldDisplayMarkerCreationArea.value = false;
    }

    function selectMap(map: InputMap) {
        store.dispatch(MapActions.selectMap, map);
        store.dispatch(ApplicationActions.markRouteChangeAsProgramatic);
        router.replace({ query: { currentMap: map.id } });
    }

    function showDeleteModal() {
      shouldDisplayDeleteModal.value = true;
    }

    function hideDeleteModal() {
      shouldDisplayDeleteModal.value = false;
    }

    function deleteMap() {
      store.dispatch(MapActions.deleteMap);
      hideDeleteModal();
    }

    return {
      cancelMap,
      cancelMarker,
      chosenMapName,
      chosenMarkerName,
      chosenMarkerWidth,
      chosenMarkerHeight,
      chosenMarkerCenterX,
      chosenMarkerCenterY,
      createMap,
      createMarker,
      currentMap,
      deleteMap,
      editionModeActivated,
      editImage,
      editMarker,
      editMarkerFileInputRef,
      editMarkerLoadImage,
      hideDeleteModal,
      mapFileInputRef,
      markerCategories,
      markerFileInputRef,
      mapGetUrl,
      markerGetUrl,
      isVisible,
      mapLoadImage,
      markerLoadImage,
      maps,
      selectMap,
      showDeleteModal,
      shouldDisplayDeleteModal,
      shouldDisplayEditionArea,
      shouldDisplayMapCreationArea,
      shouldDisplayMarkerCreationArea,
      shouldDisplaySelectMap,
      startNewMap,
      startNewMarker,
    };
  },
};
</script>

<style lang="postcss" scoped>
$side-nav-length: 250px;

.select-map-menu {
  position: absolute;
  top: 0;
  left: 0;
  right: auto;
  z-index: 10000;
  height: 100vh;
  width: $side-nav-length;
  background-color: whitesmoke;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  resize: horizontal;
  transition: all 0.2s ease;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  padding: 5px;

  h3 {
    margin: 10px;
    color: #424242;
  }

  .map-list {
    width: 200px;
  }

  .creation-area {
      display: flex;
      flex-direction: column;

      margin-top: 20px;

      padding: 10px;

      background-color: lightgray;
      border-radius: 7px;

      .raw-thumbnail {
          margin-bottom: 10px;
          background-color: white;
      }
      .thumbnail {
          margin-bottom: 10px;
          background-color: white;
          width: 200px;
          border: 1px solid lightgrey;
      }
      .center {
          background-color: yellow;
          border: 2px solid black;
          width: 2px;
          height: 2px;
          position: absolute;
          border-radius: 2px;
      }
      .creation-button {
          margin-top: 10px;
          padding: 5px;
          border: 1px solid black;
          text-align: center;
          background-color: white;
          border-radius: 7px;
          cursor: pointer;
      }
  }
  
  .edition-area {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 15px;
    width: 100%;

    .map-section {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      width: 100%;
    
      .map-list {
        width: 100%;
      }

      .delete-button {
        font-size: 11px;
        color: lightcoral;
        text-decoration: underline;
      }
    }

    .markers-categories-section {
      margin-top: 30px;
    }

    .edition-option {
      padding: 5px;
      display: block;

      .categories {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .marker-category {
          display: grid;
          grid-template-columns: 30px 1fr;
          align-items: center;
          width: 100%;
          padding: 5px 3px;

          border-top: 1px solid lightgrey;

          &:last-child {
            border-bottom: 1px solid lightgrey;
          }

          &:hover {
            filter: brightness(0.9);
            background-color: rgba(255, 59, 59, 0.33);
            transition: background-color 0.2s ease;
          }

          .new-marker-category {
            width: 20px;
            display: flex;
            justify-content: center;
          }

          .new-marker-category,
          .marker-category-icon,
          .label {
            grid-column: auto;
          }

          .marker-category-icon {
            .raw-thumbnail {
              max-width: 20px;
              max-height: 20px;
            }
          }
        }
      }
    }
  }
}
</style>