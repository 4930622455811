'use strict';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '../statics/stylesheets/main.pcss';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import 'leaflet_css';
import 'leaflet_layers';
import * as Sentry from '@sentry/browser';
import { RewriteFrames } from '@sentry/integrations';
import Iam from '../iam/javascripts/iam_logic/iam';
import Root from '../gui/Root.vue';
import { createApp, nextTick, watch } from 'vue';
import { getStore } from '../store/store';
import { Roles } from '../iam/javascripts/Roles';
import { createRouter, createWebHashHistory } from 'vue-router';
import { ApplicationActions } from '../store/applicationState';
import { MapActions } from '../store/mapStore/MapActions';
import { MapGetters } from '../store/mapStore/MapGetters';

const enabled = window.location.hostname.indexOf('localhost') === -1;
Sentry.init({
    dsn          : 'https://4dcc97f1d1ec4cb9ba3f0bd89a0bdb8d@sentry.io/1331038',
    enabled,
    release      : 'v0',
    integrations : [
        new RewriteFrames({
            root : global.__rootdir__,
        }),
    ],
});

function updateUiFromUrl(router, store) {
    const currentMap = router.currentRoute.value.query.currentMap;
    if (!currentMap) {
        router.replace({ query: { currentMap: store.getters[ MapGetters.getCurrentMap ].id } });
        return;
    }
    let selectMapUnSub;
    selectMapUnSub = store.subscribeAction({
        after : (action, state) => {
            if (action.type !== MapActions.selectMap) {
                return;
            }
            selectMapUnSub();
            const markerId = router.currentRoute.value.query.markerId;
            const rumorsId = router.currentRoute.value.query.rumorsId;
            if (markerId) {
                nextTick(() => {
                    store.dispatch(ApplicationActions.displayMarkerEditionMenu, parseInt(markerId));
                });
            } else if (rumorsId) {
                nextTick(() => {
                    store.dispatch(ApplicationActions.displayRumorEditionMenu, parseInt(rumorsId));
                });
            } else {
                store.dispatch(ApplicationActions.hideAllMenu);
            }
        },
    });
    nextTick(() => {
        if (currentMap) {
            store.dispatch(MapActions.selectMap, { id: currentMap });
        }
    });
}

async function logErrorsToSentry(func) {
    try {
        return await func();
    } catch (err) {
        console.log(err);
        Sentry.captureException(err);
    }
}

async function loadApplication(isLoggedIn, role) {
    const routes = [
        { path: '/', component: Root, props: { isLoggedIn, role } },
    ];
    const router = createRouter({
        history : createWebHashHistory(),
        routes,
    });
    const store = getStore();
    let loadMarkersUnSub;
    loadMarkersUnSub = store.subscribeAction({
        after : (action, state) => {
            if (action.type !== MapActions.loadMarkers) {
                return;
            }
            loadMarkersUnSub();
            loadMarkersUnSub = undefined;
            updateUiFromUrl(router, store);
        },
    });
    watch(router.currentRoute, () => {
        if (loadMarkersUnSub) {
            return;
        }
        if (!store.state.applicationState.isProgramaticRouteChange) {
            updateUiFromUrl(router, store);
        } else {
            store.dispatch(ApplicationActions.unmarkRouteChangeAsProgramatic);
        }
    });
    isLoggedIn = true;
    role = Roles.GAME_MASTER;
    createApp(Root, { isLoggedIn, role })
        .use(store)
        .use(router)
        .mount('#vue-root');
}

try {
    console.log('start on api server', _API_URL_ + '/');
    document.getElementById('progressive-display').style.display = 'none';
    Iam.getSession()
        .then(async(session) => {
            return logErrorsToSentry(() => loadApplication(true, session.getRole()));
        })
        .catch(async() => {
            return logErrorsToSentry(() => loadApplication(false, Roles.PLAYER));
        });
} catch (err) {
    console.log(err);
    Sentry.captureException(err);
}
