export class MarkerCategory {
    id: string;
    name: string;
    url: string;
    width: number;
    height: number;
    centerX: number;
    centerY: number;

    constructor(id: string, name: string, url: string, width: number, height: number, centerX: number, centerY: number) {
        this.id = id;
        this.name = name;
        this.url = url;
        this.width = width;
        this.height = height;
        this.centerX = centerX;
        this.centerY = centerY;
    }
}
