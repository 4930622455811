
import { computed, markRaw, nextTick, onBeforeUnmount, onDeactivated, onMounted, onUnmounted, ref, Ref, shallowRef, toRaw, toRef, unref, watch, watchEffect } from 'vue';
import { useStore, Store } from 'vuex';
import * as showdown from 'showdown';
import { RootState } from '../store/store';
import { ApplicationActions } from '../store/applicationState';
import { MapGetters } from "../store/mapStore/MapGetters";
import { Map } from "../store/domain/Map";
import { Marker } from "../store/domain/Marker";
import { MapState } from '../store/mapStore/mapState';
import { MarkerType } from '../store/domain/MarkerType';
import Editor from './graphics/Editor.vue';
import { Roles } from '../iam/javascripts/Roles';
import { MarkerActions, MarkerDiffType, MarkerGetters } from '../store/domain/markers/MarkerEvents';
import { DiplomaticRelationshipActions, DiplomaticRelationshipData, DiplomaticRelationshipGetters } from '../store/domain/diplomaticRelationship/diplomaticRelationshipEvents';
import { RouteLocationNormalized, Router, useRoute, useRouter } from 'vue-router';

export default {
    name: 'MarkerEdition',
    components: {
        Editor,
    },
    setup() {
        const store: Store<RootState> = useStore();
        const router: Router = useRouter();

        let editionArea: Ref<any> = ref(null);
        const editingText: Ref<boolean> = ref(false);
        const markerCategories = toRef(store.getters, MapGetters.getMarkerCategories);
        const editionModeEnabled: Ref<boolean> = toRef(store.state.applicationState, 'editionModeEnabled');
        const markerEditionMenuMarkerId: Ref<number> = toRef(store.state.applicationState, 'markerEditionMenuMarkerId');
        const shouldDisplayMarkerEditionMenu: Ref<boolean> = toRef(store.state.applicationState, 'shouldDisplayMarkerEditionMenu');
        const isGameMaster = computed(() => store.state.applicationState.role === Roles.GAME_MASTER );
        
        watch([ markerEditionMenuMarkerId, shouldDisplayMarkerEditionMenu ], () => {
            if (shouldDisplayMarkerEditionMenu.value) {
                store.dispatch(ApplicationActions.markRouteChangeAsProgramatic);
                router.replace({ query: { currentMap: router.currentRoute.value.query.currentMap, markerId: markerEditionMenuMarkerId.value.toString() } });
            }
        });

        const markerText = computed({
            get: () => {
                const marker = store.getters[MarkerGetters.GET_A_MARKER](markerEditionMenuMarkerId.value, true);
                return marker?.text;
            },
            set: (text) => {
                store.dispatch(MarkerActions.CHANGE_MARKER, { id: markerEditionMenuMarkerId.value, text });
            },
        });
        const markerLabel = computed({
            get: () => {
                const marker = store.getters[MarkerGetters.GET_A_MARKER](markerEditionMenuMarkerId.value);
                return marker?.label;
            },
            set: (label) => {
                store.dispatch(MarkerActions.CHANGE_MARKER, { id: markerEditionMenuMarkerId.value, label });
            },
        });
        const markerIsVisited = computed({
            get: () => {
                const marker = store.getters[MarkerGetters.GET_A_MARKER](markerEditionMenuMarkerId.value);
                return marker?.isVisited;
            },
            set: (isVisited) => {
                store.dispatch(MarkerActions.CHANGE_MARKER, { id: markerEditionMenuMarkerId.value, isVisited });
            },
        });
        const markerIsVisible = computed({
            get: () => {
                const marker = store.getters[MarkerGetters.GET_A_MARKER](markerEditionMenuMarkerId.value);
                return marker?.isVisible;
            },
            set: (isVisible) => {
                store.dispatch(MarkerActions.CHANGE_MARKER, { id: markerEditionMenuMarkerId.value, isVisible });
            },
        });
        const markerYouAreHere = computed({
            get: () => {
                const marker = store.getters[MarkerGetters.GET_A_MARKER](markerEditionMenuMarkerId.value);
                return marker?.youAreHere;
            },
            set: (youAreHere) => {
                store.dispatch(MarkerActions.CHANGE_MARKER, { id: markerEditionMenuMarkerId.value, youAreHere });
            },
        });
        const markerType = computed({
            get: () => {
                const marker = store.getters[MarkerGetters.GET_A_MARKER](markerEditionMenuMarkerId.value);
                return marker?.type;
            },
            set: (type) => {
                store.dispatch(MarkerActions.CHANGE_MARKER, { id: markerEditionMenuMarkerId.value, type });
            },
        });
        const markerOrientation = computed({
            get: () => {
                const marker = store.getters[MarkerGetters.GET_A_MARKER](markerEditionMenuMarkerId.value);
                return marker?.orientation;
            },
            set: (orientation) => {
                store.dispatch(MarkerActions.CHANGE_MARKER, { id: markerEditionMenuMarkerId.value, orientation: parseInt(orientation) });
            },
        });
        function deleteMarker() {
            store.getters[DiplomaticRelationshipGetters.GET_ALL_DIPLOMATIC_RELATIONSHIP]
                .filter((relationship: DiplomaticRelationshipData ) => {
                    return relationship.markerIds.indexOf(markerEditionMenuMarkerId.value) !== -1;
                })
                .forEach((relationship: DiplomaticRelationshipData ) => {
                    store.dispatch(DiplomaticRelationshipActions.DELETE_DIPLOMATIC_RELATIONSHIP, { id: relationship.id, label: markerLabel.value });        
                });
            store.dispatch(MarkerActions.DELETE_MARKER, { id: markerEditionMenuMarkerId.value, label: markerLabel.value });
            store.dispatch(ApplicationActions.hideAllMenu);
        }

        return {
            deleteMarker,
            editingText,
            editionArea,
            editionModeEnabled,
            isGameMaster,
            markerLabel,
            markerEditionMenuMarkerId,
            markerIsVisible,
            markerIsVisited,
            markerOrientation,
            markerText,
            markerType,
            markerTypes: [...Object.values(MarkerType)].filter(markerType => markerType !== MarkerType.players),
            markerYouAreHere,
            markerCategories,
            shouldDisplayMarkerEditionMenu,
        }
    },
}
