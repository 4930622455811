import { GenericData, GenericEventStoreFunctions } from "../genericEvents/genericMapStoreFunctions";

export type RumorsDeleteType = {
    id?: number;
}

export type RumorDiffType = {
    id: number;
    label?: string;
    text?: string;
    isVisible?: boolean;
}

export type RumorsDiffType = {
    id: number;
    lat?: number;
    lng?: number;
    isVisible?: boolean;
    radius?: number;
    rumorDataList?: RumorDiffType[];
}

export class RumorData {
    id: number;
    label: string;
    text: string;
    isVisible: boolean;

    constructor(
        id: number,
        label: string,
        text: string,
        isVisible: boolean,
    ) {
        this.id = id;
        this.label = label;
        this.text = text;
        this.isVisible = isVisible;
    }
}

export class RumorsData implements GenericData {
    id: number;
    lat: number;
    lng: number;
    isVisible: boolean;
    radius: number;
    rumorDataList: RumorData[];

    constructor(
        id: number,
        lat: number,
        lng: number,
        isVisible: boolean,
        radius: number,
        rumorDataList: RumorData[],
    ) {
        this.id = id;
        this.lat = lat;
        this.lng = lng;
        this.isVisible = isVisible;
        this.radius = radius;
        this.rumorDataList = rumorDataList;
    }
}

enum RumorCommits {
    ADD_RUMOR='ADD_RUMOR',
    DELETE_RUMOR='DELETE_RUMOR',
    EVENT_DELETE_RUMOR='EVENT_DELETE_RUMOR',
    CHANGE_RUMOR='CHANGE_RUMOR',
}

export enum RumorActions {
    ADD_RUMOR='ADD_RUMOR',
    DELETE_RUMOR='DELETE_RUMOR',
    EVENT_DELETE_RUMOR='EVENT_DELETE_RUMOR',
    CHANGE_RUMOR='CHANGE_RUMOR',
}

export enum RumorGetters {
    GET_A_RUMOR='GET_A_RUMOR',
    GET_ALL_RUMORS='GET_ALL_RUMORS',
    GET_EVENTS_OF_RUMORS='GET_EVENTS_OF_RUMORS',
    MAX_ID_OF_RUMORS='MAX_ID_OF_RUMORS',
}

export const rumorsEventStoreFunctions = new GenericEventStoreFunctions<RumorsData>(
    'rumorsHistory',
    [
        RumorCommits.ADD_RUMOR,
        RumorCommits.DELETE_RUMOR,
        RumorCommits.EVENT_DELETE_RUMOR,
        RumorCommits.CHANGE_RUMOR,
    ],
    [
        RumorActions.ADD_RUMOR,
        RumorActions.DELETE_RUMOR,
        RumorActions.EVENT_DELETE_RUMOR,
        RumorActions.CHANGE_RUMOR,
    ],
    [
        RumorGetters.GET_A_RUMOR,
        RumorGetters.GET_ALL_RUMORS,
        RumorGetters.GET_EVENTS_OF_RUMORS,
        RumorGetters.MAX_ID_OF_RUMORS,
    ]);
