<template>
    <div v-if="shouldDisplayDistance" @click.stop class="distance">
      <div class="dropdown" v-html="distanceStep">
      </div>
    </div>
</template>

<script lang="ts">
import { useStore, Store } from 'vuex';
import { RootState } from '../store/store';
import { ApplicationActions } from '../store/applicationState';
import { ref, Ref, toRef, computed, watch } from 'vue';
import L from 'leaflet';

export default {
    name: 'Distance',
    setup() {
        const store: Store<RootState> = useStore();

        const shouldDisplayDistance: Ref<boolean> = toRef(store.state.applicationState, 'shouldDisplayDistance');
        const distanceMarkers: Ref<{ lat: number, lng: number }[]> = toRef(store.state.applicationState, 'distanceMarkers');

        const distanceStep = computed(() => {
          if (distanceMarkers.value.length === 0) {
            return 'Cliquez sur le point de départ';
          } else if (distanceMarkers.value.length === 1) {
            return 'Cliquez sur le point d\'arrivée';
          } else if (distanceMarkers.value.length === 2) {
            const distance = L.CRS.Simple.distance(distanceMarkers.value[0], distanceMarkers.value[1]);

            let walkingDays = Math.round((distance * 5) / 170 * 2) / 2;
            let mountedDays = Math.round((distance * 5) / 307 * 2) / 2;
            let riverBoatDays = Math.round((distance * 1) / 108.5 * 2) / 2;
            let skyBoatDays = Math.round((distance * 1) / 347.5 * 2) / 2;

            if (walkingDays !== 0 || mountedDays !== 0 || riverBoatDays !== 0 || skyBoatDays !== 0) {
              walkingDays = walkingDays < 0.5 ? 0.5 : walkingDays;
              mountedDays = mountedDays < 0.5 ? 0.5 : mountedDays;
              riverBoatDays = riverBoatDays < 0.5 ? 0.5 : riverBoatDays;
              skyBoatDays = skyBoatDays < 0.5 ? 0.5 : skyBoatDays;
            }

            return `${walkingDays} jour${walkingDays > 1 ? 's' : ''} de marche
                    <br>${mountedDays} jour${mountedDays > 1 ? 's' : ''} de cheval
                    <br>${riverBoatDays} jour${riverBoatDays > 1 ? 's' : ''} de bateau
                    <br>${skyBoatDays} jour${skyBoatDays > 1 ? 's' : ''} de navire du ciel`;
          }
        });

        return {
          distanceStep,
          shouldDisplayDistance,
        }
    },
}
</script>

<style lang="postcss" scoped>
.distance {
  position: absolute;
  top:0;
  left: 0;
  right: 0;
  z-index: 10000;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .dropdown {
    padding: 5px 20px 9px;

    line-height: 150%;
    font-size: 14px;
    color: #353535;

    border-radius: 0 0 4px 4px;
    background-color: white;

    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);

    text-align: center;
  }
}
</style>