<template>
    <div class="some-class" @click="hideEventMenu">
        <Distance />
        <EventMenu />
        <MarkerEdition />
        <ExplorationMenu />
        <Account />
        <RumorEdition />
        <SaveLoad />
        <SelectMap />
        <div id="root">
            <div id="ui">
                <div id="map-container" class="map-container-without-toolbar">
                    <div id='map'></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Distance from './Distance.vue';
import EventMenu from './EventMenu.vue';
import MarkerEdition from './MarkerEdition.vue';
import ExplorationMenu from './ExplorationMenu.vue';
import Account from './Account.vue';
import RumorEdition from './RumorEdition.vue';
import SaveLoad from './SaveLoad.vue';
import SelectMap from './SelectMap.vue';
import { useStore, Store } from 'vuex';
import { RootState } from '../store/store';
import { ApplicationActions } from '../store/applicationState';
import { MapActions } from "../store/mapStore/MapActions";
import { computed, nextTick, onBeforeMount, onMounted, PropType, Ref, ref, toRef, watch } from 'vue';
import Map from '../javascripts/leaflet/Map';
import YouAreHereControl from '../javascripts/leaflet/controls/YouAreHereControl';
import VisitedControl from '../javascripts/leaflet/controls/VisitedControl';
import DistanceControl from '../javascripts/leaflet/controls/DistanceControl';
import ExplorationMenuControl from '../javascripts/leaflet/controls/explorationMenuControl';
import IamMenuControl from '../javascripts/leaflet/controls/iamMenuControl';
import EventsMenuControl from '../javascripts/leaflet/controls/eventsMenuControl';
import RumorsToggleControl from '../javascripts/leaflet/controls/rumorsToggle';
import DiplomaticRelationshipToggleControl from '../javascripts/leaflet/controls/diplomaticRelationshipToggle';
import FileSaveLoadControl from '../javascripts/leaflet/controls/fileSaveLoadControl';
import SelectMapControl from '../javascripts/leaflet/controls/selectMapControl';
import EditableSwitchControl from '../javascripts/leaflet/controls/EditableSwitchControl';
import { Roles } from '../iam/javascripts/Roles';
import { RouteLocationNormalized, useRoute, useRouter } from 'vue-router';

export default {
    name: 'Root',
    components: {
        Distance,
        EventMenu,
        MarkerEdition,
        ExplorationMenu,
        Account,
        RumorEdition,
        SaveLoad,
        SelectMap,
    },
    props: {
        isLoggedIn: {
            type: Boolean,
            required: true
        },
        role: {
            type: String,
            required: true
        }
    },
    setup(props: Readonly<{ isLoggedIn: boolean; role: string } & {}>) {
        const store: Store<RootState> = useStore();
        const router = useRouter();
        const route = useRoute();
        const map: Ref<Map | null> = ref(null);
        const sourceMapZoomFactor = toRef(store.state.applicationState, 'mapZoomFactor');
        const mapZoomFactor = ref(1);
        document.addEventListener('keydown', () => {
            store.dispatch(ApplicationActions.enableDragging);
        });
        document.addEventListener('keyup', () => {
            store.dispatch(ApplicationActions.disableDragging);
        });
        onBeforeMount(() => {
            store.dispatch(ApplicationActions.setRole, props.role);
            store.dispatch(MapActions.loadMarkers, route);
        });
        onMounted(() => {
            watch(sourceMapZoomFactor, () => {
                document.documentElement.style.setProperty('--map-zoom-factor', Math.min(sourceMapZoomFactor.value, 12).toString());
            })
            map.value = new Map(store, [
                new YouAreHereControl(store),
                new SelectMapControl(store),
                // new VisitedControl(store),
                new DistanceControl(store),
                new ExplorationMenuControl(store),
                new DiplomaticRelationshipToggleControl(store),
                new IamMenuControl(store),
            ], [
                new EditableSwitchControl(store),
                new RumorsToggleControl(store),
                new EventsMenuControl(store),
                new FileSaveLoadControl(store),
            ], props.isLoggedIn);
        });

        function hideEventMenu(event: Event) {
            const node = <HTMLElement>event?.target;
            if (!node?.classList.contains('map-control')
                && !node?.classList.contains('map-icon')
                && !node?.classList.contains('map-icon-img')
                && !node?.classList.contains('fleuve')
                && !node?.classList.contains('rumor-circle')
                && !(store.state.applicationState.shouldDisplayDistance
                    && node?.classList.contains('leaflet-container'))) {
                store.dispatch(ApplicationActions.hideAllMenu);
                store.dispatch(ApplicationActions.markRouteChangeAsProgramatic);
                router.replace({ query: { currentMap: router.currentRoute.value.query.currentMap } });
            }
        }

        return {
            hideEventMenu,
            map,
            mapZoomFactor,
        }
    },
}
</script>

<style lang="postcss" scoped>
.some-class {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000;
}
</style>