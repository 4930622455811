
import { computed, onMounted, ref, Ref, toRef } from 'vue';
import { useStore, Store, mapActions } from 'vuex';
import { RootState } from '../store/store';
import { MapActions } from "../store/mapStore/MapActions";
import { MapGetters } from "../store/mapStore/MapGetters";
import { Marker } from "../store/domain/Marker";
import { MapState } from '../store/mapStore/mapState';
import { MarkerEvent, MarkerEventType } from '../store/domain/markersEvents/MarkerEvent';
import Toast from '../javascripts/toasts/Toast';
import EditableSelect from './graphics/EditableSelect.vue';
import { MarkerActions, MarkerGetters } from '../store/domain/markers/MarkerEvents';
import { EventType, GenericEvent } from '../store/domain/genericEvents/genericMapStoreFunctions';

export default {
  components: { EditableSelect },
    name: 'EventMenu',
    setup() {
        const store: Store<RootState> = useStore();

        const availableDates = computed(() => {
            return store.getters[MapGetters.getAvailableDates].map((date: string) => {
                if (date === store.state.mapState.currentPlayersDate) {
                    return { name: date + ' - date joueurs', id: date };
                } else {
                    return { name: date, id: date };
                }
            });
        });
        const markerEvents: Ref<GenericEvent[]> = toRef(store.getters, MarkerGetters.GET_EVENTS_OF_MARKERS);
        const editionModeActivated = toRef(store.state.applicationState, 'editionModeEnabled');
        
        const currentDate = computed(() => {
            return { name: store.state.mapState.currentDate, id: store.state.mapState.currentDate };
        });
        const shouldDisplayEventMenu: Ref<boolean> = toRef(store.state.applicationState, 'shouldDisplayEventMenu');
        
        let shouldDisplayDropdown: Ref<boolean> = ref(false);
        let preselectedDate: Ref<string> = ref('');

        function isPlayerDate(date: string) {
            return date === store.state.mapState.currentPlayersDate;
        }
        function deleteMarkerEvent(id: number, eventType: MarkerEventType) {
            store.dispatch(MarkerActions.EVENT_DELETE_MARKER, { id });
        }
        function selectAsPlayersDate() {
            store.dispatch(MapActions.selectAsPlayersDate);
        }
        function zoomOnMarker(id: number, eventType: EventType) {
            if (eventType !== EventType.DELETE_EVENT) {
                store.dispatch(MapActions.zoomOnMarker, id);
            }
        }
        function selectDate(newEditionDate: string) {
            store.dispatch(MapActions.selectEditionDate, newEditionDate);
        }
        function inputNewDate(newEditionDate: string) {
            store.dispatch(MapActions.selectEditionDate, newEditionDate);
        }
        function dateValidationMethod(dateToParse: string) {
            return dateToParse.match(/^\d{4}-\d{2}-\d{2}$/);
        }

        return {
            availableDates,
            currentDate,
            deleteMarkerEvent,
            editionModeActivated,
            inputNewDate,
            isPlayerDate,
            markerEvents,
            selectAsPlayersDate,
            selectDate,
            shouldDisplayDropdown,
            shouldDisplayEventMenu,
            dateValidationMethod,
            zoomOnMarker,
        };
    },
}
