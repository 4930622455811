import { captureException } from '@sentry/browser';
import axios, { AxiosResponse } from 'axios';
import Iam from '../iam/javascripts/iam_logic/iam';
import Toast from '../javascripts/toasts/Toast.js';

declare const _API_URL_: boolean;

export type ApiMediaMetadata = {
    resourceAccessUrl: string;
    temporaryUploadUrl: string;
}

export function getTemporaryUploadUrl() {
    return Iam.getSession()
        .then((session: Iam) => {
            return axios
                .post(_API_URL_ + '/api/generate-media-storage-metadata', {}, {
                    headers : {
                        authorization : session.getAuthorizationToken(),
                    },
                })
                .then((response: AxiosResponse<any>): ApiMediaMetadata => response.data)
                .catch(error => {
                    console.log(error);
                    captureException(error);
                    throw error;
                });
        })
        .catch((error: Error) => {
            new Toast(error.message || JSON.stringify(error));
            console.log(error);
            captureException(error);
            throw error;
        });
}